@import "../globalVariable";
@import "../globalFontMixins";
@import "../globalCSSClasses";

.idealProfileView{
    position: relative;
    // margin-top: 10%;
    // margin-left: 20%;
    border-radius: 8px;
    background-color: $bg-primary-5;
    border: 1px solid $stroke-primary-5;
    box-sizing: border-box;
    height: 100px;
    width: 320px;
    align-items: center;
    display: flex;
    .idealProfileViewCloseBtn{
        position: absolute;
        margin-top: -56px;
        margin-left: 284px;
    }
    h3{
        @include subHeading1;
        @include globalInterFont;
        //margin-top: 28px;
    }
    p{
        @include normalText($text-primary-2);
        @include globalInterFont;
        height: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 235px;    
    }
    img{
        border-radius: 50%;
        margin: 24px 12px 30px 16px;
    }
}