@import '../../components/globalCSSClasses';
@import '../../components/globalVariable';
@import '../../themeColors';
@import '../globalFontMixins';





.Divider {
    width: 100%;
    position: relative;
    display: flex;
    isolation: isolate;
    margin-block: 22px;

    &::before {
        content: '';
        height: 2px;
        background-color: #333;
        position: absolute;
        top: 10px;
        z-index: -1;
        left: -24px;
        right: -24px;
    }

    >span {
        @include globalRubikFont;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        padding-inline: 1ch;
        background-color: $primary-bg-3;
        color: $text-disabled;
        margin-inline: auto;
    }
}