@import '../../themeColors';
@import '../../components/globalFontMixins';
.pillButton{
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    border: 1px solid transparent;
    outline: none;
    box-sizing: border-box;
    box-shadow: none;
    @include backgroundTransition;  
    @include globalInterFont;
    
    @include normalText($text-primary-1, $bg-primary-5, 11px 12px);

    &:hover{
        @include normalText($text-primary-1, $bg-primary-6, 11px 12px);
    }
}
.pillButtonSelected{
    border-color: $stroke-secondary-1;
}
.pillButtonHighlight{
    &:hover{
        // @include normalText($text-highlight-primary, transparent, 11px 12px);
        // border-color: $stroke-highlight;
    }
}
.pillButtonHighlightSelected{
    @include normalText($text-highlight-primary, transparent, 11px 12px);
    border-color: $stroke-highlight;
    &:hover{
        @include normalText($text-highlight-primary, $bg-primary-6, 11px 12px);
    }
}