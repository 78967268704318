@import '../../themeColors';
@import '../globalFontMixins';
@import '../globalCSSClasses';

p {
    margin: 0px;
}

.reactAutosuggestionContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    position: relative;
}

.hide-loader+.qluLoader {
    display: none;
}

.newAutoSuggestionInput {
    width: 320px;
    @include globalInterFont;
    @include normalText;
    padding: 12px 16px;
    background: $bg-primary-5;
    border-radius: 8px;
    border: 1px solid transparent;
    outline: none;

    &:focus,
    &:active,
    &:hover {
        outline: none;
        background: $bg-primary-6;
    }

    &:focus {
        border-color: $stroke-secondary-1;
    }

    &::placeholder {
        color: $text-primary-3;
    }
}

.newAutoSuggestionContainer {
    width: 322px;
    background-color: $bg-primary-6;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.08);
    margin-top: 8px;
    border-radius: 8px;
    max-height: 252px;
    overflow-y: auto;
    position: absolute;
    z-index: 1000;
    top: 48px;

    ul {
        display: flex;
        flex-direction: column;
        // gap: 8px;
        padding: 0;
        margin: 0;
        width: 100%;
        list-style: none;

        li:first-child:hover {
            div {
                border-radius: 8px 8px 0 0;
            }
        }

        li:last-child:hover {
            div {
                border-radius: 0 0 8px 8px;
            }
        }

        li:only-child:hover {
            div {
                border-radius: 8px;
            }
        }

        li {
            .tribeMembersNewJob {
                padding: 0px;
            }

            p {
                margin: 0px;
            }
        }
    }
}

.newAutoSuggestion {

    >div {
        @include globalInterFont;
        @include normalText;
        padding: 8px 12px;
        cursor: pointer;
    }

    >div:hover {
        background-color: $bg-primary-7;
    }
}