@import '../../../../themeColors';
@import "../../../../components/globalFontMixins";

.findMoreSizePillCards{
    background: $bg-primary-3;
    border: 1px solid $stroke-primary-3;
    padding: 28px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 36px;
    >h1{
        @include globalRubikFont;
        @include normalText;
        color: $text-primary-2;
    }
    >div{
        display: flex;
        justify-content: space-between;
        gap: 24px;
        >span{
            display: flex;
            flex-direction: column;
            gap: 32px; 
            >h3{
                @include globalRubikFont;
                @include normalText;
                color: $text-primary-3;
            }
            >div{
                height: 54px;
                display: flex;
                align-items: center;
                gap: 24px;
            }
        }
        >span:last-of-type{
                // width: calc(min(476px, 55%));
        }
    }
}