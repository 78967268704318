@import '../../components/globalFontMixins';
@import '../../themeColors';


.importanceQluButtonContainer{
    display: flex;
    align-items: center;
    gap: 1px;
    :first-child{
        border-radius: 8px 0px 0px 8px;
    }

    :last-child{
        border-radius: 0px 8px 8px 0px;
    }

    .buttonGroupButton{
        @include globalInterFont;
        @include subHeading1($text-primary-1, $bg-primary-5, 11px 12px);
        @include backgroundTransition;
        min-width: 86px;    
        cursor: pointer;
        border: 1px solid transparent;
        box-sizing: border-box;
        outline: none;
        &:hover{
            @include subHeading1($text-primary-1, $bg-primary-4, 11px 12px);
        }
    }
    .buttonStateToggle{
        @include subHeading1($text-highlight-primary, $bg-highlight-secondary, 11px 12px);
        &:hover{
            @include subHeading1($text-highlight-primary, $bg-highlight-secondary, 11px 12px);
        }
    }

}


