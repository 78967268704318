@import "../../../themeColors";

.sortingSection{    
    margin: 64px 0px auto auto;
    max-width: 350px;
    > div {
        background: $bg-primary-1;
        > div {
            padding: 0px;
            > div {
                background: $bg-primary-1;
            }
        }
    }

    .filterNavHeader{
        position: sticky;
        top: 0px;
        z-index: 2;
        background: $bg-primary-1;
        border-bottom: 1px solid $bg-primary-5;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .filterNav{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .leftFilterNav{
        display: flex;
        align-items: center;
    }

    .leftFilterNav > button > img{
        width: 9px;
        display: flex;
    }

    .leftFilterNav > button{
        padding: 10px 14px;
        border: 1px solid #A7ABB0;
        border-radius: 8px;
    }

    .filterNavHeading{
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: $text-primary-1;
        margin: 0px 8px;
    }

    .rightFilterNav{
        display: flex;
        align-items: center;

    }

    .filterCancelButton{
        margin: 0px 16px;
        border: 1px solid $stroke-primary-7;
        color: $text-primary-1;
    }

    .filterCancelButton, .filterApplyButton  {
        border-radius: 8px;
        &:hover {
            background: $bg-highlight-secondary;
            color: $text-highlight-primary;
            border-color: $text-highlight-primary;
        }
        &:focus, &:active {
            outline: none;
            box-shadow: none;
            background-color: transparent;
        }
    }

    .filterApplyButton {
        background: $bg-primary-5;
        border: 1px solid $stroke-primary-5;
    }

    .sortCardBodyContainer{
        display: flex;
        
    }
    .sortCardBodyContainer > span{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 14px 16px;
        border-right: 1px solid $stroke-primary-5;
    }

    .sortBodyHeading{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: $text-primary-1;
        margin: 0px 0px 8px 0px;
        padding: 0px;
        
    }
    .SortModuleContainer{
        padding: 8px 0px;
    }
    .SortMuduleFormGroup{
        display: flex;
        align-items: center;
        padding: 0px;
    }
    .SortMuduleRadioLabel{
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
        color: $text-primary-1;
        
        > input {

            &:focus, &:active {
                box-shadow: none;
                outline: none;
                border: none;
            }

            &.form-check-input {
                background-color: $bg-primary-5;
                border-color: $stroke-primary-5; 
            }

            &.form-check-input:checked {
                background-color: $bg-highlight-primary;
                border-color: $stroke-highlight; 
            }
        }
    }
    .SortMuduleRadioLabel:hover{
        cursor: pointer;
    }
    .SortMuduleRadioInput{
        position: static;
        width: 24px;
        height: 24px;
        margin: 0px 8px 0px 0px;
    }
    .SortMuduleRadioInput:hover{
        cursor: pointer;
    }

}