@import '../../../themeColors';
@import '../../../components/globalFontMixins';
.jobDescriptionAffordance{
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
    background-color: var(--recommended-secondary);
    padding: 8px 16px;
    margin: 16px 16px -32px 16px;
    >p{
        @include globalInterFont;
        @include normalText;
    }
}
.jobDescriptionCardContainer{
    padding: 64px 16px 32px 16px;
    .jobDescriptionCard{
        overflow: auto;
        height: 250px;
        scroll-behavior: smooth;
        margin: 0px -16px;
        padding: 0px 12px;
        span{
            line-height: 24px;
        }
        .carouselTagContainer{
            display: inline-block;
            margin: 0 4px;
        }
    }
}