@import '../../globalCSSClasses';
@import '../../globalFontMixins';
@import '../../globalVariable';



.thumbButton{
    @include backgroundTransition;
    // width: max-content;
    gap: 4px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    background: $secondary-grey;
    border-radius: 6px;
    padding: 1px 4px;
    flex-wrap: wrap;
    width: fit-content;
    &:hover{
        cursor: pointer;
        background-color: #EAF2FF;
    }    
    p{
        @include backgroundTransition;
        @include normalText();
        @include globalInterFont;
        background-color: unset;
    }

   
}