@import "../../../../themeColors";
@import "../../../../components/globalFontMixins";

.MultiFactorAuthContainer{
    display: flex;
    flex-direction: column;
    width: 676px;
    gap: 16px;
    >div{
        display: flex;
        gap: 16px;
        align-items: center;
        >label{
            @include globalInterFont;
            @include normalText;
        }
    }

}