@import "../../../components/globalVariable";
@import "../../../components/globalFontMixins";
@import "../../../components/globalCSSClasses";

.addPillSuggetionsConatiner {
  margin-top: 3px;
  .editPillSuggetionsConatiner {
    display: flex;
    align-items: center;
    //gap: 12px;
    .pillSuggetionInputContainer {
      background-color: $secondary-blue;
      padding: 4px 8px;
      border-radius: 36px;
      min-width: 126px;
      input {
        @extend .pillInput;
        width: 126px;
      }
    }
    button {
      @extend .secondaryGreyButton;
      padding: 0;
      // width: 24px;
      // height: 24px;
    }
  }
  .addPillSuggetionsButton {
    @extend .secondaryButton;
    padding: 11px 12px;
  }
}
