@import "../../components/globalFontMixins";

@keyframes slide {
  0% {
    width: 0;
  }
  100% {
    width: 40%;
  }
}

.side-drawer {
  background: #2c2c2c;
  border: 1px solid #333333;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  z-index: 200;
  animation-name: slide;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.2s;
}

.headingText {
  @include subHeading1;
}

.windowControls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //align-items: center;
  width: 50%;
  margin-top: 10px;
  margin-right: 10px;
  align-self: flex-start;
}

.controlButton {
  @include backgroundTransition;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  display: grid;
  place-content: center;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  &:hover,
  &:focus,
  &:active {
    background-color: $stroke-primary-7;
  }
  &:disabled {
    opacity: 0.7;
    background-color: $bg-primary-6;
    outline: none;
    box-shadow: none;
    border: none;
    cursor: not-allowed;
  }
}
