@import "../../../components/globalCSSClasses";
@import "../../../components/globalVariable";
@import "../../../components/globalFontMixins";

.settings {

    margin-bottom: 40px;

    .mainHeading {
        @include heading2();
        font-weight: 600;
    }

    .mainContent {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer {
        width: 784px;
        height: 74px;
        background-color: $bg-primary-6;
        border-radius: 8px;
        margin-top: 193px;
        padding: 16px;
    }

    .unavailable {
        width: 212px;
    }

    .footer {
        width: 784px;
        height: 74px;
        background-color: $bg-primary-6;
        border-radius: 8px;
        margin-top: 204px;
        padding: 16px;
    }

    .flexOnlyRow {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .flexOnly {
        display: flex;
        justify-content: space-between;
    }

    .resetButton {
        @include smallText();
        color: $bg-highlight-primary;
        margin-right: 20px;
    }

    .cancelButton {
        @include smallText();
        color: $text-primary-1;
    }

    .saveButton {
        width: 116px;
        height: 42px;
        font-size: 14px;
        padding: 11px 9px;
    }

    .crossIconButton {
        background: transparent;
        border: none;

        svg {
            width: 16px;
            height: 16px;
            margin-top: -5px;
        }
    }
}