@import '../../../../themeColors';
@import '../../../../components/globalFontMixins';
.quickDetailProfileCard{
    background: $bg-primary-3;
    border: 1px solid $stroke-primary-3;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    .socialButtonContainer{
        display: flex;
        align-items: center;
        gap: 12px;
        border-top: 1px solid $stroke-primary-3;
        border-bottom: 1px solid $stroke-primary-3;    
        padding: 16px 20px;
        >button{
            width: calc(33.333% - 8px);
        }
    }
    >div:first-of-type{
        position: relative;
        >span{
            position: absolute;
            top: 12px;
            right: 20px;
            width: unset;
            border: none;
            z-index: 1;
            >h6{
                @include normalText($text-highlight-primary);
                cursor: pointer;
            }
        }
    }
    >div{
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 12px;
        >h3{
            @include globalRubikFont;
            @include normalText($text-primary-2);
            font-weight: 400;
            width: 203px;
            padding: 24px 36px 20px 20px;
        }
        >span{
            width: calc(100% - 190px);
            border-bottom: 1px solid $stroke-primary-3;
        }
    }
    >div:last-of-type{
        >span{
            border-bottom: none;
        }
    }
}