@import '../../../../themeColors';
@import '../../../../components/globalFontMixins';
.profileSummaryVariant1Container{
    display: flex;
    align-items: center;
    gap: 34px;
    align-items: center;
    padding: 26px 94px 26px 34px;
    position: relative;
    width: 100%;
    >div:first-of-type{
        position: absolute;
        left: 12px;
        top: 12px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        >p{
            @include normalText($text-primary-2);
        }
    }
    >span{
        display: flex;
        flex-direction: column;
        gap: 4px;
        >div{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 4px;
            >h2{
                @include normalText;
            }
            >h3{
                @include normalText($text-highlight-accent);
                cursor: pointer;
            }
        }
        >h1{
            @include normalText;
            font-weight: 300;
            font-size: 24px;
            line-height: 121.02%;
            letter-spacing: -0.055em;
        }
        >h4{
            @include normalText($text-primary-2);
        }
        >.profileColabActions{
            margin-top: 12px;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;
            .emoji{
                font-size: 18px;
                .emoji-picker-react{
                    z-index: 9;
                    box-shadow: none;
                }
            }
        }
    }
}