@import "../../themeColors";

.notification-item {
    padding: 18px;
    background-color: $bg-primary-2;
    border-radius: 8px;
    display: flex;
    position: relative;


    > .notif-avt {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        // border: 1px solid orange;
        // color: lightsalmon;
        background:#29382E;
        color:#60B17C;
        font-size: 12px;
    }

    > .notif-content {
        width: 65%;
         >p:nth-child(1){
             color: $text-primary-1;
             font-size: 14px;
         }

         >p:nth-child(2){
             color: $text-primary-3;
             font-size: 12px;
         }
    }

    > .notif-link {
        position: absolute;
        top: 16px;
        right: 16px;
        color: $text-highlight-primary;
        cursor: pointer;
        font-size: 14px;
    }
}