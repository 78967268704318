@import "../../../components/globalCSSClasses";
@import "../../../components/globalVariable";
@import "../../../components/globalFontMixins";

.messagesContainer {
    display: flex;
    flex-direction: column;
    height: 70vh;
    overflow-y: auto;
    padding-bottom: 34px;
    overflow-x: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    .flexOnly {
        color: $text-primary-3;
        display: flex;
        text-transform: capitalize;
    }

    .channel {
        display: inline-flex;
        svg {
            width: 16px;
            height: 16px;
            margin-right: 4px;
            margin-top: 4px;

            path {
                stroke: $stroke-secondary-1;
            }
        }
    }

    .overviewDotStyle {
        margin: 0 8px;
        color: $text-primary-3 !important;
    }

    .profileDisplayImage {
        margin-top: -3px;
    }

    .loader {
        color: $bg-primary-7;
    }

    &::-webkit-scrollbar {
        width: 0 !important;
    }

    .self {
        margin-left: 80px !important;

        .senderImage {
            background-color: rgba(156, 168, 225, 0.2) !important;
            color: #9ca8e1 !important;
        }

        .messageContainer .message {
            background-color: #363944 !important;
        }

        .senderName {
            color: #9ca8e1 !important;
        }
    }

    .single-comment {
        @include smallText($fontColor: $text-primary);
        padding-top: 26px;
        animation: slideIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

        .dropdown-menu {
            background-color: $bg-primary-5 !important;
            button {
                padding: 5px !important;
                color: $text-primary-1 !important;
                &:hover {
                    background-color: $bg-highlight-secondary !important;
                    color: $text-highlight-primary !important;
                }
            }
        }

        .dropdown-toggle {
            margin-right: 0.7rem;
            background-color: $bg-primary-5 !important;
            color: $text-primary-1;
            border: 0px;
            border-radius: 8px;
            padding: 0.49rem 0.85rem;
            &:hover,
            &:active,
            &:focus {
                @include backgroundTransition;
                background-color: $border-grey;
                border: none;
                box-shadow: none !important;
                outline: none;
            }
        }
        .header {
            display: flex;
            justify-content: space-between;
            .commentSender {
                display: flex;
                gap: 8px;

                .senderImage {
                    @include subText($padding: 6px 4px);
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    margin-bottom: 8px;
                }
                .senderName {
                    @include smallText();
                    margin-top: 2px;
                }
            }

            .time {
                @include smallText($fontColor: $text-disabled);
                display: flex;
                .more {
                    button {
                        background: none;
                        padding: 0;
                        border: none;
                        margin-top: -5px;
                        margin-right: -6px;
                    }
                }
            }
        }

        .messageContainer {
            display: flex;
            align-items: flex-start;
            .message {
                width: 100%;
                border-radius: 10px;
                background-color: $primary-bg-7;
                padding: 12px;

                strong {
                    display: inline-block;
                }
            }
        }
    }
}
