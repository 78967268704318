@import '../../themeColors';
@import '../../components/globalFontMixins';
.ContextMenuContainer{
    @include globalInterFont;
    @include normalText;
    background-color: $bg-primary-5;
    border: 1px solid $stroke-primary-6;
    border-radius: 8px;
    padding: 4px 0;
    p{
        padding: 8px 16px;
    }
}