@import '../../../components/globalCSSClasses';
@import '../../../components/globalFontMixins';

.criteriaEducationContainer{
    height: 100%;
    padding:  48px 24px 32px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 42px;
    >div:first-child{
        display: flex;
        align-items: center;
        gap: 12px;
         >h2{
             @include globalInterFont;
             @include heading3;
         }
    }
    .div:nth-child(2){
        border-bottom: 1px solid $stroke-secondary-1;
    }
    .criteriaEducationBody{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        width: 100%;
        >.addMorePrimaryButton{
            padding-left: 0;
            padding-right: 0;
        }
        >span{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 12px;
            >h3{
                @include globalRubikFont;
                @include normalText($text-primary-3);
            }
            >h3:first-child{
                width: 275px;
            }
            // >h3:last-child{
            //     width: 260px;
            // }
            >input{
                @extend .primaryInput;
            }
            >span{
                // display: flex;
                // align-items: center;
                // flex-wrap: wrap;
                // gap: 12px;
                // >input{
                //     @extend .primaryInput;
                // }

                >span{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 12px;
                    >input{
                        @extend .primaryInput;
                    }
                }    
            }    
            .educationMajorContainer{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 12px
            }
        };
        .criteriaEducationInput{ 

            padding-bottom: 32px;
            border-bottom: 1px solid $stroke-primary-3;
        }
        .educationImportanceContainer{
            top: -12px;
            position: relative;
            margin-top: 8px;            
            >h3{
                @include globalRubikFont;
                @include normalText($text-primary-2);
                top: -8px;
                position: relative;
            }
        }
    }
 }