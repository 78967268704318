@import '../../themeColors';
@import '../../components/globalFontMixins';

.secondaryQluButton{
    @include globalInterFont;
    @include subHeading1($text-highlight-primary, $bg-highlight-secondary, 11px 16px);
    @include backgroundTransition;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    outline: none;
    border: 1px solid transparent;
    box-sizing: border-box;
    box-shadow: none;
    cursor: pointer;
    // color:  #e0e0e0;
    // background-color:#333333 ;
    &:hover{
        @include subHeading1($text-highlight-primary, $bg-highlight-secondary, 11px 16px);
        border-color: $stroke-highlight;
    }
    &:disabled{
        @include subHeading1($text-highlight-secondary, $bg-highlight-secondary, 11px 16px);
        cursor: not-allowed;
        border-color: transparent;
    }
}