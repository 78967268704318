@import "../../../themeColors";
@import "../../../components/globalFontMixins";

.profileContainer {
  display: flex;
  box-sizing: border-box;
  background: $bg-primary-2;
  border: 1px solid $bg-primary-4;
  border-radius: 8px;
  flex-direction: column;
  cursor: pointer;
}

.superLikeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 104px;
  height: 42px;
  border: 1px solid $stroke-primary-6;
  border-radius: 45px;
  background: $bg-primary-5;
}

.superLikeContainer:hover {
  border: 1px solid $stroke-primary-5;
}

.role {
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;

  .position {
    @include globalInterFont;
    font-weight: 400;
    font-size: 16px;
    line-height: 121.02%;
    color: $text-primary-1;
  }

  .at {
    @include globalInterFont;
    font-weight: 400;
    font-size: 16px;
    line-height: 121.02%;
    color: $text-primary-3;
  }

  .companyName {
    @include globalInterFont;
    font-weight: 400;
    font-size: 16px;
    line-height: 121.02%;
    color: #4e95ff;
    cursor: pointer;
  }

  .companyName:hover {
    text-decoration: underline;
    color: #4e95ff;
  }
}

.profileBodySection {
  display: flex;
  flex-direction: row;

  > .profileBodySectionName {
    flex: 1;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 146.02%;
    margin-left: 10px;
    text-transform: uppercase;
    color: $text-primary-3;
    padding: 10px;
  }
  > .profileBodySectionData {
    flex: 3;
    border-bottom: 1px solid #2c2c2c;
  }

  > .profileBodySectionDataWithoutBorder {
    flex: 3;
  }
}
.likeableSectionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  max-width: 26vw;
  min-height: 65px;
}

.likeableSection {
  padding: 4px;
  gap: 3px;
  > p {
    @include globalInterFont;
    font-weight: 400;
    font-size: 16px;
    line-height: 121.02%;
    color: $text-primary-2;
  }
  > span {
    @include globalInterFont;
    font-weight: 600;
    font-size: 16px;
    line-height: 121.02%;
    color: $text-primary-2;
  }
}

.likeableSectionContainer:hover {
  background-color: $bg-primary-4;
  border-radius: 8px 0px 0px 8px;
  max-width: 26vw;
}

.skills {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  width: 25vw;
  padding: 10px;

  > div {
    .tag {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 4px 7px;
      gap: 2px;
      width: auto;
      height: 24px;
      background: $bg-primary-5;
      border: 1px solid $stroke-primary-6;
      border-radius: 6px;

      > span {
        @include globalInterFont;
        font-weight: 600;
        font-size: 16px;
        line-height: 121.02%;
        color: $text-primary-2;
      }
    }
  }
}

.hyperlink {
  @include globalInterFont;
  font-weight: 400;
  font-size: 16px;
  line-height: 121.02%;
  color: #4e95ff;
  cursor: pointer;
  padding: 5px;
  width: 80px;
  margin-left: 8px;
  pointer-events: all;
}

.hyperlink:hover {
  text-decoration: underline;
  color: #4e95ff;
}

.education {
  @include globalInterFont;
  font-weight: 400;
  font-size: 16px;
  line-height: 121.02%;
  color: $text-primary-1;
}
