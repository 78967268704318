@import "../../../themeColors";
@import "../../../components/globalFontMixins";

.calibrationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.calibrationHeading {
  @include globalInterFont;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 121.02%;
  letter-spacing: -0.011em;
  color: $text-primary-1;
  margin-top: 50px;
}

.calibrationSubHeading {
  @include globalInterFont;
  font-weight: 400;
  font-size: 18px;
  line-height: 176%;
  text-align: center;
  color: $text-primary-2;
  width: 40vw;
}

.eclipseContainer {
  box-sizing: border-box;
  min-width: 37px;
  width: 37px;
  height: 37px;
  background: $bg-primary-5;
  border: 1px solid $stroke-primary-6;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.eclipseContainer:hover {
  border: 1px solid $stroke-primary-5;
}

.calibrationProfiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  align-self: center;
  justify-content: space-around;
  margin-bottom: 20vh;
  width: calc(min(75vw, 1080px));
  margin-top: 20px;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
