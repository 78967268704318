@import "../../../themeColors";
@import "../../../components/globalFontMixins";
@import "../../../components/globalFontMixins";
@import "../../../components/globalCSSClasses";
@import "../../../components/globalVariable";
.set-prioritize-size {
  @include globalInterFont;
  color: $text-primary-1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 92px;
  text-align: center;
  .clear {
    margin-top: 40px;
    margin-bottom: 20px;
    color: $text-primary-2;
    // text-decoration: underline;
    text-transform: capitalize;
    cursor: no-drop;
    &.active {
      cursor: pointer;
      color: $primary-1;
    }
  }
  .title {
    color: $text-primary;
    font-weight: 600;
    font-size: 24px;
    padding: 20px;
    width: 702px;
  }
  .sub-title {
    margin-bottom: 56px;
  }
  .steps {
    display: flex;
    gap: 10px;
    .step {
      display: flex;
      padding: 15px 20px;
      background: $bg-primary-5;
      border-radius: 60px;
      width: fit-content;
      border: 1px solid transparent;
      cursor: pointer;
      &.active {
        border: 1px solid $primary-1;
      }
      .active-name {
        color: $primary-1;
      }
      &.disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
      &:hover:not(.disabled) {
        background: $bg-highlight-secondary;
        color: $text-highlight-primary;
      }
      .step-item-index {
        background-color: $primary-1;
        color: $text-secondary-1;
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
      //   inset: 20px 11px;
      //   flex-direction: column;
      //   align-items: center;
      //   justify-content: center;
      gap: 8px;
      .step-title {
        @include globalInterFont;
        color: $text-primary-2;
        font-weight: 600;
        font-size: 16px;
      }
      .step-value {
        @include globalInterFont;
        color: $text-primary-2;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}
