@import '../../themeColors';

.dualContainer {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background-color: $bg-primary-1;
    display: flex;
    // align-items: center;
    justify-content: center;

    .leftWindow {
        background-color: $bg-primary-2;
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        transition: width 300ms ease-in-out,
            opacity 500ms ease-in-out,
            padding 200ms ease-in-out,
            transform 400ms ease-in-out;
        padding-bottom: 100px;

        &-appear {
            opacity: 0;
            transform: rotateY(90deg);
        }

        &-appear-active {
            opacity: 1;
            transform: rotateY(0);
        }

        &-enter {
            opacity: 0;
            transform: rotateY(90deg);
        }

        &-enter-active {
            opacity: 1;
            transform: rotateY(0);
        }

        &-exit {}

        &-exit-active {
            opacity: 0;
            width: 0;
            transform: rotateY(90deg);
        }
    }

    .rightWindow {
        width: 100%;
        // background-color: $bg-primary-2;
        // overflow-x: hidden;
        overflow-y: auto;
        scroll-behavior: smooth;
        transition:
            width 300ms ease-in-out,
            opacity 500ms ease-in-out,
            padding 200ms ease-in-out,
            transform 400ms ease-in-out;

        &-appear {
            opacity: 0;
            transform: rotateY(90deg);
        }

        &-appear-active {
            width: 0;
            opacity: 1;
            transform: rotateY(0);
        }

        &-enter {
            opacity: 0;

        }

        &-enter-active {
            opacity: 1;
        }

        &-exit {}

        &-exit-active {
            opacity: 0;
            transform: rotateY(90deg);
            width: 0;
        }

    }

    .soloWindow {
        margin-top: 64px;
        margin-bottom: 98px;
        overflow-y: unset;
    }
}

// .dualContainer::before {
//     content: '';
//     position: absolute;
//     background: $bg-gradient;
//     opacity: 0.3;
//     filter: blur(150px);
//     transform: rotate(-0.3deg);
//     inset: 5px;
// }