@import '../globalFontMixins';
@import '../globalVariable';



.dropDownContainer{
    width: 75%;
    &:active{
        @include globalInterFont;
        
        color: $secondary-blue !important;
        
    }
}

.singleItemDisplayControl{
    @include subHeading1($primary-black);
}


.dropDownToggle_Container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin-left: -8px;
    border-radius: 8px;

    h1{
        @include subHeading1($primary-black);
        @include globalInterFont;
    }

    border: none;
    background: none;

    &:active{
        background: $border-grey !important; 
        box-shadow: none !important;
    }
    
    &:hover{
        background: $border-grey;
        outline: none;
        border: none;
    }

    &:focus{
        background: $border-grey;
        // box-shadow: none;
    }

    
}

.dropDownItem_Container{
    @include globalInterFont;
    @include normalText($primary-black, $padding:8px 16px);
    border-radius: 0;
    border: none !important;
    &:hover, 
    &:active{
        background-color: $secondary-grey;
        color: $primary-black;
    }

    &:focus{
        outline: none;
    }
}

.dropDownMenu_Container{
    width: 100%;
}