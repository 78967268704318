@import '.././../themeColors';

.qluLoader{
    path{
        stroke: $stroke-highlight;
    }
    &Inverted{
        path{
            stroke: $stroke-primary-1;
        }
    }
}
