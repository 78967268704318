@import "../../components/globalVariable";
@import "../../components/globalFontMixins";
@import "../../themeColors";

.noneFound {
  @include normalText($fontColor: $text-primary-2 !important,
    $margin: 1.15rem 0 1.5rem 1.5rem !important);
}

// .expanded .prospectDetailPage .prospectPage {
//   // width: 80vw;
// }

@keyframes slideLeft {
  from {
    opacity: 0;
    transform: translate3d(400rem, 0rem, 500rem);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.prospects .prospectPage {
  border: 1px solid $stroke-primary-4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 2px 0 2px;
  width: 80vw;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.05));
  min-height: 600px;
  animation: slideLeft 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.prospectPage {
  display: flex;
  flex-direction: row;
  background: $bg-primary-3;
  margin-bottom: 10px;
  width: 90vw;
  @include globalInterFont;

  .comments,
  .activity {
    @include normalText;
    border-bottom: 1px solid $bg-primary-5;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem;

    p:last-child {
      color: $text-primary-2;
      font-size: smaller;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .detailPanels {
    @include normalText;
    display: flex;
    flex-direction: column;
    border-left: 1px solid $bg-primary-5;
    width: 100%;

    .panel>p,
    .panel .totalExperience>p:first-child,
    .panel .functionalExperience>p:first-child,
    .specialitiesPanel>p {
      @include heading3;
      margin-left: 1.5rem;
      margin-top: 1.75rem;
    }

    .specialitiesPanel>p>span {
      @include normalText;
    }

    .specialitiesPanel>p>.missingText,
    .skillsPanel>.subHeading>p>.missingText {
      color: $primary-red;
    }

    .skillsPanel>.subHeading>p {
      @include subHeading1;
      margin-left: 1.5rem;
      margin-top: 1.15rem;
    }

    .skillsPanel>.subHeading>button {
      padding: 0;
      background: none;
      border: none;

      &:focus {
        background: none;
        border: none;
      }

      &:active {
        background: none;
        border: none;
      }

      &>p {
        @include subHeading1;
        margin-left: 1.5rem;
        margin-top: 1.15rem;
      }

      &>p>span {
        @include normalText;
      }
    }

    .specialitiesPanel>p>span,
    .skillsPanel>.subHeading>p>span {
      @include normalText;
    }

    .skillsPanel>.subHeading {
      border-bottom: 1px solid $bg-primary-5;
      min-height: 58px;

      >.tablets {
        margin-top: 0.85rem;
        margin-left: 1rem;
      }

      button {

        &:hover,
        &:active,
        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .skillsPanel .subHeading:nth-child(4) {
      border-bottom: 0px solid $bg-primary-5;
      // margin-top: 1rem;
      // margin-bottom: 1rem;
    }

    .skillsPanel .subHeading {
      .missing {
        color: $primary-red !important;
      }

      .tablets {
        flex-direction: column;
        // padding-top: 0.82rem;
        margin-left: 1rem;
        transform: scaleY(1);
        transform-origin: top;
        transition: all 0.26s ease;

        p {
          background-color: $bg-primary-3 !important;

          >span:first-child {
            background-color: $bg-primary-6;
            padding: 1px 4px 1px 4px;
            border-radius: 6px;
            margin-left: -0.2rem;
          }

          span.duration {
            color: $text-primary-2 !important;
          }
        }

        .missing {
          background-color: $bg-primary-3 !important;
          color: $text-primary-2 !important;

          >span:first-child {
            background-color: $primary-red-opaque;
            color: $primary-red !important;
          }
        }

        &.show {
          transform: scaleY(1);
          max-height: 100vh;
        }

        &.hide {
          transform: scaleY(0);
          max-height: 0vh;
          margin-top: -13px;
        }
      }
    }

    .specialitiesPanel {
      padding-bottom: 0.75rem;
      // padding-top: 1.125rem;
      min-height: 80px;

      >.tablets {
        flex-direction: column;
        padding-top: 0.82rem;

        margin-left: 1rem;

        p {
          background-color: $bg-primary-3 !important;

          >span:first-child {
            background-color: $bg-primary-6;
            padding: 1px 4px 1px 4px;
            border-radius: 6px;
            margin-left: -0.2rem;
          }

          span.duration {
            color: $text-primary-2 !important;
          }
        }

        .missing {
          background-color: $bg-primary-3 !important;
          color: $text-primary-2 !important;

          >span:first-child {
            background-color: $primary-red-opaque;
            color: $primary-red !important;
          }
        }
      }
    }

    .panel {
      display: flex;
      border-bottom: 1px solid $bg-primary-5;
      // width: 100vw;
      flex-direction: column;

      .title>span:first-child {
        @include globalInterFont;
        @include normalText($bg-highlight-primary, $bg-highlight-secondary);
        padding: 2px 4px;
        border-radius: 4px;
      }

      .title_company {
        margin-top: 1rem;

        &>span:first-child {
          color: $text-highlight-primary;
          background-color: $bg-highlight-secondary;
          padding: 2px 3px;
          border-radius: 4px;
          font-weight: 500;
        }
      }

      p {
        margin-left: 1.5rem;
      }

      .tablets {
        flex-wrap: wrap;
        margin-bottom: 1rem;

        p {
          background-color: $bg-primary-6;
          padding: 1px 4px 1px 4px;
          border-radius: 6px;
          margin-left: 0.5rem;
        }

        .missing {
          background-color: $primary-red-opaque;
          color: $primary-red;
        }
      }

      &.aboutPanel {
        display: flex;
        flex-direction: column;
        padding-bottom: 0.8rem;

        &>p {
          margin-bottom: 1.1rem;
        }

        >.summary {
          min-height: 60px;
          padding-right: 10%;
        }
      }

      .experiences {
        display: flex;
        flex-direction: column;

        .single-experience {
          display: flex;
          margin-top: 1.25rem;
          min-height: 43px;

          .experience-date {
            >p {
              width: 150px;
            }

            .duration {
              @include subHeading1;
              margin-left: 1.5rem;
              margin-top: 0.95rem;
              margin-bottom: 0.95rem;
            }
          }

          .experience-info {
            margin-left: 1.3rem;
            border-bottom: 1px solid $bg-primary-5;
            width: 100%;

            &.noBorder {
              border-bottom: 0px solid $bg-primary-5 !important;
            }

            .summary {
              // width: 60rem;
              margin-top: 0.5rem;
              white-space: pre-line;
              text-align: left;
            }

            .title {
              min-height: 30px;
            }

            .title .years {
              color: $text-primary-2;
            }

            .duration {
              font-weight: 300;
              color: $text-primary-2;
            }

            .title_exp {
              margin-top: 0.95rem;
              font-weight: 500;
            }

            .title_location {
              margin-top: 0.5rem;
              font-weight: 300;
              color: $text-primary-2;
            }
          }

          .experience-skills {
            display: flex;
            padding-top: 0.8rem;
            min-height: 30px;

            >p {
              color: $text-primary-2;
            }

            div {
              display: flex;
            }
          }

          .experience-specialities {
            display: flex;
            margin-top: -0.7rem;
            min-height: 30px;

            >p {
              color: $text-primary-2;
            }

            div {
              display: flex;
            }
          }
        }
      }

      &.relevantExperiencePanel .relevant-summary:nth-child(2) {
        padding-top: 1.125rem;
      }

      .relevant-summary {
        display: flex;
        min-height: 30px;

        >p:first-child {
          width: 170px;
        }

        .title {
          width: 70%;

          &:last-child {
            padding-bottom: 1.5rem;
          }

          &.noneFound {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
          }
        }
      }
    }

    .tablets {
      display: flex;

      p {
        margin-bottom: 0.3rem;
      }
    }

    .totalFunctionalExperiencePanel {
      display: flex;
      flex-direction: row;
      width: 100%;

      .totalExperience {
        width: 50%;
        min-height: 30px;

        p {
          padding-bottom: 1.5rem;
          padding-top: 1.125rem;
        }
      }

      .functionalExperience {
        width: 50%;
        min-height: 30px;
      }

      .tablets {
        margin-left: 0.95rem;
        margin-top: 0.9rem;
        display: flex;
        flex-direction: column;
        gap: 4px;

        .years {
          color: $text-primary-2;
        }
      }
    }

    .visaDemographics {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      border: none;

      p {
        margin-left: 1.5rem;
      }

      .panel {
        border-bottom: 0px;
        width: 50%;
        // width: 200px;
      }

      .panel p:nth-child(2) {
        @include normalText;
        margin-left: 1.5rem;
        margin-top: 1.15rem;
        margin-bottom: 1.5rem;
      }
    }

    .educationPanel {
      >h3 {
        @include subHeading1;
        margin-left: 1.5rem;
        margin-top: 1.15rem;
        margin-bottom: 1.25rem;
      }

      >p {
        @include normalText;
        margin-left: 1.5rem;
        margin-bottom: 1.25rem;
      }
    }
  }
}

.prospectDetailPage .prospectPage {
  width: 80vw;

  .detailPanels {
    background: none;
  }
}

.collapse {
  transition: all 0.5s ease-in-out;

  // height: 0;
  &.expanded {
    height: auto;
  }
}

.functionalExperienceContainer {
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    @include globalInterFont;
    @include normalText($backgroundColor: $secondary-grey);
    padding: 2px 4px;
  }
}