@import "../../../themeColors";

.advancedFilterSection{
    margin: 0px 0px 0px 5%;
    max-width: 100%;

    > div {
        background: $bg-primary-1;
        > div {
            padding: 0px;
            > div {
                background: $bg-primary-1;
            }
        }
    }

    .filterNavHeader{
        position: sticky;
        top: 0px;
        z-index: 2;
        background: $bg-primary-1;
        border-bottom: 1px solid $bg-primary-5;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .filterNav{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .leftFilterNav{
        display: flex;
        align-items: center;

        .leftFilterBorder {
            border-right: 1px solid $stroke-primary-4;
            height: 28px;
            margin-right: 8px;
        }
    }

    .filterNavHeading{
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: $text-primary-1;
        margin: 0px 8px;
    }

    .rightFilterNav{
        display: flex;
        align-items: center;
        .filterBorder {
            border-right: 1px solid $stroke-primary-4;
            height: 42px;
        }
    }

    .filterClear{
        margin: 0px 16px;
        color: $text-highlight-primary;
    }   
    .filterClear:hover{
        cursor: pointer; 
    }

    .filterCancelButton{
        margin: 0px 16px;
        border: none;
        color: $text-primary-1;
    }

    .filterCancelButton, .filterApplyButton  {
        border-radius: 8px;
        &:hover {
            background: $bg-highlight-secondary;
            color: $text-highlight-primary;
            border-color: none;
        }
        &:focus, &:active {
            outline: none;
            box-shadow: none;
            background-color: transparent;
        }
    }

    .filterApplyButton {
        background: $bg-primary-5;
        border: none;
    }

    .bottomFilterBodyContainer{
        display: flex;
        margin: 0px;
        padding: 0px;
    }

    .bottomFilterBody{
        display: flex;
        // justify-content: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        // width: 70%;
        // padding: 16px 0px;
        width: 68%;
        padding: 16px 34px;
        border-right: 1px solid $stroke-primary-5;
    }

    .suggestionMainSpanContainer{
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
        margin: 8px 12px;
        // width: 47%;
        width: 45%;
    }
    .suggestionMainSpanContainer > div{
        margin: 8px 0px;
    
    }

    .filterSubHeadingContainer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0px !important;
    }

    .filterSubHeading{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: $text-primary-1;
        margin-bottom: 0px;
        height: 27px;
    }

    .skillStrictButtonContainer{
        margin: 0px 0px 0px auto;
        display: flex;
        gap: 10px;
    }
    .skillStrictButton,.skillStrictButton:active,.skillStrictButton:focus{    
        padding: 4px 12px;
        margin-right: 8px;
        border-radius: 8px;
        font-size: 12px;
        font-weight: 600;
        // color: $text-primary-3;
        color:$text-primary-2;
        background: $bg-primary-6;
        border: none;
        outline: none;
    }
    .skillStrictButton:hover{
        color: $text-highlight-primary;
        background: $bg-highlight-secondary;
        border: none;
        outline: none;
    }

    .specialtyStrictButton,.specialtyStrictButton:active,.specialtyStrictButton:focus{    
        padding: 4px 12px;
        margin-right: 8px;
        border-radius: 8px;
        font-size: 12px;
        font-weight: 600;
        color: $text-primary-3;
        background: $bg-primary-6;
        border: none;
        outline: none;
    }
    .specialtyStrictButton:hover{
        color: $text-highlight-primary;
        background: $bg-highlight-secondary;
        border: none;
        outline: none;
    }

    .skillStrictToggle,.skillStrictToggle:focus,.skillStrictToggle:active{
        background-color: $bg-highlight-secondary;
        color: $text-highlight-primary;
        border: none;
        outline: none;
        box-shadow: none;
    }

    .specialtyStrictToggle,.specialtyStrictToggle:focus,.specialtyStrictToggle:active{
        background-color: $bg-highlight-secondary;
        color: $text-highlight-primary;
        border: none;
        outline: none;
        box-shadow: none;
    }

    .adv-filter-ip{    
        box-sizing: border-box;
        width: 100%;
        height: 42px;
        padding: 16px;
        background: $bg-primary-5;
        color: $text-primary-1;
        margin-top: 4px;
        margin-bottom: 8px;
        border-radius: 8px;
        outline: none;
        border: none;
    }
    .autosuggest-suggestions-container > ul > li > div{
        line-height: 20px;
    }
    .autosuggest-suggestions-container {

        /* border-radius: 4px; */
        background-color: $bg-primary-5;
        color: $text-primary-1;
        width: 418px;
        z-index: 2;
        position: absolute;
    
    }
    
    .autosuggest-suggestions-container > ul{
        border: 1px solid $stroke-primary-5;
        box-sizing: border-box;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
        margin-top: 0;
        margin-bottom: 1rem;
        list-style: none;
        padding-left: 0;
        max-height: 268px;
        overflow: auto;
    }
    
    .suggestion-item {
    
        height: 42px;
        line-height: 12px;
        cursor: pointer;
        padding: 16px;
    }
    
    .suggestion-item:hover{
        background-color: rgba(41, 122, 247, 0.05);
    }

    .AdvancedVisaFilterPillContainer{
        display: flex;
        flex-wrap: wrap;
        margin-top: 4px;

    }
    .form-control:focus{
        outline: none;
        box-shadow: none;
    }

    .advFilterNewLine{
        border: none;
        width: 100%;
        margin: 16px 0px;
    }

    .TitleSwitchContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 10px 0px auto;
    }
    .TitleSwitchContainer > span{
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: $text-primary-1;
        margin-right: 8px;
    }
    .react-toggle-track {
        width: 32px !important;
        height: 20px !important;
        background-color: $bg-primary-1 !important;
        border: 1px solid $stroke-primary-6 !important;
    }
    
    .react-toggle-thumb{    
        width: 14px !important;
        height: 14px !important;
        background-color: $bg-primary-6 !important;
        border: 1px solid $stroke-primary-6  !important;
        top: 3px !important;
        left: 2px !important;
    }
    .react-toggle--checked .react-toggle-track {
        background-color: $bg-highlight-secondary !important;
        border-color: $bg-highlight-secondary !important;
    }
    .react-toggle--checked .react-toggle-thumb {
        left: 17px !important;
        background-color: $text-highlight-primary !important;
        border-color: $text-highlight-primary !important;
    }
    .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
        background: $bg-highlight-secondary !important;
        border-color: $bg-highlight-secondary !important;
    }
    .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: $bg-primary-1 !important;
        border-color: $stroke-primary-6 !important;
    }
    .react-toggle--focus .react-toggle-thumb {
        -webkit-box-shadow:none !important;
        -moz-box-shadow: none !important;
        box-shadow: none !important;
    }

    .advFilterDevider{
        width: 100%;
        margin: 16px 0px;
    }
    .advFilterCheckboxContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .form-check-input {
            background-color: $bg-primary-5;
            border: 2px solid var(--stroke-secondary-5);

            &:focus, &:active {
                outline: none;
                border: none;
                box-shadow: none;
            }
        }
    }

    .sliderFilterContainer{
        margin: 16px 0px 0px 0px;
    }
    .sliderFilter{

    }
    .sliderFilterLableContainer{    
        display: flex;
        justify-content: space-between;

    }
    .sliderFilterLable{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: $text-primary-2;

    }
    .sliderFilterLablePosition{
        margin: 0px 0px 0px auto;
    }

    .advExpSliderContainer{
        padding: 24px 0px;
        display: flex;
        width: 95%;
        justify-content: center;
        align-items: center;

        .input-range__track {
            background-color: $bg-primary-5;
        }

        .input-range__track--active {
            background: $bg-highlight-primary;
        }

    }
    .advExpSliderContainer > div > div > div {
        background: $bg-highlight-primary;
    }
    .advExpSliderContainer > div > span > span{
        left: 0%;
    }
    .advExpSliderContainer > div > div > span > div{

        background: $bg-highlight-primary;
        border: 1px solid $bg-highlight-primary;
    }

    // .checkbox{
    //     position: relative;
    //     margin: 0px;
    //     width: 24px;
    //     height: 24px;
    //     background: #297AF7;
    //     border: 1px solid #297AF7;
    //     box-sizing: border-box;
    //     border-radius: 6px;
    //     margin-left: 0px;
    // }

    .checkBox{
        position: relative;
        margin: 0;
        margin-right: 8px;
        width: 24px;
        height: 24px;
    }
    .scoringFilterSpan{
        display: flex;
        flex-direction: column;
        // width: 30%;
        // padding: 16px 0px;
        width: 32%;
        padding: 16px 34px;
    }

    .card-body {
        padding: 0px;
    }
}

.AdvancedFilterPillButtonContainer{
    padding: 0px;
    margin: 0px 8px 8px 0px;
    border-radius: 8px;
    display: flex;
    > button > svg{
        padding: 0px;
        margin: 0px;
    }
    .AdvancedFilterPillButtonText, 
    .AdvancedFilterPillButtonText:active{
        outline: none;
        box-shadow: none;
        border: none;
        background: $bg-primary-5 !important;
        border-radius: 8px 0px 0px 8px;
        text-align: left;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
        color: $text-primary-1 !important;
    }
    .AdvancedFilterPillButtonText:hover {
        outline: none;
        box-shadow: none;
        border: none;
        background-color: $bg-highlight-secondary !important
    }

    .AdvancedFilterPillButtonHighlight, .AdvancedFilterPillButtonHighlight:active{
        outline: none;
        box-shadow: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $bg-primary-5 !important;
        border-radius: 0px 8px 8px 0px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
        color: $text-primary-1 !important;
    }
    .AdvancedFilterPillButtonHighlight:hover {
        outline: none;
        box-shadow: none;
        border: none;
        background: $bg-positive-secondary !important;

        svg {
            * {
                stroke: $text-positive-primary;
            }
        }
    }

    .AdvancedFilterPillButtonEliminate, .AdvancedFilterPillButtonEliminate:active{
        outline: none;
        box-shadow: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $bg-primary-5 !important;
        border-radius: 0px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 23px;
        color: $text-primary-1 !important;
        z-index: 1;
    }
    .AdvancedFilterPillButtonEliminate:hover {
        outline: none;
        box-shadow: none;
        border: none;
        background: $bg-negative-secondary !important;

        svg {
            * {
                stroke: $text-negative-primary;
            }
        }
    }

    .AdvancedFilterEliminateInactive{
        background: $bg-primary-5 !important;
        color: $text-primary-1 !important;
    }

    .AdvancedFilterEliminateActive{
        background: #EF5555 !important;
        color: white !important;
    }

    .AdvancedFilterPillHighlight, 
    .AdvancedFilterPillHighlight:active{
        background: $bg-highlight-secondary !important;
        color: $text-highlight-primary !important;
        svg {
            * {
                stroke: $bg-highlight-primary !important;
            }
        }
    }
    .AdvancedFilterPillEliminate, 
    .AdvancedFilterPillEliminate:active{
        background: rgba(239, 85, 85, 0.08) !important;
        color: #EF5555 !important;
    }
    
}