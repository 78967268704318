@import '../../../../themeColors';
@import '../../../../components/globalFontMixins';
.profileSummaryContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    padding: 24px 20px;
    position: relative;
    >div:first-of-type{
        position: absolute;
        left: 12px;
        top: 12px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        >p{
            @include normalText($text-primary-2);
            text-align: center;
        }
    }
    >span:first-of-type{
        >p{
            font-weight: 300;
            font-size: 24px;
            line-height: 121.02%;
            letter-spacing: -0.055em;
        }
    }
    >span{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;
        >p{
            @include normalText;
            text-align: center;
        }
        >h6{
            @include normalText($text-highlight-accent);
            cursor: pointer;
        }

        >.profileColabActions{
            margin-top: 12px;
            gap: 4px;
            .emoji{
                font-size: 18px;
                .emoji-picker-react{
                    z-index: 9;
                    box-shadow: none;
                }
            }
        }
    }
}