@import "../../../components/globalCSSClasses";
@import "../../../components/globalFontMixins";
@import '../../../themeColors';

.hiringCompanyDetails {
  margin-top: 24px;

  // min-height: ;
  .hiringCompanySuggestionContainer {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  h1 {
    margin-bottom: 64px;
    @include globalInterFont;
    @include normalText($text-primary-2);
    margin-bottom: 32px;
  }

  p {
    @include globalInterFont;
    @include normalText($text-primary-3);
    margin-bottom: 12px;
  }

  .companyAddBtnClickedContainer {
    p {
      margin-bottom: 12px;
    }

    input {
      width: 45%;
      height: 6vh;
      background-color: #f5f6f7;
      border: none;
      border-radius: 8px;
      padding-left: 16px;

      &:focus-visible {
        border: none !important;
        outline: none;
      }
    }
  }
}

.similarCompaniesDetailsMainDiv {
  display: flex;
  width: 100%;

  .rightCompanyDetailsModal {
    width: 40%;
    border-left: 1px solid $stroke-primary-5;

    .suggestionProfileLoader {
      margin-left: 165px;
      margin-top: 50vh;
    }

    .hoverProfileSummaryCard {
      margin: 184px 45px 0px 45px;
      display: flex;
      gap: 32px;
      align-items: center;

    }

    .hoverProfiletag {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 12px;
      margin: 184px 45px 0px 45px;
      align-items: center;

      p {
        @include normalText($primary-grey);
      }
    }

    .hoverProfilecompanyCard {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      margin: 276px 0px 0px 46px;

      .hoverProfilecompanyCardContent {
        max-width: 162px;

        h3 {
          @include globalInterFont;
          @include subHeading1;
        }

        p {
          @include normalText();
          @include globalInterFont;
        }
      }
    }
  }

  .similarCompaniesDetails {
    width: 60%;

    .likedDislikedContainer {
      display: flex;
      //flex-wrap: wrap;
      gap: 274px;
      margin-top: 64px;

      .likedContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
      }

      .dislikedContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
      }
    }

    margin-top: 32px;

    >p {
      @include normalText;
      @include globalInterFont;
      margin-bottom: 32px;
    }

    .SimilarCompaniesBtn {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
  }
}