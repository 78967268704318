@import '../../components/globalCSSClasses';
@import '../../components/globalVariable';
@import '../../themeColors';

.DigitInput {
    display: flex;
    gap: 8px;
    cursor: pointer;


    .single {
        background: $primary-bg-4;
        border: 1px solid #{$primary-bg-6};
        border-radius: 4px;
        cursor: pointer;

        &.error {
            outline: 1px solid #EF5555;
        }

        &.focus {
            outline: 1px solid #FF8D4E;
        }

        input {
            cursor: pointer;
            text-align: center;
            height: 100%;
            width: 100%;
            border: none;
            outline: none;
            background-color: transparent;
            font-size: 44px;
            width: 44px;
            height: 56px;
            color: #E0E0E0;
        }
    }
}

.error {
    color: #EF5555;
    margin-top: 12px;
}