@import "../../themeColors";
@import "../../components/globalFontMixins";
@import "../../components/globalFontMixins";
@import "../../components/globalCSSClasses";
@import "../../components/globalVariable";
.ProfileLiked {
  display: flex;
  justify-content: space-around;
  padding-block: 10px;

  @include globalInterFont;
  .right {
    width: auto;
  }
  .left {
    max-width: 70%;
    .toast-header {
      font-size: 16px;
      font-weight: 600;
      color: $text-secondary-1;
      padding: 0px;
      margin: 0px;
      background-color: transparent;
      border: none;
    }
    .toast-description {
      font-size: 14px;
      color: $text-secondary-1;
    }
  }
}
.Toastify__toast-theme--light {
  background-color: $text-primary-2;
  border-radius: 6px;
  padding: 10px 20px;
}
