@import '../../themeColors';
@import '../../components/globalFontMixins';
@import '../../components/globalCSSClasses';

.DropdownContainer{
    .DropdownToggle{
        @include globalInterFont;
        @include normalText;
        min-width: 275px;
        width: 100%;
        min-height: 42px;
        padding: 10px 16px;
        display: flex;
        align-items: center;
        overflow: hidden;
        justify-content: space-between;
        background-color: $bg-primary-5 !important;
        color: $text-primary-1 !important;
        border-radius: 8px;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        overflow: hidden;
        &:hover{
            background-color: $bg-primary-7 !important;
        }
        >svg >path{
            stroke: $text-primary-1 !important;
        }
    } 
    .DropdownMenu{
        min-width: 275px;
        max-height: 235px;
        overflow: auto;
        background-color: $bg-primary-6;
        border-radius: 8px;
        border: none;
        padding: 0;
        margin-top: 8px;
        transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
        >.disabledOption{
            @include globalInterFont;
            @include normalText($text-primary-2);
            padding: 8px 12px;
            cursor: not-allowed;
        }
        >.recruiterLoginButton{
            @include globalInterFont;
            @include normalText;
            padding: 8px 12px;
            text-decoration: none;
            width: 100%;
            &:hover{
                background-color: $bg-primary-7;
            }
        }
        >button{
            @include globalInterFont;
            @include normalText;
            padding: 8px 12px;
            &:hover{
                background-color: $bg-primary-7;
            }
        }
        >.gbt_root {
            margin: 0;
            width: 100% !important;
        }
    }
}
