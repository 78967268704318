@import "../../components/globalFontMixins";
@import "../../components/globalVariable";
@import "../../themeColors";

.emoji-picker-react input.emoji-search {
  background: $bg-primary-7;
  border: none;
  color: $text-primary;
  &:focus,
  &:active {
    border: none;
  }
}

.emoji-picker-react .emoji-categories {
  background: $bg-primary-3 !important;
}

aside.emoji-picker-react .content-wrapper,
.emoji-picker-react .emoji-group:before,
aside.emoji-picker-react {
  background: $bg-primary-4 !important;
  border: none !important;
}

aside.emoji-picker-react {
  border: 1px solid $stroke-primary-5 !important;
}

.emoji-picker-react .emoji-categories button {
  filter: invert(100%);
}

.filter-blue {
  filter: invert(37%) sepia(95%) saturate(1476%) hue-rotate(202deg)
    brightness(95%) contrast(105%);
}

.prospectContainer {
  display: flex;
  // width: min(95%, 1440px);
  &.prospect {
    // max-width: calc(70vw - 12rem);
  }

  // @media screen and (max-width: 1601px) {
  //   &.commentSection {
  //     max-width: calc(100% - 0.1vw);
  //   }
  // }
  // @media screen and (max-width: 1366px) {
  //   &.commentSection {
  //     max-width: calc(100% - 26rem);
  //   }
  // }

  // @media screen and (max-width: 1280px) {
  //   &.commentSection {
  //     max-width: calc(100% - 36rem);
  //   }
  // }
}
.prospectCategoryMenuContainer {
  position: sticky;
  top: 100px;
  z-index: 10;
  background-color: var(--bg-primary-1);
  padding-bottom: 10px;
  .myDropDown {
    display: block;
    position: relative;
    background-color: $primary-bg-3;
    color: $text-primary;
    .myDropDown-item {
      position: absolute;
      will-change: transform;
      border-radius: 8px;
      top: 21px;
      left: -24px;
      padding: 0.5rem 1rem;
      border-bottom: 1px solid $bg-primary-3;
      text-transform: capitalize;
      background-color: $bg-primary-3;
      z-index: 10000;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: $bg-primary-2;
        color: $text-primary;
      }
    }
  }
  .prospectCategorySubContainer {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .categoryLineSpacing {
      padding-left: 1rem;
      font-size: 24px;
      color: $bg-primary-6;
    }
    .categoryRecommended {
      display: flex;
      align-items: center;
      height: 40px;
      color: $text-secondary;
      text-transform: uppercase;
      cursor: pointer;

      .addedfilterButtonDropdown {
        button {
          background: $bg-primary-1;
          border: none;
        }

        .dropdown-toggle {
          width: max-content;
          @include globalInterFont;
          @include normalText($primary-grey);
          font-weight: 400;
          text-transform: uppercase;
        }
        .dropdown-menu {
          background: $bg-primary-1;
        }

        .dropdown-item {
          color: $text-primary-1;

          &:hover {
            color: $text-highlight-primary;
            background: $bg-highlight-secondary;
          }
        }
      }
      .likedCompanyBucket {
        width: max-content;

        .horizontalLine {
          width: 100%;
          border-bottom: 2px solid $bg-highlight-primary;

          /* make it glow! */
          // -webkit-box-shadow: 0px 0px 12px 2px $bg-highlight-primary;
          // -moz-box-shadow: 0px 0px 12px 2px $bg-highlight-primary;
          // box-shadow: 0px 0px 12px 2px $bg-highlight-primary;
        }
      }

      .recommendedNumber {
        background-color: $bg-primary-6;
        border-radius: 4px;
        font-size: 12px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 5px;
      }
      .categoryRecommendedSvg {
        margin-left: 20px;
        margin-right: 5px;
        // background-color: rgba(255, 195, 33, 0.3);
        // border-radius: 50%;
        display: flex;
        justify-content: center;
        height: 28px;
        width: 28px;
        align-items: center;
        .recommendedSvg {
          margin-bottom: 4px;
        }
      }
    }
  }
  .categoryScrollLeft,
  .categoryScrollRight {
    opacity: 0;
    visibility: hidden;
    margin-top: 2px;
    border-radius: 50%;
    border: 1px solid transparent;
    transition: 0.2s all ease-in-out;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.08);

    padding: 5px 12px 8px;
    &.visible {
      opacity: 1;
      visibility: visible;
    }
  }
  .categoryScrollLeft {
    margin-left: -10px;
    box-shadow: 13px 7px 36px -1px rgba(0, 0, 0, 1);
    -webkit-box-shadow: 13px 7px 36px -1px rgba(0, 0, 0, 1);
    -moz-box-shadow: 13px 7px 36px -1px rgba(0, 0, 0, 1);
    background-color: var(--bg-primary-6);
    cursor: pointer;
    position: absolute;
    &:hover {
      background-color: var(--bg-primary-2);
    }
  }
  .categoryScrollRight {
    margin-left: -25px;

    box-shadow: -11px 7px 36px -1px rgba(0, 0, 0, 1);
    -webkit-box-shadow: -11px 7px 36px -1px rgba(0, 0, 0, 1);
    -moz-box-shadow: -11px 7px 36px -1px rgba(0, 0, 0, 1);
    background-color: var(--bg-primary-6);
    cursor: pointer;
    position: relative;
    &:hover {
      background-color: var(--bg-primary-2);
    }
  }
}
.unlockRecommendationContainer {
  margin-top: 16px;
}
.prospects {
  // min-width: 50vw;
  width: 100%;
  > div {
    > div {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 16px;
      // width: 90vw;
      margin-top: 1rem;
      // margin-left: 3rem;

      &.expanded {
        // width: 87vw;
      }
    }
  }
}

.collapsedSideBar {
  position: relative;
  .react-toggle-track {
    width: 32px !important;
    height: 20px !important;
    background-color: $bg-primary-1 !important;
    border: 1px solid $stroke-primary-6 !important;
  }

  .react-toggle-thumb {
    width: 14px !important;
    height: 14px !important;
    background-color: $bg-primary-6 !important;
    border: 1px solid $stroke-primary-6 !important;
    top: 3px !important;
    left: 2px !important;
  }
  .react-toggle--checked .react-toggle-track {
    background-color: $bg-highlight-secondary !important;
    border-color: $bg-highlight-secondary !important;
  }
  .react-toggle--checked .react-toggle-thumb {
    left: 17px !important;
    background-color: $text-highlight-primary !important;
    border-color: $text-highlight-primary !important;
  }
  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background: $bg-highlight-secondary !important;
    border-color: $bg-highlight-secondary !important;
  }
  .react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background-color: $bg-primary-1 !important;
    border-color: $stroke-primary-6 !important;
  }
  .react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }
  .collapsed {
    z-index: 200 !important;
    height: 55vh;
    @media screen and (min-width: 1920px) {
      height: 65vh;
    }
    .nav-item:hover {
      background: $bg-primary-6;
      border-radius: 0px 8px 8px 0px;
    }
  }

  .unCollapsed {
    // position: absolute;
    top: 0;
    opacity: 0;
    transition: all 0.5s ease-in;
    background-color: none !important;
    height: 55vh;
    @media screen and (min-width: 1920px) {
      height: 65vh;
    }

    &.expanded {
      opacity: 1;
      z-index: 100;
    }

    .nav-item:hover {
      background: $bg-primary-6;
      border-radius: 0px 8px 8px 0px;
    }
  }
}

.sideNav {
  border-top: 1px solid $bg-primary-3;
  transition: all 0.5s ease-in;
  min-width: 2.8%;
  @include globalInterFont;
  margin-top: -1rem;
  padding-top: 1rem;
  padding-right: 5px;

  // For Sticky Nav

  align-self: flex-start;
  position: sticky;
  top: 0;
  z-index: 150;
  background-color: $bg-primary-3;
  min-height: 100vh;

  &.prospectPage {
    display: flex;
    justify-content: center;
  }

  .nav-item {
    margin-top: 0.3rem;
  }

  .nav-item:hover {
    background: $bg-primary-3;
    border-radius: 0px 8px 8px 0px;
  }

  .nav-item.users {
    margin-top: 10px;
  }

  .nav-item:hover .icon {
    * {
      stroke: $bg-highlight-primary !important;
    }
  }

  .selected {
    // background-color: $bg-highlight-secondary;
    border-radius: 0px 8px 8px 0px;

    .icon {
      * {
        stroke: $bg-highlight-primary !important;
      }
    }
  }

  .expanded.selected {
    .prospect,
    .prospectText {
      color: $text-highlight-primary !important;
      font-weight: 600 !important;
    }
    .prospectCount {
      font-weight: 500 !important;
      font-size: 14px;
    }
  }

  .horizontalLine {
    background-color: $stroke-primary-6;
    .nav-link {
      padding: 0 1.15rem !important;
    }
  }

  .menuIcon {
    p {
      opacity: 0;
      position: absolute;
      transition: all 0.5s ease-in;
    }

    &.expanded {
      p {
        opacity: 1;
      }
    }

    &.expanded .icon {
      margin-left: 84% !important;
      transform: rotate(0deg);
      transform-origin: center center;
    }

    .icon {
      border-radius: 5px;
      padding: 5px;
      width: 36px;
      height: 36px;
      margin-left: 18%;
      transform: rotate(180deg);
      transform-origin: center center;
      transition: all 0.5s ease-in;
      &:hover {
        background: $bg-primary-6;
      }
      * {
        stroke: $text-primary-2 !important;
        fill: $text-primary-2 !important;
      }
    }
  }

  &.expanded {
    min-width: 12.5rem;
    @media (min-width: 1020px) {
      min-width: 14.5rem;
    }
    // @media screen and (min-width: 1200px) and (max-width: 1710px) {
    //   min-width: 14%;
    // }
    // @media screen and (min-width: 1020px) and (max-width: 1240px) {
    //   min-width: 16%;
    // }
    // @media screen and (max-width: 1020px) {
    //   min-width: 17%;
    // }
    & {
      border-right: 0px;
    }
    .menuIcon {
      @include heading3($text-primary-1);
      font-weight: 400;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 1rem;

      .icon {
        background: none;
        margin-right: 2.5%;
        margin-top: -2.8%;
        width: 24px;
        height: 24px;
      }

      p {
        @include globalRubikFont;
        @include normalText($text-primary-1);
        font-weight: 400;
        letter-spacing: -0.015em;
      }
    }

    .selected {
      .prospectText {
        .prospect {
          color: $text-highlight-primary !important;
          font-weight: 600 !important;
        }
      }
    }

    .prospectIcon {
      @include heading3($text-primary-1);
      font-weight: 400;
      display: flex;
      flex-direction: row;

      .prospectText {
        margin-left: 0.5rem;
        display: flex;
        justify-content: space-between;
        width: 8rem;

        @media (min-width: 1020px) {
          width: 10rem;
        }

        .prospect {
          @include globalInterFont;
          @include normalText($text-primary-1);
          font-weight: 500;
          letter-spacing: -0.015em;
          color: $text-primary-1;
          text-transform: capitalize;
        }
      }
    }
  }
}

.tab-content {
  display: flex;
  width: 100vw;
  justify-content: center;
  background-color: $bg-primary-1;
  // overflow-y: auto;
  // max-height: 83vh;

  .tab-pane {
    >.row{
      justify-content: center;
    }
    // width: min(95%, 1440px);
    // width: 95vw !important;
    // @media screen and (min-width: 1680px) {
    //   width: 80vw !important;
    // }
  }
  &.prospectPage {
    // width: 58vw !important;
    width: 90vw !important;
    // @media (min-width: 1920px) {
    //   width: 58vw !important;
    // }
    // @media (min-width: 1528px) and (max-width: 1920px) {
    //   width: 52vw !important;
    // }
    // @media (min-width: 1400px) and (max-width: 1528px) {
    //   width: 52vw !important;
    // }
    // @media (max-width: 1400px) {
    //   width: 48vw !important;
    // }
  }
  &.commentsSection {
    // width: 87vw;
  }

  &.expanded {
    // width: 87vw;
    .tab-pane {
      // width: 80vw !important;
    }
  }

  .menuContainer .topMenuBar,
  .menuContainer::after {
    background-color: $bg-primary-1 !important;
  }
}

.menuIcon,
.nav-link {
  cursor: pointer;
}
.time {
  font-size: 12px;
  color: var(--text-primary-2);
  position: relative;
  bottom: 47px;
  height: 50px;
  display: flex;
  /* text-align: center; */
  align-items: center;
  justify-content: center;

  width: 56px;
  opacity: 0;
  transition: 0.5s opacity ease;
  z-index: 100;
  &:hover {
    opacity: 1;
    // user-select: none;
  }
}
