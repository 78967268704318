@import '../../themeColors';
@import '../../components/globalFontMixins';

.primaryQluButton{
    @include globalInterFont;
    @include subHeading1($text-secondary-1, $bg-highlight-primary, 11px 32px);
    @include backgroundTransition;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    outline: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    &:hover{
        @include subHeading1($text-secondary-1, $bg-highlight-hover, 11px 32px);
    }
    &:disabled{
        @include subHeading1($text-secondary-1, $bg-highlight-disabled, 11px 32px);
        cursor: not-allowed;
    }
}