@import '../../../../themeColors';
@import "../../../../components/globalFontMixins";

.findMorePillCards{
    background: $bg-primary-3;
    border: 1px solid $stroke-primary-3;
    padding: 28px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 36px;
    >h1{
        @include globalRubikFont;
        @include normalText;
        color: $text-primary-2;
    }
    >div{
        display: flex;
        gap: 8px;
        align-items: center;
        flex-wrap: wrap;
        >div{
            display: flex;
            gap: 12px;
            align-items: center;
        }
        >button{
            
        }
    }
   
    
}
.divError{
    color: #ef5555;
    opacity: 100;
}