@import '../../themeColors';
@import '../../components/globalFontMixins';
.carouselTagHover{
    position: relative;
    &:after{
        @include globalInterFont;
        @include normalText($text-secondary-1, $bg-highlight-primary, 4px 8px);
        @include backgroundTransition;
        border-radius: 0 6px 6px 0;
        margin: 0;
        position: absolute;
        top: 0;
        content: " ✖";  
        z-index: 10;
        animation-name: opctyanim;
        animation-duration: 500ms;  
        animation-fill-mode: forwards;
    }
}

@keyframes opctyanim {
    from {
        opacity: 0;
        right: 0;
    }
    to {
        opacity: 1;
        right: -24px;
    }
}
 .carouselTag{
    @include globalInterFont;
    @include normalText($padding: 4px 8px);
    @include backgroundTransition;
    border-radius: 6px;
    cursor: pointer;
    &Selected{
        @include globalInterFont;
        @include normalText($text-highlight-primary, $bg-highlight-secondary, 4px 8px);
        @include backgroundTransition;
        border-radius: 6px;
        cursor: pointer;
        &:hover{
            @include normalText($text-secondary-1, $bg-highlight-primary, 4px 8px);
        }

    }
    &Highlight{
        @include globalInterFont;
        @include normalText($text-secondary-1, $bg-highlight-primary, 4px 8px);
        @include backgroundTransition;
        border-radius: 6px;
        cursor: pointer;
    }
}


.carouselTagGrey{
    @include globalInterFont;
    @include normalText($padding: 4px 8px);
    @include backgroundTransition;
    border-radius: 6px;
    cursor: pointer;
    &Selected{
        @include globalInterFont;
        @include normalText($text-primary-1, $bg-primary-7, 4px 8px);
        @include backgroundTransition;
        border-radius: 6px;
        cursor: pointer;
        &:hover{
            @include normalText($text-primary-1, $stroke-secondary-1, 4px 8px);
        }
    }
    &Highlight{
        @include globalInterFont;
        @include normalText($text-primary-1, $stroke-secondary-1, 4px 8px);
        @include backgroundTransition;
        border-radius: 6px;
        cursor: pointer;
    }
    &Disabled{
        @include globalInterFont;
        @include normalText($text-primary-1, $bg-primary-7, 4px 8px);
        @include backgroundTransition;
        border-radius: 6px;
        cursor: pointer;
        background-color: #454545;
        color: #A0A0A0;
    }
}