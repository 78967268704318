@import "../../themeColors";

.chartCardRoot {
    width: 200px;
    height: 120px;
    border-radius: 8px;
    border: 1px solid $stroke-primary-2;
    background-color: $bg-primary-2;
    padding: 12px;

    > p {
        color: $text-primary-2;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    > div {
        display: flex;
        justify-content: space-around;
        // > * { width: 50% };
        > p {
            font-size: 24px;
            color: $text-primary-1;
            // width: 45%
        }
        > div {
            width: 75px;
            // width:55%
        }
    }
}