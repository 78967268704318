@import "../../../themeColors";

.ScoringFiltersSliderContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px;

  .advFilterCheckboxContainer {
    display: flex;
    align-items: center;
  }

  // .checkbox{
  //     position: relative;
  //     margin: 0px;
  //     width: 24px;
  //     height: 24px;
  //     background: #297AF7;
  //     border: 1px solid #297AF7;
  //     box-sizing: border-box;
  //     border-radius: 6px;
  //     margin-left: 0px;
  // }

  .checkBox {
    position: relative;
    margin: 0;
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }

  .filterSubHeading {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $text-primary-1;
    margin-bottom: 0px;
    height: 27px;
  }

  .filterSliderValue {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $text-primary-1;
    margin: 0px 0px 0px auto;
  }

  .sliderFilterContainer {
    margin: 16px 0px 0px 0px;

    .input-range__track {
      background-color: $bg-primary-5;
    }

    .input-range__track--active {
      background: $bg-highlight-primary;
    }

    > div > div > div {
      background: $bg-highlight-primary;
    }
    > div > span > span {
      left: 0%;
    }
    > div > div > span > div {
      background: $bg-highlight-primary;
      border: 1px solid $bg-highlight-primary;
    }
  }

  .sliderFilterLableContainer {
    display: flex;
    justify-content: space-between;
  }
  .sliderFilterLable {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: $text-primary-3;
  }

  .sliderFilter {
    & {
      width: 100%;
      height: 1.5rem;
      padding: 0;
      background-color: transparent;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    &:focus {
      outline: 0;
    }
    &:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px $bg-highlight-primary,
        0 0 0 0.25rem rgba(13, 110, 253, 0);
    }
    &:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px $bg-highlight-primary,
        0 0 0 0.25rem rgba(13, 110, 253, 0);
    }
    &::-moz-focus-outer {
      border: 0;
    }
    &::-webkit-slider-thumb {
      width: 1rem;
      height: 1rem;
      margin-top: -0.25rem;
      background-color: $bg-highlight-primary;
      border: 0;
      border-radius: 1rem;
      -webkit-transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      -webkit-appearance: none;
      appearance: none;
    }
    @media (prefers-reduced-motion: reduce) {
      &::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none;
      }
    }
    &::-webkit-slider-thumb:active {
      background-color: $bg-highlight-primary;
    }
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 0.5rem;
      color: transparent;
      cursor: pointer;
      background-color: $bg-primary-5;
      border-color: transparent;
      border-radius: 1rem;
    }
    &::-moz-range-thumb {
      width: 1rem;
      height: 1rem;
      background-color: $bg-highlight-primary;
      border: 0;
      border-radius: 1rem;
      -moz-transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      -moz-appearance: none;
      appearance: none;
    }
    @media (prefers-reduced-motion: reduce) {
      &::-moz-range-thumb {
        -moz-transition: none;
        transition: none;
      }
    }
    &::-moz-range-thumb:active {
      background-color: $bg-highlight-primary;
    }
    &::-moz-range-track {
      width: 100%;
      height: 0.5rem;
      color: transparent;
      cursor: pointer;
      background-color: $bg-primary-5;
      border-color: transparent;
      border-radius: 1rem;
    }
    &:disabled {
      pointer-events: none;
    }
    &:disabled::-webkit-slider-thumb {
      background-color: #adb5bd;
    }
    &:disabled::-moz-range-thumb {
      background-color: #adb5bd;
    }

    width: 100%;
  }
}
