@import '../../globalFontMixins';
@import '../../globalVariable';

.svgAndDescription{
    display: flex;
    gap: 8px;

    align-items: center;
    p{
        @include globalInterFont;
        @include smallText();
        .initials{
            padding : 4px;
            display: inline-block;
            text-align : center;
            align-items : center;
            justify-content : center;
            width : 28px ;
            height : 28px;
            border-radius: 50% ;
            background : #29382E;
            color : #60B17C;
        }
    }
}
