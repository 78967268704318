@import '../../themeColors';


.outboxContainer{
    min-height: 100vh;
    width: calc(100vw - 6px);
    display: flex;
    flex-direction: column;

    >.outboxBodyContainer{
        // height: auto;
        width: 100%;
        display: flex;
        align-self: center;
        justify-content: center;
        >.outboxBody{
            padding: 32px 32px 120px 32px;
            display: flex;
            align-items: flex-start;
            gap: 16px;
            
         
        }

    } 
}
.outboxCardsFade-enter {
    opacity: 0;
    transform: translateY(-100%);
}
.outboxCardsFade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
.outboxCardsFade-exit {
    opacity: 1;
    transform: translateY(0);
}
.outboxCardsFade-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 300ms ease-out, transform 300ms ease-out;
}
.outboxTabsFade-enter {
    opacity: 0;
    transform: translateX(-100vw);
}
.outboxTabsFade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
.outboxTabsFade-exit {
    opacity: 1;
    transform: translateX(0);
}
.outboxTabsFade-exit-active {
    opacity: 0;
    transform: translateX(-100vw);
    transition: opacity 300ms ease-out, transform 300ms ease-out;
}