.companyDisplayImageContainer {
    .companyDisplayImage {
        margin-bottom: 24px;
        border-radius: 8px;
    }    

    .placeHolderContainer {
        border-radius: 8px;
        margin-bottom: 24px;
        div{
            margin-top: 50%;

        }
        .companyDisplayImage { 
            display: flex;
            justify-content: center;
            align-items: center;
        }   
    }
}