@import "../../components/globalVariable";
@import "../../components/globalFontMixins";
@import "../../themeColors";

.editTabs {
  padding-right: calc(1% + 0.1vw);
  @media screen and (min-width: 1680px) {
    padding-right: calc(8% + 0.5vw);
  }
}

.editFlowTabsButtonActive {
  @include globalInterFont();
  @include normalText(#2e2e2e);
  margin-left: 21px;
  border: none;
  background-color: $bg-highlight-secondary;
  color: $text-highlight-primary;
  border-radius: 26px;
  margin-top: 22px;
  margin-bottom: 22px;

  .tabsNameCount {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 12px;
    padding: 8px 12px 8px 12px;
    text-transform: capitalize;
  }
  &:hover {
    transition: none;
    transform: none;
    border: none !important;
  }
  &:focus {
    outline: none;
    border: none;
  }
  p {
    margin-bottom: 0px;
  }
}

.editFlowTabsButton {
  @include globalInterFont();
  @include normalText($text-primary-1);
  margin-left: 21px;
  border: none;
  background-color: $bg-primary-6;
  border-radius: 26px;
  margin-top: 22px;
  margin-bottom: 22px;

  .tabsNameCount {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 12px;
    padding: 8px 12px 8px 12px;
    text-transform: capitalize;
  }
  &:hover {
    color: $text-highlight-primary;
    background-color: $bg-highlight-secondary;
    transition: none;
    transform: none;
    border: none !important;
  }
  &:focus {
    outline: none;
  }
  p {
    margin-bottom: 0px;
  }
  &:active:focus {
    background-color: $bg-highlight-secondary;
    color: $text-highlight-primary;
  }
}

.editFlowBackTabsButton {
  margin-left: 21px;
  border: none;
  background-color: #f5f6f7;
  border-radius: 50%;
  margin-top: 22px;
  width: 42px !important;
  height: 42px !important;
  &:hover {
    transition: none;
    transform: none;
    border: none !important;
    background-color: #eaf2ff;
  }
  p {
    margin-bottom: 0px;
  }
  &:focus {
    outline: none;
  }
}

.editFlowTabs {
  //margin-left: 21px;
  border: none;
  z-index: 100;
  .caretWideRight {
    * {
      stroke: $stroke-primary-5 !important;
    }
  }
}

.editFlowTabsArrow {
  margin-left: 21px;
}

.editFlowTabsup {
  margin-left: 16px;
}
