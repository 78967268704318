@import "../Optout/global.scss";
.dataProtectionContainer {
  background-color: $bg-primary-1;
  min-height: 100vh;
  position: relative;
  scroll-behavior: smooth;
  scroll-snap-type: block;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  // gap: min(360px, 25vw);
}

.dataProtectionSection {
  display: flex;
  // align-items: center;
  padding-top: 120px;
  padding-bottom: 200px;
  justify-content: space-between;
  width: calc(min(784px, 80%));
  // width: 50vw;
  gap: 16px;
 
  >ul{
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 25%;
    gap: 8px;
    list-style: none;
    > li {
      @include bodyText1($text-primary-2, $bg-primary-3);
      @include backgroundTransition;
      padding: 12px 16px;
      border-radius: 8px;
      cursor: pointer;
      &:hover{
        @include bodyText1($text-secondary-1, $bg-highlight-primary);
        padding: 12px 16px;
      }
    }
  }
  a{
    @include bodyText1($text-highlight-primary);
    text-decoration: underline;
  }

  > div {
    width: 75%;
    > h3 {
      @include bodyText2($text-primary-1);
      margin-bottom: 32px;
    }
    > h2 {
      @include bodyText2($text-primary-1);
      margin-bottom: 32px;
      margin-top: 24px;
    }
    > h1 {
      @include bodyText2($text-primary-1);
      margin-bottom: 32px;
      margin-top: 24px;
    }
    > p {
      @include bodyText1;
      margin-bottom: 24px;
    }
    > ul {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 0;
      padding-left: 20px;
      > li {
        @include bodyText1;
      }
    }
  }
}



@media screen and (max-width: 900px) {
  .dataProtectionSection {
      flex-direction: column;
      >ul{
        flex-direction: row;
        width: 100%;
      }
      >div{
        width: 100%;
      };
  }
}
