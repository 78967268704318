@import "../../../components/globalCSSClasses";
@import "../../../components/globalVariable";
@import "../../../components/globalFontMixins";

.overview {

    @include smallText($text-primary-1);

    img {
        margin-right: 12px;
        margin-bottom: 8px;
    }

    .linkedInLogo {
        margin-left: 5px;
        margin-bottom: 3px;
        cursor: pointer;
    }

    .you {
        @include smallText($text-primary-3);
        margin-left: 4px;
    }

    .flexDisplay {
        display: flex;
    }

    .detailsRow {
        display: inline-flex;
        padding-bottom: 28px;
    }

    .tag {
        width: 100px;
    }

    .subHeading {
        @include smallText($text-primary-2);
    }

    .profileDisplayImage {
        margin-right: 12px;
    }

    textarea {
        resize: none;
        overflow: hidden;
        border: none;
        @include smallText($text-primary-2);
        width: 200px;
        height: 20px;

        :focus {
            outline: none;
            border: none;
        }

        :focus-visible {
            outline: none;
            border: none;
        }
    }

    :focus-visible {
        outline: none;
    }

    .borderBottom {
        border-bottom: 1px solid $bg-primary-4;
    }

    .paddingTop {
        padding-top: 28px;
    }

    .orangeView {
        color: $text-highlight-primary;
        cursor: pointer;
    }

    .overviewDotStyle {
        margin: 0 8px;
        color: $bg-primary-5 !important;
    }
}