@import "../../../components/globalFontMixins";
@import "../../../components/globalVariable";
@import "../../../themeColors";
.tribesCard {
  color: $text-primary;
  background-color: $bg-primary-3;
  padding: 28px 16px;
  border-radius: 8px;
  width: 26.25rem;
  height: 165px;
  transition: 0.3s all ease-in-out;
  &.expanded {
    height: 320px;
  }
  .close {
    transition: 0.3s all ease-in-out;
    transform: rotate(0deg);
  }
  .open {
    transition: 0.3s all ease-in-out;
    transform: rotate(180deg);
  }
  .dropdown {
    transition: all 0.3s ease-in-out;
    opacity: 0;
    &.expanded {
      opacity: 1;
    }
  }
  .mainContent {
    display: flex;
    justify-content: space-between;
  }
  .left {
    display: flex;
    gap: 10px;
    .avatar {
      height: 50px;
      width: 50px;
      border-radius: 100%;
      background: rgba(156, 168, 225, 0.2); // for now hard coded.
      display: flex;
      color: #9ca8e1; // for now hard coded.
      justify-content: center;
      align-items: center;
    }
    .invited {
      color: $text-highlight-primary;
      background-color: $bg-highlight-secondary;
      padding: 4px;
      width: fit-content;
      border-radius: 8px;
    }
  }
  .commentBtn {
    background-color: $primary-4;
    border-radius: 6px;
  }
  .bottom {
    @include globalRubikFont;
    margin-top: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $text-disabled;
  }
}
.tribesDropdown {
  .dropdown-menu {
    background-color: $bg-primary-5;
  }
  .dropdown-item {
    color: $text-primary;
    background-color: $bg-primary-5;
    padding-left: 5px;
    padding-right: 5px;
  }
  button {
    background: none;
    padding: 0;
    border: none;

    &:hover,
    &:focus {
      background: none;
      border: none;
    }
  }
}
