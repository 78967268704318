@import '../../themeColors';
@import '../../components/globalFontMixins';

.warningQluButton{
    @include globalInterFont;
    @include subHeading1($recommended, $recommended-secondary, 11px 32px);
    @include backgroundTransition;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    outline: none;
    border: 1px solid transparent;
    box-shadow: none;
    cursor: pointer;
    &:hover{
        border-color: $recommended;
    }
    &:disabled{
        cursor: not-allowed;
    }
}