@import "../../components/globalFontMixins";
@import "../../components/globalVariable";
@import "../../themeColors";

.tribesCardContainer {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  .noMembers {
    color: $text-primary-1;
  }
}
.tribesContainer {
  display: flex;
  justify-content: space-between;
}
.tribesContent {
  width: 90vw;
  margin: 32px;
  transition: 0.5s all ease-in-out;
  &.expanded {
    width: calc(90vw - 30.625rem);
  }
}
