@import "../../../components/globalCSSClasses";
@import "../../../components/globalFontMixins";
@import "../../../components/globalVariable";

@mixin flexBetween {
  display: flex;
  justify-content: space-between;
}

.RightOnClickModalBody {
  flex-direction: column;
  display: flex;
  justify-content: center;
  overflow: hidden;
  overflow-wrap: break-word;
  padding: 32px 16px 16px 16px;
  .rightOnClickModalcompanyInfo {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    gap: 8px;
    div {
      @include flexBetween();
    }
    h6 {
      @include subHeading1();
      font-weight: bold;
      width: 50%;
    }
    a, p {
      width: 50%;
      @include normalText();
    }
  }
  .RightOnClickModaloverview {
    @include globalInterFont;
    @include normalText;
    margin-top: 19px;
    span {
      @include subHeading1();
    }
  }
  .RightOnClickModalHeader {
    // align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    img{
        border-radius: 8px;
    }
    .RightOnClickModalCardContent {
      h3 {
        @include globalInterFont;
        @include subHeading1;
      }
      p {
        @include normalText();
        @include globalInterFont;
      }
      // .RightOnClickModaloverview {
      //   @include globalInterFont;
      //   @include normalText;
      //   margin-top: 19px;
      //   span {
      //     @include subHeading1();
      //   }
      // }
      span{
        @include normalText(#A7ABB0);
        @include globalInterFont;
      }
    }
  }
}
