@import '../../../themeColors';
@import '../../../components/globalFontMixins';
.outboxReviewController{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
    >.outboxReviewAffordance{

        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        gap: 12px;
        align-items: center;
        background-color: var(--recommended-secondary);
        padding: 8px 16px;
        margin: 16px 0px;
        // width: 100%;
        >p{
            @include globalInterFont;
            @include normalText;
        }
    }
    >.pillTabHeader{
        display: flex;
        align-items: center;
        gap: 16px;
        position: sticky;
        top: 0;
        background: $bg-primary-1;
        z-index: 1000;
        width: 100%;
        padding: 8px 0;
    }
    >.outboxReviewBody{
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        >div{
            background: $bg-primary-2;
            border: 1px solid $stroke-primary-2;
            border-radius: 8px;
            padding: 24px;
            display: flex;
            flex-direction: column;
            gap: 40px;
            .outboxReviewRoundSummary{
                display: flex;
                align-items: center;
                gap: 16px;
                margin-bottom: 24px;
                >p{
                    @include globalInterFont;
                    @include normalText($text-primary-2);
                }
                >span{
                    width: 24px;
                    height: 24px;
                    display: grid;
                    place-content: center;
                    background-color: $bg-positive-secondary;
                    border-radius: 50%;
                    path{
                        stroke: $text-positive-primary
                    }
                }
            }
            .outboxReviewRoundHeader{
                display: flex;
                align-items: center;
                justify-content: space-between;
                >span{
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    >h3{
                        @include globalInterFont;
                        @include heading3;
                    }
                    >p{
                        @include globalInterFont;
                        @include normalText($text-primary-2);
                    }
                    >span{
                        width: 40px;
                        height: 40px;
                        display: grid;
                        place-content: center;
                        background-color: $bg-highlight-secondary;
                        border-radius: 50%;
                    }
                    >.svgStrokeContainer{
                        path{
                            stroke:  $bg-highlight-primary;
                        }
                    }
                    >.svgFillContainer{
                        path{
                            fill:  $bg-highlight-primary;
                        }
                    }
                }
            }
        }
    }
}
