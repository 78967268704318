@import "../../../themeColors";
@import "../../../components/globalFontMixins";

.loader {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.fullContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 100px;
  width: 90vw;
}

.recipientsList {
  flex: 2;
}

.rounds {
  flex: 4;
}

.round {
  flex: 4;
  display: flex;
  flex-direction: column;
  background: $bg-primary-2;
  border-radius: 8px;
  margin-bottom: 20px;

  .roundsHeader {
    padding-bottom: 10px;
  }

  .roundsBody {
    display: flex;
    flex-direction: row;
  }

  .message {
    flex: 4;
    margin: 10px;
  }

  .assistant {
    flex: 2;
    padding: 20px;
  }
}

.headerContainer {
  background-color: #242424;
  height: 110px;
  border: 1px solid #2c2c2c;
  box-sizing: border-box;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0px;
}

.headerContainer_left {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.headerContainer_heading {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 26px 0px 0px 31px;

  > p {
    margin-top: 8px;
  }
  > span {
    width: 40px;
    height: 40px;
    display: grid;
    place-content: center;
    background-color: $bg-highlight-secondary;
    border-radius: 50%;
  }
  > .svgRoundIcon {
    path {
      stroke: $bg-highlight-primary;
    }
  }
}

.title {
  @include globalInterFont;
  @include subHeading1;
  font-size: 20px;
}

.tableHeaderContainer_right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 50%;
  flex: 1;
}

.tribes {
  display: flex;
  flex-direction: row;
  width: 35px;
  height: 22px;
  background: $bg-primary-4;
  border-radius: 5px;
  justify-content: center;
  align-items: center;

  > p {
    @include globalInterFont;
    color: $text-primary-2;
    font-weight: 600;
    font-size: 14px;
    line-height: 12px;
    margin-left: 3px;
    margin-top: 2px;
  }
}

.stats {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  margin-right: 30px;
  .label {
    @include globalInterFont;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: $text-primary-3;
  }
}

.stats_values {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .value {
    @include globalInterFont;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 121.02%;
    letter-spacing: -0.035em;
    color: #e0e0e0;
    margin-top: 5px;
  }

  .subValue {
    @include globalInterFont;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: $text-primary-3;
    margin-left: 8px;
  }
}

.recipientsListContainer {
  width: 95%;
  display: flex;
  flex-direction: column;
  background: $bg-primary-2;
  border: 1px solid $stroke-primary-2;
  border-radius: 8px;
  > div {
    border-left: 4px solid transparent;
    box-sizing: border-box;
    @include backgroundTransition;
  }
  .selectedTab {
    border-color: $stroke-highlight;
  }
  .allRecipientsTab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
    border-radius: 8px 8px 0 0;
    > span {
      display: flex;
      align-items: center;
      gap: 12px;
      > span {
        width: 46px;
        display: grid;
        place-content: center;
      }
      > p {
        @include globalInterFont;
        @include subHeading1($text-highlight-primary);
      }
    }
    > p {
      @include globalInterFont;
      @include normalText($text-secondary-1, $bg-highlight-primary, 2px 8px);
      border-radius: 6px;
    }
  }
  .recipientTab {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px 24px;
    &:last-child {
      border-radius: 0 0 8px 8px;
    }
    > div:last-child {
      padding-left: 58px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;
    }
  }
  > div:hover {
    cursor: pointer;
    background: $bg-primary-3;
  }
}

.outboxMessagingSubjectContainer {
  background: $bg-primary-4;
  border-top: 1px solid $stroke-primary-4;
  border-right: 1px solid $stroke-primary-4;
  border-left: 1px solid $stroke-primary-4;
  padding: 16px;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  > div {
    display: flex;
    align-items: center;
    gap: 16px;
    > h3 {
      @include globalRubikFont;
      @include normalText($text-primary-3);
      width: 71.5px;
    }
    > p {
      @include globalInterFont;
      @include normalText($text-primary-2);
    }
    > input {
      @include globalInterFont;
      @include normalText;
      max-width: 100%;
      width: 100%;
      border: none;
      outline: none;
    }
  }
}

.footer {
  box-sizing: border-box;
  width: 60vw;
  flex: 1;
  align-self: center;

  /* bg/bg-primary-6 */

  background: $bg-primary-6;
  /* stroke/stroke-primary-6 */

  border: 1px solid $stroke-primary-6;
  border-radius: 12px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
}

.footer-back {
  height: 75px;
  display: flex;
  flex-direction: row;
  width: 80px;
  justify-content: space-around;
  align-items: center;
  margin-left: 30px;
  cursor: pointer;

  > p {
    @include globalInterFont;
    color: $text-primary-1;
    font-weight: 400;
    font-size: 16px;
    margin-top: 2px;
  }
}

.footer-actions {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-right: 50px;
  gap: 40px;
}

.stop {
  background-color: #ef5555;
}
