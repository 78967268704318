@import '../../../../themeColors';
@import '../../../../components/globalFontMixins';

.profileCardTagsDetails{
    padding: 20px 32px 20px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    >div:first-of-type{
        display: flex;
        align-items: center;
        gap: 8px;
        >h4{
            @include normalText;
        }
        >h5{

            @include normalText($text-primary-2);
        }
        >h6{
            @include normalText($text-negative-primary);
        }
    }
    >div:nth-of-type(2){
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        >h6{
            @include normalText($text-primary-3);
        }
    }
    >.Collapsible { 
        display:flex;
        flex-flow: column;
        gap: 8px;
        > span{
            @include normalText($text-highlight-accent);
            cursor: pointer;
            order: 2;
        }
        >div{
            order: 1;
            >div{
                display: flex;
                flex-wrap: wrap;
                gap: 8px;
            
            }
        }
    }
}

.profileCardTagsDetailsTagFound{
    @include normalText;
    background-color: $bg-primary-5;
    border: 1px solid $stroke-primary-6;
    border-radius: 6px;
    padding: 0 4px;
}
.profileCardTagsDetailsTagMissing{
    @include normalText($text-negative-primary);
    background-color: $text-negative-secondary;
    border: 1px solid $stroke-negative;
    border-radius: 6px;
    padding: 0 4px;
}