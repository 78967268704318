@import '../../../themeColors';
@import '../../../components/globalFontMixins';
.PendingNotificationPanelLoader{
    width: 100%;
    padding: 20px;
    display: grid;
    place-content: center;
}
.PendingNotificationPanel{
    width: 100%;
    border: 1px solid $recommended;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 20px;
    >div>a>button{
        margin-top: 20px;
    }
    >div:first-child{
        display: flex;
        gap: 12px;
        >span:last-child{
            >h3{
                @include subHeading1;
                margin-bottom: 8px;
            }
            >p{
                @include normalText;
            }
            
        }
    }

}