@import "../../../themeColors";

@keyframes slideUpSkew {
    from {
      opacity: 0;
      transform: translate3d(0rem, 100rem, 500rem) scale(0) skewX(-90deg);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1) skewX(0);
    }
  }

@keyframes slideRightSkew {
    from {
      opacity: 0;
      transform: translate3d(-100rem, 0rem, 0rem) scale(0) skewX(-90deg);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1) skewX(0);
    }
  }
  
.cardBoolean {
    height: 100vh;
    display: flex;
    align-items: flex-start !important;
    justify-content: space-between !important;

    .suggestionFormGroupContainer {
        div {
            position: relative;

            .autosuggest-suggestions-container {
                position: absolute;
            }
        }
    }

    .booleanTerm {
        display: flex;
        flex-direction: column;
        width: 352px;
        color: $text-primary-1;
        border: 1px solid $stroke-primary-4;
        box-shadow: 0px 0px 8px $bg-primary-2;
        border-radius: 8px;
        padding: 32px 24px 32px 24px;
        margin-top: 40px;
        margin-right: 24px;
        animation: slideUpSkew 1.0s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

        .label {
            color: $text-primary-2;
            text-transform: uppercase;
            margin-bottom: 12px;
        }

        .greyedOut {
            color: $text-primary-3 !important;
        }

        .terms {
            .label {
                color: $text-primary-2 !important;
            }
            .value {
                color: $text-primary-1 !important;
            }
            .relation {
                color: $text-positive-primary !important;
            }
            .not {
                color: $text-negative-primary !important;
            }
        }
    }
    .innerBooleanContainer {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        overflow-y: scroll;
        height: 90vh;
        margin-right: 50px;
        width: 70vw;
        &::-webkit-scrollbar {
            width: 0 !important;
        }
        
        .dropdown-toggle {
            background-color: transparent !important;
            color: $text-primary-1;
            border: 0px;
            border-radius: 8px;
            padding: 0.49rem 0.85rem;
            margin-left: -12px;
        }

        .dropdown-menu {
            background-color: $bg-primary-5 !important;
            button {
                color: $text-primary-1;

                &:hover {
                    background-color: $bg-highlight-secondary;
                    color: $text-highlight-primary;
                }
            }
        }

        .addMore {
            display: flex;

            .dropdown-toggle {
                background-color: $bg-primary-4 !important;
            }

            &.inside {
                flex-direction: column;
            }
            &.outside {
                flex-direction: row;
            }
            align-items: center;
            .plusBtn {
                background: $bg-primary-4;
                border: none;
                padding-top: 5px;
                padding-bottom: 10px;

                &:hover {
                    background-color: $bg-highlight-secondary;
                    * {
                        stroke: $bg-highlight-primary;
                    }
                }
                animation: slideUpSkew 1.0s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

            }
            .verticalLine {
                height: 50px;
                width: 1px;
                background-color: $stroke-primary-4;
                animation: slideUpSkew 1.0s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            }
            .horizontalLine {
                height: 1px;
                width: 100px;
                background-color: $stroke-primary-4;
                animation: slideRightSkew 1.0s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            }
        }

        .multipleBooleanContainers {
            display: flex;
            margin-top: 24px;
            padding-bottom: 24px;
            margin-left: 24px;
        }

        .bottomFilterBodyContainer {
            flex-direction: column;
            border-radius: 8px;

            &.multiple {
                background-color: $bg-primary-2 !important;
            }

            .boxFilterSelections:first-child {
                margin-top: 24px;

                &.hasMultiple {
                    margin-top: 16px;
                }
                
            }

            .boxFilterSelections {
                display: flex;
                flex-direction: column;
                width: 352px;
                background-color: $bg-primary-1;
                border: 1px solid $stroke-primary-4;
                border-radius: 8px;
                padding: 24px 16px 16px 16px;
                margin-left: 24px;
                margin-right: 24px;
                animation: slideUpSkew 1.0s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

                &.hasMultiple {
                    margin-left: 16px;
                    margin-right: 16px;
                }

                .header {
                    display: flex;
                    justify-content: space-between;
                }
            }

        }

        
    }
    
}