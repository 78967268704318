@import "../../../components/globalFontMixins";
@import "../../../components/globalVariable";
@import "../../../components/globalCSSClasses";
@import "../../../themeColors";

@mixin fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-duration: 0.2s;
}

@mixin fade-out {
  opacity: 0;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-duration: 0.2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 1px 1px $stroke-highlight;
  }

  10% {
    box-shadow: 0 0 2px 2px $stroke-highlight;
  }

  20% {
    box-shadow: 0 0 3px 3px $stroke-highlight;
  }

  30% {
    box-shadow: 0 0 4px 4px $stroke-highlight;
  }

  40% {
    box-shadow: 0 0 5px 5px $stroke-highlight;
  }

  60% {
    box-shadow: 0 0 5px 5px $stroke-highlight;
  }

  70% {
    box-shadow: 0 0 4px 4px $stroke-highlight;
  }

  80% {
    box-shadow: 0 0 3px 3px $stroke-highlight;
  }

  90% {
    box-shadow: 0 0 2px 2px $stroke-highlight;
  }

  100% {
    box-shadow: 0 0 1px 1px $stroke-highlight;
  }
}

@keyframes pulse-out {
  0% {
    box-shadow: 0 0 5px 5px $stroke-highlight;
  }

  25% {
    box-shadow: 0 0 5px 5px $stroke-highlight;
  }

  50% {
    box-shadow: 0 0 3px 3px $stroke-highlight;
  }

  100% {
    box-shadow: 0 0 1px 1px $stroke-highlight;
  }
}

.supportBoxContainer {
  @include fade-in;
  @include backgroundTransition;
  background: #2c2c2c;
  border: 1px solid #333333;
  border-radius: 8px;
  width: 315px;
  border-radius: 8px;
  z-index: 50;
}

.botIconOpen {
  position: absolute;
  bottom: 43px;
  right: 20px;
  z-index: 10;
  cursor: pointer;
  box-shadow: 0 0 5px 5px $stroke-highlight;
  border-radius: 100%;
  animation: 2s linear infinite pulse;
}

.botIcon {
  position: absolute;
  bottom: 43px;
  right: 20px;
  z-index: 10;
  cursor: pointer;
  box-shadow: 0 0 0px 0px;
  border-radius: 100%;
  animation: 1s ease pulse-out;
}

.messagePanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  >p {
    @include globalInterFont;
    @include subHeading1;
    margin-left: 15px;
    margin-top: 20px;
  }
}

.messageContainers {
  @include globalInterFont;
  margin-top: 20px;
  background-color: $bg-primary-6;
  width: 95%;
  color: $text-primary-1;
  border-radius: 8px;
  border-color: $stroke-primary-4;
  padding: 24px 16px 33px 16px;

  >div {
    height: 40vh;
    overflow: auto;
  }
}

.messageContainerSecondary {
  @include globalInterFont;
  margin-top: 20px;
  background-color: $bg-primary-6;
  width: 90%;
  color: $text-primary-1;
  border-radius: 8px;
  border-color: $stroke-primary-4;
  padding: 24px 16px 33px 16px;

  >div {
    height: 14vh;
    overflow: auto;
  }
}

.controlButton {
  @include globalInterFont;
  @include backgroundTransition;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  display: grid;
  place-content: center;
  border-radius: 8px;
  width: 24px;
  height: 24px;

  &:hover,
  &:focus,
  &:active {
    background-color: $stroke-primary-7;
  }

  &:disabled {
    opacity: 0.7;
    background-color: $bg-primary-6;
    outline: none;
    box-shadow: none;
    border: none;
    cursor: not-allowed;
  }
}

.headingText {
  @include globalInterFont;
  @include subHeading1;
}

.windowControls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 45px;
}

.accordion {
  height: 100%;
  overflow-y: auto;
  max-height: 374px;
}

.fullScreenAccordion {
  // height: calc(100vh - 100px);
  overflow-y: auto;
}

.accordionGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333333;
}

.contactButton {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 70px;
  border: 0px solid #d6d6d6;
}



.JD {

  .accordion-item,
  .accordion-content {
    background: transparent;
  }

  .side-drawer {
    background: #1D1D1D;
    border-radius: 0px;

    &::before {
      content: '';
      position: absolute;
      pointer-events: none;
      bottom: 50%;
      height: 100%;
      width: 100%;
      left: 50%;
      background: linear-gradient(272.57deg, #EF5555 6.72%, #7440F5 92.66%);
      opacity: 0.16;
      filter: blur(150px);
      transform: rotate(-0.3deg);
    }

    .body {
      height: calc(100% - 60px);

      &>div {
        height: 100%;
      }
    }

    .windowControls {}
  }
}