@import '../../global.scss';
.formComponentContainer{
    display: flex;
    gap: 24px;
    flex-direction: column;
    width: 100%;
    >div:nth-child(1){
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    >div:nth-child(3){
        display: flex;
        align-items: flex-end;
        gap: 24px;
    }
    >div:nth-child(5){
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.formComponentTextInput{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    >label{
        @include bodyText2($text-primary-1);
    }
    >span{
        display: flex;
        align-items: center;
        // justify-content: space-between;
        flex-wrap: wrap;
        gap: 24px;
        >p{
            @include bodyText1($text-positive-primary);
        }
        >input{
            @include bodyText1;
            @include backgroundTransition;
            background-color: $bg-primary-4;
            border: 1px solid $stroke-primary-4;
            outline: none;
            border-radius: 8px;
            padding: 12px 16px;
            &:focus{
                background-color: $bg-primary-5;
                border: 1px solid $stroke-primary-5;
            }
            &:disabled{
                background-color: $bg-primary-1;
                border: 1px solid $stroke-primary-1;
            }
        }
    }
    >div{
        @include bodyText1($text-negative-primary);
    }
}

.formComponentSelectInput{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    >label{
        @include bodyText2($text-primary-1);
    }
    select{
        @include bodyText1;
        @include backgroundTransition;
        background-color: $bg-primary-4;
        border: 1px solid $stroke-primary-4;
        outline: none;
        border-radius: 8px;
        padding: 12px 16px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image:
            linear-gradient(45deg, transparent 50%, $stroke-primary-7 50%),
            linear-gradient(135deg, $stroke-primary-7 50%, transparent 50%);
        background-position:
            calc(100% - 22px) calc(18px), 
            calc(100% - 16px) calc(18px);
        background-size:
            6px 6px,
            6px 6px;
        background-repeat: no-repeat;
        min-width: 255px;
        &:focus{
            background-color: $bg-primary-5;
            border: 1px solid $stroke-primary-5;
        }
    }
    >div{
        @include bodyText1($text-negative-primary);
    }
}

.formComponentPrimaryButton{
    @include bodyText1($text-secondary-1, $bg-highlight-primary);
    @include backgroundTransition;
    outline: none;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    &:hover{
        background-color: $bg-highlight-hover;
    }
    &:disabled{
        background-color: $bg-highlight-disabled;
    }
}

.formComponentSecondaryButton{
    @include bodyText1($text-primary-1, transparent);
    @include backgroundTransition;
    outline: none;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    &:hover{
        color: $text-primary-2;
    }
    &:disabled{
        color: $text-primary-3;
    }
}




@media screen and (max-width: 712px) {
    .formComponentContainer{
        >div:nth-child(1){
            display: flex;
            align-items: flex-start;
            justify-content: unset;
            flex-direction: column;
            gap: 24px;
        }
    }
}

@media screen and (max-width: 536px) {
    .formComponentTextInput{
        >span{
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
        }
    }
}
