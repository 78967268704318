.textField{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-radius: 8px;


    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    border: 1px solid rgba(155, 160, 164, 0.5);
    width: 45%;
    padding: 8px;

    >textarea{
        color: #E0E0E0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;

        border: none;
        outline: none;
        resize: none;
        width: 96%;
        min-height: 30px;
        overflow-y:hidden;
        font-family: Poppins;
        background: transparent;
        padding: 2px 4px;
        padding-bottom: 0px;
    }
    >img{
        cursor: pointer;
        margin-right: 0.5rem;
        margin-bottom: 0.15rem;
    }

}