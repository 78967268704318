@import '../../themeColors';
@import '../../components/globalFontMixins';

.footerActionBar{   
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
    background: $bg-primary-3;
    border-top: 1px solid $stroke-primary-3;
    >div{
        width: calc(min(100%, 1080px));
        display: flex;
        align-items: center;
        justify-content: space-between;
        >span{
            display: flex;
            align-items: center;
            gap: 24px;
        }
    }
}