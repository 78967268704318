@import '../../../../themeColors';
@import '../../../../components/globalFontMixins';
.outboxMessageContainer{
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    background-color: $bg-primary-2;
    border: 1px solid $stroke-primary-2;
    border-radius: 8px;
    padding: 24px;
    >div:first-child{
        >p{
            @include globalInterFont;
            @include normalText($text-primary-2);
            margin: 8px 0;
        }
        >.DropdownContainer{
            margin-top: 16px;
            >.DropdownToggle, >.DropdownMenu{
                width: 350px;
            }
        }
    }
    .outboxMessageHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        >span{
            display: flex;
            align-items: center;
            gap: 16px;
            >h3{
                @include globalInterFont;
                @include heading3;
            }
            >span{
                width: 40px;
                height: 40px;
                display: grid;
                place-content: center;
                background-color: $bg-highlight-secondary;
                border-radius: 50%;
            }
            >.svgStrokeContainer{
                path{
                    stroke:  $bg-highlight-primary;
                }
            }
            >.svgFillContainer{
                path{
                    fill:  $bg-highlight-primary;
                }
            }
        }
    }
    >.outboxMessageBody{
    }
}