@import '../globalFontMixins';
@import '../globalVariable';
@import '../globalCSSClasses';
@import '../../themeColors';

.prospectLayout {
    background-color: $bg-primary-1;
}

.commentContainer {
    display: flex;
    width: 100vw;
    justify-content: flex-end;
}

.comments {
    transition: all 0.5s ease-in;
    // background: $primary-bg-3 !important; // REMOVING FOR BETTER VIEW
    &.show {
    //   min-width: 419px;
      opacity: 1;
      transform: translateX(0px);

      // * {
      //     display: block;
      // }
      .prospectComments {
        opacity: 1;
      }
    }
    &.hide {
      min-width: 0px;
      min-height: 100vh;
      opacity: 0;
      transform: translateX(400px);
      * {
        display: none;
      }
      .prospectComments {
        opacity: 0;
      }
    }
  
    .prospectComments {
        height: 100vh;
        background: $primary-bg-3 !important;
    }
  
    // padding: 0 1%;
    // top: -95px; // REMOVING FOR BETTER VIEW
    // left: 72.5%;
    display: flex;
    position: fixed;
    top: 69.5px;
    z-index: 9999;
    @media (max-width: 1366px) {
      // top: -95px; // REMOVING FOR BETTER VIEW
    //   left: 14%;
      position: fixed;
    }
  }