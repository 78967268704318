@import "../../../components/globalFontMixins";
@import "../../../components/globalVariable";
@import "../../../themeColors";

.tribesCommentSection {
  background-color: #222222;
  display: none;
  opacity: 0;
  max-width: 0rem;
  min-width: 0rem;
  height: 0rem;

  padding: 10px;
  color: $text-primary;
  transition: all 0.4s ease-in-out;
  &.expanded {
    display: block;
    opacity: 1;
    max-width: 30.688rem;
    min-width: 30.625rem;
    height: 100%;
    position: sticky;
    top: 0;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid $stroke-primary-3;
    .btn {
      color: $primary-1;
      background-color: $primary-4;
      border-radius: 8px;
      border: none;
      padding: 11px 12px;
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 261px);
    overflow-y: auto;
    padding-bottom: 34px;
    border-bottom: 1px solid $primary-border-grey;
    overflow-x: hidden;
    padding-left: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    transition: 0.3s all ease-in-out;
    &.newHeight {
      height: calc(100vh - 153px);
    }
    .loader {
      color: $bg-primary-7;
    }

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .single-comment {
      @include normalText($fontColor: $text-primary);
      padding-top: 26px;
      animation: slideInComments 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

      .dropdown-menu {
        background-color: $bg-primary-5 !important;
        button {
          padding: 5px !important;
          color: $text-primary-1 !important;
          &:hover {
            background-color: $bg-highlight-secondary !important;
            color: $text-highlight-primary !important;
          }
        }
      }

      .dropdown-toggle {
        margin-right: 0.7rem;
        background-color: $bg-primary-5 !important;
        color: $text-primary-1;
        border: 0px;
        border-radius: 8px;
        padding: 0.49rem 0.85rem;
        &:hover,
        &:active,
        &:focus {
          @include backgroundTransition;
          background-color: $border-grey;
          border: none;
          box-shadow: none !important;
          outline: none;
        }
      }
      .header {
        display: flex;
        justify-content: space-between;
        .commentSender {
          display: flex;
          gap: 8px;

          .senderImage {
            @include subText($padding: 6px 4px);
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-bottom: 8px;
          }
          .senderName {
            @include smallText();
            margin-top: 2px;
          }
        }

        .time {
          @include smallText($fontColor: $text-disabled);
          display: flex;
          .more {
            button {
              background: none;
              padding: 0;
              border: none;
              margin-top: -5px;
              margin-right: -6px;
            }
          }
        }
      }

      .messageContainer {
        display: flex;
        align-items: flex-start;
        .message {
          width: 332px;
          min-height: 76px;
          border-radius: 10px;
          background-color: $primary-bg-7;
          padding: 12px;

          strong {
            display: inline-block;
          }

          .reactions {
            margin-top: 12px;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            .emoji {
              display: flex;
              align-items: center;
              width: 40px;
              height: 32px;
              background-color: $bg-primary-6;
              border-radius: 39px;
              padding: 3px;
              cursor: pointer;
              .count {
                padding-left: 1px;
                padding-top: 2px;
                color: $text-primary-1;
              }

              &.emojiOwner {
                background: $bg-highlight-secondary !important;

                .count {
                  font-weight: bold;
                  color: $text-highlight-primary;
                }
              }

              .tooltiptext {
                display: none;
                background-color: $bg-primary-1;
                color: $text-primary-1;
                text-align: center;
                padding: 5px 15px;
                width: 150px;
                border-radius: 6px;
                position: absolute;
                top: 70px;
                left: 50px;
                z-index: 500 !important;

                span {
                  display: block;
                }
              }
              &:hover .tooltiptext {
                display: block;
              }
            }
          }

          .replyBox {
            background: $primary-bg-5;
            padding: 12px;
            border-radius: 10px;
            color: $text-secondary;
            margin-bottom: 24px;
          }
        }

        .actions {
          padding-left: 12px;
          display: flex;
          flex-direction: column;
          button {
            width: 32px;
            height: 32px;
            padding: 0 5px;
            margin-bottom: 12px;
            background-color: $primary-bg-7;
            border-radius: 8px;
            border: none;

            &:hover {
              background: $primary-bg-6 !important;
              * {
                stroke: $text-primary;
              }
            }
          }

          button:last-child {
            padding-left: 7px;
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    .inputInput {
      background: $primary-bg-6;
      border-radius: 10px;
      width: 80% !important;

      margin-left: 20px;
      margin-top: 20px;
      border: none;
      padding-bottom: 20px;
      color: $text-primary;

      .inputInput__input {
        border: none;
        color: $text-primary;
        padding: 10px;
      }

      .inputInput__suggestions {
        background-color: $primary-bg-5 !important;
        border-radius: 10px;
        ul li {
          padding: 8px;
          &:hover {
            background-color: $bg-highlight-secondary;
            color: $text-highlight-primary;
          }
        }
      }
    }

    .messageSend {
      padding: 7.5px;
      width: 36px;
      height: 36px;
      background: $primary-icon-4;
      border: none;
      margin-top: 20px;
      margin-left: 16px;
      * {
        stroke: $primary-1;
      }
      &:hover {
        background: $primary-bg-6 !important;
        * {
          stroke: $text-primary-1;
        }
      }
    }
  }
}
.inputBox {
  display: flex;
}
