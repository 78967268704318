@import "../../components/globalCSSClasses";
@import "../../components/globalFontMixins";
@import "../../components/globalVariable";
@import "../../themeColors";

.menuContainer {
  @include globalInterFont;
  padding: 0.8rem 0rem;
  align-self: flex-start;
  position: sticky;
  top: 0;
  border-bottom: 1px solid $bg-primary-5;
  z-index: 107;
  background-color: $bg-primary-1;
  .revampProspectPagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 10px;
    .paginationStatus {
      @include globalInterFont;
      color: $text-primary-1;
      padding-top: 10px;
    }
  }
  &.expanded {
    // width: calc(87vw - 12rem);
  }

  // &:after {
  //   content: "";
  //   background-color: $bg-primary-1;
  //   height: 5.438rem;
  //   // border-bottom: 1px solid $bg-primary-5;
  //   width: 100%;
  //   top: -16px;
  //   // right: calc(-9% - 0.7vw); // creating issues
  //   border-bottom: 1px solid $bg-primary-5;
  //   position: absolute;
  //   // padding-left: 100px;
  //   z-index: -100;
  //   // @media (max-width: 1367px) {
  //   //   // width: 200px;
  //   //   // right: -9%; // creating issues
  //   // }
  // }

  .filterCount {
    @include globalInterFont;
    @include normalText($text-highlight-primary);
    text-decoration: none;
    cursor: pointer;
  }

  .selectTopDropDownMenu {
    background-color: $bg-primary-5;
    .topSelection {
      background: $bg-primary-3;
      color: $text-primary;
    }
    > button {
      @include globalInterFont;
      @include normalText;
      display: flex;
      align-items: center;
      padding: 8px;

      &:hover,
      &:focus,
      &:active {
        @include globalInterFont;
        @include normalText;
        padding: 8px;
        background-color: $bg-highlight-secondary;
        color: $text-highlight-primary;
      }
    }
    > button:first-child {
      &:hover,
      &:focus,
      &:active {
        background-color: $bg-highlight-secondary;
      }
    }
  }
  .actionbarFilterGreyButton {
    @extend .secondaryGreyButton;
  }
  .actionbarFilterButton {
    @extend .secondaryButton;
    &.selected {
      background-color: $bg-highlight-secondary;
      color: $text-highlight-primary;
      * {
        stroke: $bg-highlight-primary;
      }
    }
    color: $text-primary-1;
    background-color: $bg-primary-6;
  }

  .topMenuBar {
    display: flex;
    padding-inline: 10px;
    // gap: calc(19vw);

    justify-content: space-between;
    flex-wrap: wrap;

    .dropdown-toggle::after {
      margin-left: 0.855em;
      vertical-align: 0.155em;
    }

    .checkChevron {
      margin-right: 1rem;
      margin-left: -5px;
      .btn {
        margin-left: -0.2rem;
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        &:focus {
          box-shadow: none !important;
        }
      }

      .dropdown-menu.show {
        // top: 56% !important;
        opacity: 1 !important;
        pointer-events: all !important;

        button:nth-child(3) {
          display: flex;
          justify-content: flex-end;
        }
      }

      .form-control-lg {
        min-height: calc(0.7em + 0.7rem + 2px);
        padding: 0.5rem 0.8rem;
        font-size: 1.25rem;
        cursor: pointer;
        background-color: $bg-primary-5;
        margin-bottom: 0.1rem;

        &:focus,
        &:active,
        &:checked {
          border-color: $stroke-primary-5;
          outline: 0;
          box-shadow: none;
        }

        &:checked[type="checkbox"] {
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.33337 8H12.6667' stroke='%23A0A0A0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
        // margin-top: 0.3rem;
      }
    }

    .selectDropdownMenu {
      position: absolute;
      background: $plain-white;
      border: 1px solid $border-grey;
      border-radius: 10px;
      top: 9.5rem;
      z-index: 100;
      div {
        display: flex;
        justify-content: flex-end;

        button {
          margin-right: 1rem;
          margin-bottom: 1rem;
        }
      }

      p {
        padding: 15px;
      }

      p:hover {
        background-color: $border-grey;
      }
    }

    .topMenuIcons-right {
      display: flex;
      // margin-left: 2rem;
      .selected-prospects-count {
        @include globalInterFont;
        color: $text-highlight-primary;
        align-self: center;
        margin-left: 0.5rem;
      }
      .topMenuIcons-right-actions-container {
        display: flex;
        gap: 12px;
        align-items: center;
        > button {
          width: 40px;
          height: 40px;
        }
        .topMenuIcons-right-actions-buttons {
          background-color: $bg-highlight-secondary;
          color: $text-highlight-primary;
          * {
            stroke: $bg-highlight-primary;
          }

          &:hover {
            background-color: $bg-primary-5;
          }
        }
      }
    }

    .topMenuIcons-left {
      display: flex;
      align-items: center;
      gap: 12px;

      div.imgButton {
        background: $secondary-grey;
        border-radius: 5px;
        padding: 5px;
        width: 36px;
        height: 36px;
        margin-right: 0.7rem;
      }

      .search {
        width: 40px;
        height: 40px;
        background-color: $bg-primary-5;
        * {
          stroke: $stroke-secondary-2;
        }
        &:hover {
          background-color: $bg-highlight-secondary;
          * {
            stroke: $bg-highlight-primary;
          }
        }
      }
    }

    .vertical-line {
      width: 1px;
      height: 30px;
      background-color: $stroke-primary-5;
    }

    .filterOrSort {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      * {
        stroke: $bg-highlight-primary;
      }
      &:hover {
        background-color: $bg-primary-5;
      }

      &:focus {
        background-color: $bg-primary-5;
      }
    }

    .addedfilterButtonDropdown {
      .dropdown-menu {
        background-color: $bg-primary-5 !important;
        button {
          color: $text-primary-1 !important;
          &:hover {
            background-color: $bg-highlight-secondary !important;
            color: $text-highlight-primary !important;
          }
        }
      }

      .dropdown-toggle {
        // margin-right: 0.7rem;
        background-color: $bg-primary-5 !important;
        color: $text-primary-1;
        border: 0px;
        border-radius: 8px;
        padding: 0.49rem 0.85rem;
        &:hover,
        &:active,
        &:focus {
          @include backgroundTransition;
          background-color: $border-grey;
          border: none;
          box-shadow: none !important;
          outline: none;
        }
      }
    }

    .addedfilterButtonGroup {
      @extend .secondaryGreyButton;
      background-color: $bg-primary-5;
      border-radius: 0;
      * {
        fill: $text-primary-1;
      }
      &:hover {
        background-color: $bg-highlight-secondary !important;
      }
      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      &.active {
        background-color: $bg-highlight-secondary !important;
        color: $primary-blue !important;
        font-weight: 500;

        * {
          fill: $bg-highlight-primary;
        }
      }

      &:focus {
        background-color: $bg-primary-5;
      }
    }
  }
}
