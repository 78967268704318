@import '../../../../themeColors';
@import '../../../../components/globalFontMixins'; 
.ProfileCompanyCard{
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 8px;
    background-color: $bg-primary-3;
    border: 1px solid $stroke-primary-3;
    max-width: 375px;
    padding: 12px 16px;
    overflow: hidden;
    >button{
        @include backgroundTransition;
        position: absolute;
        top: 12px;
        right: 16px;
        display: grid;
        place-content: center;
        width: 24px;
        height: 24px;
        outline: none;
        border-radius: 8px;
        border: 1px solid $stroke-primary-4;
        background-color: $bg-primary-4;
        &:hover{
            border: 1px solid $stroke-primary-5;
            background-color: $bg-primary-5;

        }
    }
    >p{
        @include normalText($text-primary-2);
        padding-left: 58px;
    }
}

.ProfileAutoSuggest{
    display: flex;
    flex-direction: column;
    gap: 16px;
    >div{
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
    }
    >h5{
        @include normalText($text-primary-2);
    }
}