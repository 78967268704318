@import '../../../components/globalFontMixins';
@import '../../../themeColors';

.criteriaTotalExperienceContainer{
    background-color: $bg-primary-2;
    height: 100%;
    padding:  48px 24px 32px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 42px;
    >div:last-child{
        padding-bottom: 120px;
    }
    >.criteriaTotalExperiencesHeader{
        display: flex;
        align-items: center;
        gap: 12px;
         >h2{
             @include globalInterFont;
             @include heading3;
         }
    }
    >.criteriaTotalExperiencesBody{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 32px;
        gap: 24px;
        >.criteriaTotalExperiencesCounterContainer{
            display: flex;
            align-items: center;
            gap: 12px;
            >.inputOuter_Container{
                width: 75px;
            }
            >p{
                @include globalInterFont;
                @include normalText;
            }
        }
        >h3{
            @include globalRubikFont;
            @include normalText($text-primary-2);
        }
        >div{
            margin-left: -12px;
            width: 350px
        }
        >p{
            @include globalInterFont;
            @include normalText($text-highlight-primary);
            padding: 12px 16px;
            border-radius: 36px;
            border: 1px solid $stroke-highlight;
        }
    }
}