@import "../../../themeColors";
@import "../../../components/globalFontMixins";

.profileHeader {
  display: flex;
  flex-direction: row;
  width: 36vw;
  min-width: 36vw;
  height: 128px;
  background-color: $bg-primary-3;
  border-radius: 7px 7px 0px 0px;
  border-bottom: 1px solid $bg-primary-5;
}

.superLikeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 95px;
  height: 37px;
  background: $bg-primary-5;
  border: 1px solid $stroke-primary-6;
  border-radius: 45px;
}

.superLikeContainer:hover {
  border: 1px solid #802146;
}

.profileHeaderLeft {
  display: flex;
  width: 27vw;
  margin-left: 10px;
  height: 115px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  align-self: center;
  gap: 18px;
}

.profileHeaderInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 25vw;
  gap: 3px;
  margin-bottom: 5px;
  > span {
    @include globalInterFont;
    font-weight: 600;
    font-size: 18px;
    line-height: 121.02%;
    color: $text-primary-1;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  > p {
    @include globalInterFont;
    font-weight: 400;
    font-size: 16px;
    line-height: 121.02%;
    color: $text-primary-1;
  }
}

.role {
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;

  .position {
    @include globalInterFont;
    font-weight: 400;
    font-size: 16px;
    line-height: 121.02%;
    color: $text-primary-1;
  }

  .at {
    @include globalInterFont;
    font-weight: 400;
    font-size: 16px;
    line-height: 121.02%;
    color: $text-primary-3;
  }

  .companyName {
    @include globalInterFont;
    font-weight: 400;
    font-size: 16px;
    line-height: 121.02%;
    color: #4e95ff;
    cursor: pointer;
  }

  .companyName:hover {
    text-decoration: underline;
    color: #4e95ff;
  }
}

.profileHeaderRight {
  display: flex;
  width: 8vw;
  height: 100px;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  align-self: center;
  gap: 20px;

  > p {
    @include globalInterFont;
    font-weight: 400;
    font-size: 16px;
    line-height: 121.02%;
    color: #4e95ff;
    cursor: pointer;
    pointer-events: all;
  }
}

.profileFeedback {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

@keyframes slide {
  0% {
    width: 0;
  }
  100% {
    width: 48vw;
  }
}

.slide-drawer {
  background: $bg-primary-3;
  border: 1px solid $bg-primary-5;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 0;
  right: 0;
  width: 48vw;
  z-index: 200;
  animation-name: slide;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.2s;
}

.loadingCountDown {
  color: white;
  font-family: Michroma;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.725em;
  position: absolute;
  top: 50vh;
  right: 45vw;
}
