@import '../../../../themeColors';
@import '../../../../components/globalFontMixins';
.outboxRoundContainer{
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    background-color: $bg-primary-2;
    border: 1px solid $stroke-primary-2;
    border-radius: 8px;
    padding: 24px;
    >.outboxRoundHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        >span{
            display: flex;
            align-items: center;
            gap: 16px;
            >.outboxRemoveRound{
                width: 24px;
                height: 24px;
                display: grid;
                place-content: center;
                border: none;
                outline: none;
                background: $bg-primary-5;
                border-radius: 8px;
                &:hover, &:active{
                    background: $bg-primary-6;
                    border: none;
                    outline: none;
                }
            }
            >h3{
                @include globalInterFont;
                @include heading3;
            }
            >span{
                width: 40px;
                height: 40px;
                display: grid;
                place-content: center;
                background-color: $bg-highlight-secondary;
                border-radius: 50%;
            }
        }
    }
    >.outboxRoundBody{
        display: flex;
        align-items: center;
        gap: 24px;
        >span{
            width: calc(50% - 12px);
            >div{
                display: flex;
                align-items: center;
                gap: 24px;
                .inputOuter_Container{
                    width: calc(50% - 12px)
                }
            }
            >h4{
                @include globalRubikFont;
                @include normalText($text-primary-2);
                margin-bottom: 8px;
            }
            h6{
                @include globalInterFont;
                @include normalText($text-primary-3, $bg-primary-3, 12px 16px);
                border-radius: 8px;
            }
            .outboxRoundBodyEmail{
                overflow: hidden;
                text-overflow: ellipsis;
                width: calc(50% - 12px);
            }
            >div{
                >.DropdownMenu, >.DropdownToggle{
                    width: 100%;
                    >button{
                        width: 100%;
                    }
                }
                >.DropdownContainer{
                    width: calc(50% - 12px);
                    >.DropdownMenu{
                        width: 100%;
                        min-width: unset;
                        max-height: unset;
                        button{
                            width: 100%;
                        }
                    } 
                    >.DropdownToggle{
                        min-width: 100%;
                    }
                }
            }
            
        }
    }
}