@import "../../../themeColors";
@import "../../../components/globalFontMixins";
@import "../../../components/globalCSSClasses";

.criteriaAdditionalTitle {
  background-color: $bg-primary-2;
  height: 100%;
  padding: 48px 24px 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 42px;
  > .criteriaAdditionalTitleHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > span {
      display: flex;
      align-items: center;
      gap: 12px;
      > h2 {
        @include globalInterFont;
        @include heading3;
      }
    }
  }
  > .criteriaAdditionalTitleBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    >  .genericTitle{
      margin-top: -35px;
      border-radius: 8px;
      justify-content: space-between;
      gap: 12px;
      align-items: center;
      background-color: var(--recommended-secondary);
      padding: 8px 16px;
    }
    > h3 {
      @include globalRubikFont;
      @include normalText($text-primary-2);
    }
    >span:first-child{
      margin-bottom: -24px;
      >h5{
        @include globalRubikFont;
        @include normalText($text-primary-3);
      }
      >h5:first-child{
        width: 320px;
      }
    }
    > span {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;
      .titleInputApplyToAll{
        background: none;
        border: none;
        outline: none;
        @include globalInterFont;
        @include subHeading1($text-primary-3);
        @include backgroundTransition;
        &:hover{
          color: $text-highlight-primary;
        }
      }
      > input {
        @extend .primaryInput;
      }
    }
  }
  .companySizeInput {
    top: -16px;
    position: relative;
    margin-top: 12px;
    > h3 {
      @include globalRubikFont;
      @include normalText($text-primary-2);
      top: -8px;
      position: relative;
    }
    > span,
    .companySizeOptions {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
    }
  }
  > .criteriaAdditionalTitleFooter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    padding-bottom: 120px;
    > hr {
      width: 100%;
      background-color: $stroke-secondary-1;
    }
    > h3 {
      @include globalRubikFont;
      @include normalText($text-primary-2);
    }
    > span {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      > input {
        @extend .primaryInput;
      }
    }
  }
}
