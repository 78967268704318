@import '../../../components/globalCSSClasses';
@import '../../../components/globalFontMixins';

.criteriaFunctionsContainer{
    // background-color: $bg-primary-2;
    height: 100%;
    padding:  48px 24px 32px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 42px;
    >div:first-child{
        display: flex;
        align-items: center;
        gap: 12px;
         >h2{
             @include globalInterFont;
             @include heading3;
         }
    }
    .criteriaFunctionsBody{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 42px;
        >span{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 12px;
            >input{
                @extend .primaryInput
            }
        };
    }
 }