@import "../../../themeColors";
@import "../../../components/globalFontMixins";
@import "../../../components/globalCSSClasses";

.criteriaMainTitle {
  background-color: $bg-primary-2;
  height: 100%;
  padding: 48px 24px 32px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 42px;
  > .criteriaMainTitleHeader {
    display: flex;
    align-items: center;
    gap: 12px;
    > h2 {
      @include globalInterFont;
      @include heading3;
    }
  }
  > div .genericTitle{
    border-radius: 8px;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
    background-color: var(--recommended-secondary);
    padding: 8px 16px;
  }
  > .criteriaMainTitleBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    > h3 {
      @include globalRubikFont;
      @include normalText($text-primary-2);
    }
    > span {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;
      > input {
        @extend .primaryInput;
      }
      > .goodTitle {
        @include globalInterFont;
        @include smallText(
          $text-positive-primary,
          $bg-positive-secondary,
          4px 8px
        );
        border-radius: 6px;
      }
      > .badTitle {
        @include globalInterFont;
        @include smallText(
          $text-negative-primary,
          $bg-negative-secondary,
          4px 8px
        );
        border-radius: 6px;
      }
      > .titleResult {
        @include globalInterFont;
        @include smallText($text-primary-2, $bg-primary-6, 4px 8px);
        border-radius: 6px;
      }
    }
    > .titleSuggestionContainer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;    
    }
  }
  .companySizeOptions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
  }
}
