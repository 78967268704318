@import '../../themeColors';
@import '../../components/globalFontMixins';

.qluSvgStroke{
    @include backgroundTransition;
    stroke: $stroke-secondary-1;
    &Selected{
        @include backgroundTransition;
        stroke: $stroke-highlight;
    }
    &Recommended{
        @include backgroundTransition;
        stroke: $recommended;
    }
}
.qluSvgFill{
    @include backgroundTransition;
    fill: $stroke-secondary-1;
    &Selected{
        @include backgroundTransition;
        fill: $stroke-highlight;
    }
}
