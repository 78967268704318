@import "../../themeColors";
.sectionHeaderRoot{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    >p {
        color: $text-primary-1;
    }

    > div {
        display: flex;
    }


}

