@import "../../themeColors";
@import "../../components/globalFontMixins";
@import "../../components/globalFontMixins";
@import "../../components/globalCSSClasses";
@import "../../components/globalVariable";
.profileButtons {
  display: flex;
  flex-direction: row;
  background-color: $bg-primary-5;
  justify-content: center;
  box-sizing: border-box;
  width: 32%;
  height: 48px;
  border: 1px solid $stroke-primary-6;
  border-radius: 45px;
  transition: 0.3s all ease-in-out;
  &:active {
    transform: scale(0.88);
  }
  &.tick:focus,
  &.question:focus,
  &.cross:focus {
    background-color: $bg-primary-4;
  }
  &.tick {
    &.active {
      background-color: $bg-heart-secondary;
      border-color: $stroke-heart;
    }
  }
  &.question {
    &.active {
      background-color: $bg-maybe-secondary;
      border-color: $stroke-maybe;
    }
  }
  &.cross {
    &.active {
      background-color: $bg-negative-secondary;
      border-color: $stroke-negative;
    }
  }

  &.tick:hover {
    background-color: $bg-primary-6;
  }
  &.question:hover {
    background-color: $bg-primary-6;
  }
  &.cross:hover {
    background-color: $bg-primary-6;
  }
  .tick {
    * {
      stroke: $bg-positive-primary;
    }
  }
  .question {
    * {
      stroke: #ffc321;
    }
  }
  .cross {
    * {
      stroke: $bg-negative-primary;
    }
  }
}
