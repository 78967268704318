@import "../../themeColors";
@import "../../components/globalFontMixins";
@import "../../components/globalFontMixins";
@import "../../components/globalCSSClasses";
@import "../../components/globalVariable";

button:active,
button:focus {
  box-shadow: none !important;
  outline: none !important;
}
.profileLikedStatusSelector {
  @include globalInterFont;
  color: $text-primary-3;

  > div {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
@keyframes slideUpSmall {
  from {
    opacity: 0;
    transform: translate3d(0rem, 100rem, 500rem);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.reviewProfileBtn {
  background-color: $bg-primary-7;
  border-radius: 6px;
}
.closeButtonPopup {
  background: none;
  border: none;
  color: $text-secondary-1;
  border-radius: 6px;
  &:hover {
    background: none;
    text-decoration: underline;
    color: $text-secondary-1;
  }
}
.prospectCard {
  display: flex;
  flex-direction: column;
  background: $bg-primary-3;
  border: 1px solid $bg-primary-5;
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 349px;
  min-height: 500px;
  // margin-bottom: 1rem;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.05));
  padding: 0px 16px !important;
  .reviewProfile {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }
  .qualificationStatus {
    color: $primary-red;
    background-color: $bg-negative-secondary;
    border-radius: 6px;
    width: fit-content;
    padding: 5px;
    display: flex;
    align-self: center;
    margin-top: 5px;
  }
  .reviewProfileBtnAgain {
    @include globalInterFont;
    border-radius: 8px;
    background-color: $bg-primary-5;
    color: $text-primary-1;
    border: none;
    &:hover {
      background-color: $bg-primary-6;
    }
  }
  .reviewProfileBtn {
    @include globalInterFont;
    border-radius: 8px;
    background-color: $bg-highlight-secondary;
    color: $primary-1;
    border: none;
    &:hover {
      background-color: $bg-highlight-primary;
      color: $bg-highlight-secondary;
    }
  }
  &:hover {
    border: 1px solid $primary-1;
    cursor: pointer;
  }
  .revampProfileImg {
    position: relative;
    .recommended {
      display: block;
      border: 3px solid $recommended;
    }
    .fa-recommended {
      position: absolute;
      top: 0;
      right: 0;
      background-color: $recommended;
      height: 20px;
      width: 20px;
      padding: 2px;
      border-radius: 50%;

      fill: $bg-primary-6;
    }
  }

  .prospectIndex {
    display: flex;
    row-gap: 10px;
    flex-direction: column;
    margin-top: -7px;
    align-items: center;
    span {
      color: $text-primary-3;
    }
  }

  &.prospectCardMid {
    width: 18.75rem;
    border: none;
    border-radius: 8px !important;
    background: none;
  }
  .addedfilterButtonGroup {
    @extend .secondaryGreyButton;
    height: 40px;
    width: 43px;
    background-color: transparent;
    border: none;
    border-radius: 6px;
    * {
      fill: $text-primary-3;
    }
    &:hover {
      background-color: $bg-highlight-secondary !important;
    }

    &.active {
      background-color: $bg-highlight-secondary !important;
      color: $primary-blue !important;
      font-weight: 500;

      * {
        fill: $bg-highlight-primary;
      }
    }

    &:focus {
      background-color: $bg-primary-5;
    }
  }

  aside.emoji-picker-react {
    left: 53px;
    box-shadow: none;
    position: absolute;
    margin-top: -36%;
    top: 200px;
  }

  &.fullPage {
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0));
    border: none;
    position: sticky;
    top: 75px;
  }

  .prospectCardAction {
    display: flex;
    justify-content: space-between;
    padding: 22px 0px 22px 0px;
    flex-direction: row;

    .form-check-input {
      background-color: #0003;
      border: 2px solid $stroke-secondary-1;
      &:active,
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:checked {
        background-color: $text-highlight-primary;
      }
    }

    .form-control-lg {
      min-height: calc(0.6em + 0.6rem + 2px);
      padding: 0.7rem 0.7rem;
      font-size: 1.25rem;
      margin-top: -1px;
    }
  }

  .checkBox,
  .bookmark,
  .messageButton {
    cursor: pointer;
    height: 24px;
    margin-top: -0.5rem;
    &:focus {
      box-shadow: none !important;
    }
  }

  .bookmark {
    padding-left: 0px;
    padding-right: 0px;
  }

  .prospectCard-titleInfo {
    @include globalInterFont;
    display: flex;
    flex-direction: column;
    justify-content: start;
    flex-wrap: nowrap;
    align-items: center;
    min-height: 115px;
    // max-height: 140px;
    margin-top: -5px;
    &.recommended {
      margin-top: 5px;
    }
    .recommendedTitle {
      color: $recommended;
      background-color: $recommended-secondary;
      padding: 2px 6px;
      border-radius: 6px;
      margin-top: 5px;
    }
    .nameTitle {
      @include heading2;
      padding-bottom: 10px !important;
      // width: 300px;

      display: flex;
      .fullTitle {
        max-width: 285px;
        max-height: 30px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      .linkedInLogo {
        margin-left: 5px;
        margin-bottom: 3px;

        &:hover {
          fill: #0072b1;
          path {
            fill: #0072b1;
          }
        }
      }
    }
    .jobtooltip {
      position: relative;
      display: inline-block;
    }
    .tooltiptext {
      display: none;
      width: 20rem;
      background-color: $bg-primary-1;
      color: $text-primary-1;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
    }
    &:hover .tooltiptext {
      display: block;
    }
    .jobTitle {
      @include subHeading1($fontWeight: 400);
      margin-bottom: 5px !important;
      text-overflow: ellipsis;
      // max-height: 70px;
      // overflow-y: auto;
      // min-height: 47px;
      // div[data-name],
      // div[data-company] {
      //   max-width: 140px;
      //   max-height: 20px;
      //   display: -webkit-box;
      //   -webkit-box-orient: vertical;
      //   -webkit-line-clamp: 1;
      //   overflow: hidden;
      // }
      max-height: 45px;
      width: 300px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      padding-inline: 7px;
    }

    .location {
      @include normalText($text-primary-2);
      max-width: 285px;
      max-height: 60px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      // min-height: 24px;
    }
  }

  .prospectBars {
    margin-top: 22px;
    // display: flex;
    // flex-direction: row;
    div[data-name]:hover {
      color: var(--bg-highlight-primary);
      background-color: var(--bg-highlight-secondary);
    }
    &.removeFlex {
      display: block;
    }
    .titles {
      margin-bottom: 1.5rem;

      p {
        @include normalText;
        margin-top: 10px;
        width: 100px;
        margin-bottom: 0px;
      }

      & > .row > div > .totalScore {
        font-weight: 600;
      }

      .barFull {
        margin-top: 1.7em;
        // margin-right: 1em;
      }

      .barFull {
        &.progress {
          background-color: $bg-primary-6;
          .progress-bar {
            background-color: $text-highlight-primary !important;
          }
        }
      }

      .barFull.progress {
        height: 4px !important;
        margin-left: 10px;
      }
    }
  }

  .horizontalLine {
    border-top: 1px solid $bg-primary-5;
    display: flex;
    position: relative;
    width: 349px;
    left: -1rem;
  }

  .experienceScore {
    margin-top: 1.7rem;

    .experienceSubScores {
      margin-left: 0.5rem;
      margin-bottom: 0.5rem;
      p:first-child {
        font-weight: 400;
      }
    }
  }

  .experienceScoreBar {
    padding-top: 0.9rem;

    .barFull_3.barProgress {
      background: $bg-highlight-primary !important;
    }
  }
  .EmojiToolTip {
    display: flex;
    position: relative;
    margin-top: -28px;
    // justify-content: center;
    z-index: 9999;

    span {
      background: black;
      padding: 2px 3px;
      border-radius: 6px;
      color: $text-primary-1;
    }
  }
  .collab {
    margin-top: 10px;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;

    .btnP {
      display: flex;
      align-items: center;
      background: $bg-highlight-secondary;
      color: $bg-highlight-primary;
      font-weight: bold;
      // width: 32px;
      border-radius: 4px;
      height: 32px;
      // padding: 2px 3px;
    }
    .emojiContainer {
      display: flex;
      max-width: 170px;
      gap: 5px;
      padding-bottom: 5px;
      overflow-x: auto;
      button {
        display: flex;
        background: $bg-primary-6;
        min-width: 32px;
        height: 32px;
        padding: 2px 3px;
        align-items: center;
        border-radius: 37px;
      }
    }
    .commentIcon {
      * {
        stroke: var(--bg-highlight-primary);
      }
      color: $text-primary-1;
    }
    div:not(:first-child) {
      // margin-left: 15px;
    }

    // div:first-child {
    //   button {
    //     display: flex;
    //     align-items: center;
    //     background: $bg-highlight-secondary;
    //     color: $bg-highlight-primary;
    //     font-weight: bold;
    //     // min-width: 32px;
    //     height: 32px;
    //     padding: 2px 3px;
    //   }
    // }

    // div:last-child {
    //   button {
    //     display: flex;
    //     align-items: center;
    //     background: $bg-highlight-secondary;
    //     color: $bg-highlight-primary;
    //     font-weight: bold;
    //     border-radius: 6px;
    //     height: 32px;
    //     padding: 2px 3px;
    //   }
    // }
    .toggleEmoji {
      * {
        stroke: var(--bg-highlight-primary);
      }
    }
    .emojiOwner {
      .count {
        // background: $bg-highlight-secondary !important;
        // color: $text-highlight-primary;
        font-weight: bold;
      }
      .emoji {
        font-size: 1.25rem;
      }
    }
    .emojies {
      position: relative;

      .tooltip-inner {
        margin: 20px;
      }
    }
    button {
      color: $text-primary-1;
      span.count {
        padding: 1px 0;
      }
      .emoji {
        font-size: 17px;
      }
    }
  }
}
