@import "../../themeColors";
@import "../../components/globalFontMixins";
@import "../../components/globalFontMixins";
@import "../../components/globalCSSClasses";
@import "../../components/globalVariable";
@keyframes slideUpSmall {
  from {
    opacity: 0;
    transform: translate3d(0rem, 100rem, 500rem);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.revampProspectMidCard {
  width: 668px;
  height: 706px;
  background-color: var(--bg-primary-3);
  margin-bottom: 20px;
  display: flex;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 8px;
  div[data-name] {
    transition: 0.2s all ease-in-out;
  }
  &:hover {
    border: 1px solid $primary-1;
    cursor: pointer;
  }
  .skillHighLight {
    background-color: $bg-highlight-secondary;
    border-radius: 4px;
  }
}
.summaryDetails {
  margin: 24px 12px;
  color: $text-primary-1;
  // max-height: 520px;
  overflow-y: auto;
  .experience,
  .education,
  .skill,
  .speciality {
    padding: 5px;
  }
  .speciality {
    .speciality-list {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-left: -5px;
      flex-direction: row;
    }
  }
  @include globalInterFont;
  line-height: 146.02%;
  .mainTitle {
    @include globalRubikFont;
    color: $text-primary-3;
    span {
      @include globalInterFont;
      text-transform: none;
    }
    .missing {
      color: $primary-red;
    }
  }
  .lengthMore {
    color: $text-primary-3;
    width: fit-content;
    padding: 5px;
    border-radius: 8px;
    // margin-left: -5px;
    transition: all 0.2s ease;
    &.missing {
      color: $primary-red;
      // background-color: $bg-negative-secondary;
      font-size: small;
    }
    &:hover {
      background: $bg-highlight-secondary;
      color: $text-primary;
    }
  }
  .experience {
    margin-bottom: 28px;
    .currentHeadline {
      // width: 350px;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 1;
      // overflow: scroll;
      // .currentHeadlineChild {
      //   overflow: hidden;
      //   display: inline-block;
      //   max-width: 15ch;
      //   text-overflow: ellipsis;
      //   white-space: nowrap;
      // }
    }
    .currentHeadline > span:last-child {
      @include globalInterFont;
      @include normalText($bg-highlight-primary, $bg-highlight-secondary);
      padding: 2px 4px;
      border-radius: 4px;
    }
    .single-experience {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .experienceDate {
      color: $text-primary-2;
    }
  }
  .education {
    margin-bottom: 28px;
    .duration {
      color: $text-primary-2;
    }
  }
  .single-value {
    background-color: $bg-primary-6;
    padding: 1px 4px;
    border-radius: 6px;
    margin: 4px 4px;
    text-transform: capitalize;
    &.missing {
      color: $primary-red;
      background-color: $bg-negative-secondary;
    }
  }
  .skill {
    margin-bottom: 28px;
    .skill-list {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-left: -5px;
      flex-direction: row;
    }
  }
}
