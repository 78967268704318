@import "../../themeColors";
@import "../../components/globalFontMixins";
@import "../../components/globalFontMixins";
@import "../../components/globalCSSClasses";
@import "../../components/globalVariable";

.unlockRecommendation {
  padding: 24px;
  width: 365px;
  height: 183px;
  background-color: $primary-bg-4;
  color: $text-primary-2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: sticky;
  top: 183px;
  border-radius: 12px;
  @include globalInterFont;

  .title {
    color: $text-primary;
    font-weight: 600;
  }
  .iconListContainer {
    display: flex;
  }
  .recommendedProgressContainer {
    width: 317px;
    .recommendedProgress {
      height: 8px;
      width: 314px;
      border-radius: 4px;
      margin-left: 5px;
      &.progress {
        background-color: $bg-primary-6;
        .progress-bar {
          background-color: $text-highlight-primary !important;
        }
      }
    }
  }
  .iconList {
    border-radius: 6px;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-bg-6;
    transition: 0.3s all ease-in-out;
    &.tick {
      // &:hover {
      //   background-color: $bg-positive-secondary;
      // }
      * {
        stroke: $bg-positive-primary;
      }
    }
    &.question {
      // &:hover {
      //   background-color: $bg-highlight-secondary;
      // }
      * {
        stroke: #ffc321;
      }
    }
    &.cross {
      // &:hover {
      //   background-color: $bg-negative-secondary;
      // }
      * {
        stroke: $bg-negative-primary;
      }
    }
  }
}
