@import "../../../components/globalCSSClasses";
@import "../../../components/globalFontMixins";
@import "../../../themeColors";

.tribeMemberContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  background-color: $bg-primary-2;
  border-radius: 8px;
  padding: 32px 32px 98px 32px;
  h4{
    @include globalRubikFont;
    @include normalText($text-primary-2);
  }
  >div:first-child{
    display: flex;
    align-items: center;
    gap: 12px;
    h3{
      @include globalInterFont;
      @include normalText;
    }
    >.tribeMemberTick{
      background-color: $bg-positive-secondary;
      display: grid;
      place-content: center;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      >svg >path{
        stroke: $stroke-positive;
      }
    }
  }
  >div:nth-child(2){
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    >h1{
      @include globalInterFont;
      @include heading2;
    }
    >span{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      >p{
        @include globalInterFont;
        @include normalText($text-highlight-primary, $bg-highlight-secondary, 4px 8px);
        border-radius: 8px;
      }
    }
  }
  >.tribeMembersRadioCardsContainer{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    >h4{
      @include globalRubikFont;
      @include normalText($text-primary-3);
      // margin-bottom: 12px;
    }
    >p{
      @include globalInterFont;
      @include normalText($text-primary-3);
      margin-bottom: 12px;
    }
    >div{
      display: flex;
      align-items: center;
      gap: 12px;
    }
    >span{
      width: 100%;
      display: flex;
      gap: 12px;    
      flex-wrap: wrap;
    }
  }
}
