@import '../../themeColors';
@import '../../components/globalFontMixins';
.pillTabButton{
    @include backgroundTransition;
    background-color: $bg-primary-3;
    cursor: pointer;
    border: none;
    border: 1px solid transparent;
    border-radius: 70px;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 10px 24px;
    >span{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        >p{
            @include globalInterFont;
            @include normalText;
            @include backgroundTransition;
        }
        >h6{
            @include globalInterFont;
            @include normalText($text-primary-2);
            @include backgroundTransition;
        }
    }
    &:hover{
        border-color: $stroke-secondary-1;
        background-color: $bg-primary-4;
        .qluSvgFill{
            fill: $text-primary-1;
        }
        .qluSvgStroke{
            stroke:  $text-primary-1;
        }
        >span{
            >h6{
                @include normalText();
            }
        }
    }
    &Selected{
        background-color:  $bg-highlight-secondary;
        >span{
            >p{
                @include normalText($text-highlight-primary);
            }
            >h6{
                @include normalText($text-highlight-primary);
            }
        }
        // path{
        //     stroke:$text-highlight-primary;
        // }
        .qluSvgFill{
            fill: $text-highlight-primary;
        }
        .qluSvgStroke{
            stroke: $text-highlight-primary;
        }
        &:hover{
            border-color: transparent;
            background-color: $bg-highlight-secondary;
            .qluSvgFill{
                fill: $text-highlight-primary;
            }
            .qluSvgStroke{
                stroke: $text-highlight-primary;
            }
            >span{
                >h6{
                    @include normalText($text-highlight-primary);
                }
            }
        }
    }  
}