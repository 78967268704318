@import "../../themeColors";
.landing-root {
  display: flex;
  .leftnav {
    position: sticky;
    left: 0;
    top: 0;
    width: 68px;
    margin-bottom: auto;
  }
}

.navitemactive {
  > * {
    stroke: #ff8d4e;
  }
}

.naviteminactive {
  > * {
    stroke: #6c6c6c;
  }
}

.navicon {
  text-align: center;
  padding-top: 48px;
  padding-bottom: 12px;
  background-color: $bg-primary-2;
  border-right: 1px solid $stroke-primary-3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  > img {
    width: 24px;
    cursor: pointer;
  }
}

.stepperOverride {
  padding-top: 120px;
}

.landingcontent {
  // width: 100%;
  padding-top: 32px;
  width: 100%;
  @media screen and (min-width: 1600px) {
    width: 105%;
  }
}

.campaignPrompt {
  font-size: 20px;
  color: $text-primary-1;
  text-align: center;
  padding: 24px 0;
}

.jobHeaderTabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  > .jobtabs {
    display: flex;
    justify-content: space-between;
    width: 316px;
    text-transform: uppercase;

    > p {
      cursor: pointer;
      padding-bottom: 6px;
      color: #e0e0e0;
    }

    > p.active {
      border-bottom: 2px solid #ff8d4e;
    }
  }
  > .searchcontainer {
    display: flex;
    // width: 564px;
    justify-content: flex-end;
    > input {
      background-color: $bg-primary-2;
      border-radius: 8px;
      border: none;
      outline: none;
      color: $text-primary-1;
      padding: 8px 12px;
      margin-left: 16px;
    }
  }
}
