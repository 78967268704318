@import "../../../components/globalCSSClasses";
@import "../../../components/globalFontMixins";


.skillDetailsJDModal {
  max-width: 70vw;

  >.modal-content {
    position: relative;
    background-color: $bg-primary-2;
    border-radius: 8px;
    border: 1px solid $stroke-primary-2;
  }

  .labelQluButton {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.criteriaSkillsContainer {
  height: 100%;
  padding: 48px 24px 32px 24px;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  gap: 42px;

  >div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;

    >span {
      display: flex;
      align-items: center;
      gap: 12px;

      >h2 {
        @include globalInterFont;
        @include heading3;
      }
    }
  }

  >.skillDetailsJDModalContainer {
    display: flex;
    justify-content: flex-end;

    >button {
      color: $text-primary-3;

      path {
        stroke: $text-primary-3;
      }

      &:hover {
        color: $text-primary-1;

        path {
          stroke: $text-primary-1;
        }
      }
    }
  }

  .criteriaSkillsBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;

    >span:first-child {
      margin-bottom: -24px;

      >h5 {
        @include globalRubikFont;
        @include normalText($text-primary-3);
      }

      >h5:nth-child(1) {
        width: 320px;
      }

      >h5:nth-child(2) {
        width: 261px;
      }
    }

    >span {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;

      >input {
        @extend .primaryInput;
      }

      >.skillDetailsExperienceContainer {
        display: flex;
        align-items: center;
        min-width: 520px;
        min-height: 55px;
      }

      >.skillImportanceContainer {
        top: -12px;
        position: relative;
        margin-top: 8px;

        >h3 {
          @include globalRubikFont;
          @include normalText($text-primary-2);
          top: -8px;
          position: relative;
        }
      }
    }

    >h3 {
      @include globalRubikFont;
      @include normalText($text-primary-2);
    }
  }

  >.criteriaSkillsFooter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    padding-bottom: 120px;

    >hr {
      width: 100%;
      background-color: $stroke-secondary-1;
    }

    >h3 {
      @include globalRubikFont;
      @include normalText($text-primary-2);
    }

    >span {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
    }
  }
}