@import '../../../themeColors';

.profileCardContainer{
    padding: 64px 16px 120px 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    >div>span{
        background-color: transparent;
        border: none;
        padding: 0;
        >span{
            padding: 0;
        }
    }
    .idealProfileSkills_Container{
        padding-bottom: 0;
    }
}