@import "../../../components/globalCSSClasses";
@import "../../../components/globalVariable";
@import "../../../components/globalFontMixins";

.newMeetingContainer {
    @include smallText($text-primary-1);

    .disabled {
        opacity: 0.3;
    }
    
    .mainHeading {
        @include subHeading1;
        font-size: 20px;
        padding: 26px 16px;
        border-bottom: 1px solid $bg-primary-2;
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 39px;
    }

    .iconButton {
        width: 24px;
        height: 24px;
        background-color: var(--bg-primary-4);
        border: 1px solid var(--bg-primary-4);
        border-radius: 8px;
        margin: auto 0;

        svg {
            width: 18px;
            height: 18px;
            margin-top: -14px;
            margin-left: -4px;
        }
    }

    .guestIconButton {
        background: transparent;
        border: none;

        svg {
            width: 16px;
            height: 16px;
            margin-top: -5px;
        }
    }

    .inputText input {
        @include smallText($text-primary-1);
        background-color: $bg-primary-4;
        border-radius: 8px;
        width: 300px;
        height: 42px;
        border: 0;
        padding: 7px 16px;

        .form-control {
            background-color: transparent;
            border: none;
        }

        input.form-control:focus {
            box-shadow: none;
        }

        :focus-visible {
            outline: 0;
        }
    }

    .guestInputText input {
        @include smallText($text-primary-1);
        background-color: $bg-primary-4;
        border-radius: 8px;
        width: 270px;
        height: 42px;
        border: 0;
        padding: 7px 16px;

        .form-control {
            background-color: transparent;
            border: none;
        }

        input.form-control:focus {
            box-shadow: none;
        }

        :focus-visible {
            outline: 0;
        }
    }

    .datePicker {
        input {
            @include smallText();
            width: 92px;
            height: 42px;
            border: none;
            box-sizing: border-box;
            border-radius: 4px;
            color: $text-primary-1;
            padding: 10px;
            background-color: $bg-primary-4;
        }
        margin-right: 12px;
    }

    .hyphen {
        margin: 8px;
        color: $text-primary-1;
    }

    .timeSettingsInput input.react-datepicker-ignore-onclickoutside,
    .timeSettingsInput .react-datepicker-wrapper input[type="text"] {
        @include smallText();
        width: 86px;
        height: 42px;
        border: none;
        box-sizing: border-box;
        border-radius: 4px;
        color: $text-primary-1;
        padding: 10px;
        background-color: $bg-primary-4;
    }

    .addNew {
        margin: 0 0 32px 0 !important;
        @include smallText();
        color: $text-highlight-secondary;
    }

    .you {
        @include smallText($text-primary-3);
        margin-left: 4px;
    }

    .subHeading {
        @include smallText($text-primary-2);
    }

    .profileDisplayImage {
        margin-right: 12px;
    }

    .heading {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 43px;
    }

    .flexAlignDisplay {
        display: flex;
        margin-bottom: 20px;
    }

    .flexDisplay {
        display: flex;
        align-items: center;
        margin: 0 0 16px 0;
    }

    .header {
        min-width: 60px;
    }

    .flexOnly {
        display: flex;
        align-items: center;
    }

    .locationButton {
        margin-top: 24px;
        margin-bottom: 16px;
        border-radius: 8px;
        width: 320px;
        color: #fff;
        background-color: #4285f4;
        text-align: center;
        cursor: pointer;
    }

    .gbt_icon {
        border-radius: 8px;
    }

    .addLocation {
        width: 352px;
        height: 264px;
        padding: 16px;
        position: absolute;
        background-color: $bg-primary-4;
        margin-top: -20px;
        border-radius: 8px;
    }

    .meetingChannelHeader {
        font-weight: 600;
        margin-bottom: 12px;
    }

    .meetingChannelDescription {
        margin-bottom: 24px;
    }

    .addLocationBtn {
        width: 143px;
        height: 42px;
        background-color: $bg-primary-4;
        border-radius: 8px;
        margin-right: 16px;
        padding: 9px 11px;

        img {
            margin-right: 12px;
        }
    }

    .cancelButton,
    .createButton {
        width: 64px;
        height: 36px;
        font-size: 12px;
        font-weight: 400;
        margin-left: 12px;
        padding: 11px;
    }

    .meetingLink {
        margin-left: 26px;
        color: $text-primary-2;
    }

    .meeting {
        width: 300px;
        height: 66px;
        border-radius: 8px;
        background-color: $bg-primary-4;
        padding-top: 11px;
        padding-left: 12px;
    }

    .meetingIcon {
        display: flex;
        justify-content: space-between;
    }

    .gbt_svg_cntr {
        img {
            margin-right: 8px;
        }
    }

    .cross {
        margin-right: 16px;
        width: 8px;
        height: 8px;
        background-color: transparent;
        border: none;
    }
}
