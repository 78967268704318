@import '../../../../themeColors';
@import '../../../../components/globalFontMixins';
.findMoreCompaniesCards{
    background: $bg-primary-3;
    border: 1px solid $stroke-primary-3;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    height: 602px;
    >span{
        padding: 28px;
        overflow-y: auto;
    }
    >span:first-of-type{
        width: 60%;
        >h1{
            @include globalRubikFont;
            @include normalText;
            color: $text-primary-2;
        }    
        >div{
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            margin-top: 32px;
            >div{
                display: flex;
                align-items: center;
                gap: 12px;
            }
        }
    }
    >span:last-of-type{
        width: calc(max(332px, 40%));
        border-left: 1px solid $stroke-primary-5;
        position: relative;
        .companyHoverLabel{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% - 56px);
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
            >.profileSummaryCard{
                width: unset
            }
            >h3{
                @include globalInterFont;
                @include normalText;
                color: $text-primary-3;
                text-align: center;
            }
        }
    }

}