@import "../../themeColors";
@import "../globalFontMixins";
@import "../globalCSSClasses";

.companyCard {
  position: relative;
  background-color: $bg-primary-5;
  border: 1px solid $stroke-primary-5;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 32px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .placeHolderContainer {
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $bg-primary-5;
  }

  .companyDescription {
    max-height: 200px;
    overflow-y: auto;
    color: $text-primary-1;
  }

  @supports (overflow-y: overlay) {
    .companyDescription {
      overflow-y: overlay;
    }
  }

  .companyCardHeader {
    display: flex;
    align-items: center;
    gap: 12px;

    h3 {
      @include globalInterFont;
      @include subHeading1;
    }

    >img {
      border-radius: 8px;
    }

    >span {
      display: flex;
      flex-direction: column;
      gap: 4px;

      >div {
        display: flex;
        align-items: center;
        gap: 8px;

        >p {
          @include globalInterFont;
          @include normalText($text-primary-2);
        }
      }
    }
  }

  >p {
    @include globalInterFont;
    @include normalText;
    color: $text-primary-1;
  }

  .companyCardBody {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    >.companyCardKeyValue {
      display: flex;

      span {
        background-color: $bg-highlight-secondary;
        color: $bg-highlight-primary;
      }

      width: 100%;

      >h3 {
        min-width: 25%;
      }

      >p {
        @include globalInterFont;
        @include normalText($text-primary-2);
      }
    }
  }

  .companyCardCloseBtn {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  h3 {
    @include globalInterFont;
    @include normalText;
  }
}