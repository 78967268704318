@import "../globalCSSClasses";
@import "../globalFontMixins";
@import "../globalVariable";

.allertModalContent {
  height: 99px;
  width: 514px;
  background-color: $text-primary-2;
  border-radius: 8px;
}
.allertModalBackdrop {
  opacity: 0 !important;
}
.allertModalClass {
  margin-top: 105px;
}
.allertModal {
  backdrop-filter: blur(3px);
}

.alertModalBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .alertModalBodayData {
    @include smallText($text-secondary-1);
  }
  .alertModalHeader {
    p {
      @include smallText($text-secondary-1);
      font-weight: 600;
    }
  }
  .alertModalFooterBtns {
    .alertModalSecondary {
      @include smallText($text-primary-1);
      @extend .secondaryButton;
      margin-right: 20px;
      height: 34px;
      align-items: center;
      width: 159px;
      height: 32px;
      padding: 6px 8px;
      background-color: $bg-primary-7;
      margin-bottom: 12px;
      &:focus {
        outline: none;
      }
    }
    .alertModalPrimary {
      @include smallText($text-primary-1);
      @extend .primaryButton;
      padding: 6px 8px;
      align-items: center !important;
      width: 159px;
      height: 20px;
      &:focus {
        outline: none;
      }
    }
  }
}
