@import "../../../../components/globalCSSClasses";
@import "../../../../components/globalVariable";
@import "../../../../components/globalFontMixins";

.messagingSettings {
    @include smallText($text-primary-1);
    width: 676px;
    margin-top: 76px;
    border-bottom: 1px solid var(--bg-primary-3);
    padding-bottom: 52px;

    .heading {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 43px;
    }

    .flexAlignDisplay {
        display: flex;
    }

    .flexDisplay {
        display: flex;
        align-items: center;
        margin: 0 0 16px 0;
    }

    .header {
        width: 280px;
        margin-top: 5px;
        margin-right: 48px;

        .subHeader {
            @include smallText($text-primary-3);
        }
    }

    .googleBtn {
        @include smallText();
        width: 104px;
        height: 42px;
        color: $text-primary-1;
        background-color: $bg-primary-4;
        padding: 11px 20px 11px 11px;
        text-transform: capitalize;
    }

    .email {
        background-color: $bg-primary-4;
        border-radius: 8px;
        padding: 11px 32px 11px 16px;
        width: 204px;
        height: 42px;
        margin-left: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .inputText {
        background-color: $bg-primary-4;
        border-radius: 8px;
        width: 204px;
        height: 42px;
        margin-left: 12px;

        .form-control {
            background-color: transparent;
            border: none;
        }

        input.form-control:focus {
            box-shadow: none;
        }
    }

    .calendarInputText input {
        @include smallText($text-primary-1);
        background-color: $bg-primary-4;
        border-radius: 8px;
        margin-left: 12px;
        margin-right: 16px;
        width: 204px;
        height: 42px;
        border: 0;
        padding: 7px 16px;

        .form-control {
            background-color: transparent;
            border: none;
        }

        input.form-control:focus {
            box-shadow: none;
        }

        :focus-visible {
            outline: 0;
        }
    }

    .DropdownContainer .DropdownToggle {
        width: 320px;
    }

    .crossIconButton {
        background: transparent;
        border: none;

        svg {
            width: 16px;
            height: 16px;
            margin-top: -5px;
        }
    }

    .link {
        @include smallText();
        color: $bg-highlight-primary;
        margin-left: 16px;
    }

    .addNew {
        margin: 0 0 32px 0 !important;
        @include smallText();
        color: $text-disabled;
    }

    .tribes {
        margin-top: 32px;
        padding-bottom: 52px;
        border-bottom: 1px solid $bg-primary-3;

        .dropdown button {
            width: 320px;
            height: 42px;
            font-size: 14px;
        }
    }

    .react-toggle {
        margin-right: 16px;
    }

    .react-toggle--checked .react-toggle-track,
    .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track,
    .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track,
    .react-toggle-track {
        background-color: $bg-highlight-secondary;
    }

    .react-toggle--checked .react-toggle-track-check,
    .react-toggle-track-x {
        display: none;
    }

    .react-toggle--checked .react-toggle-thumb,
    .react-toggle-thumb {
        border-color: $bg-highlight-primary;
        background-color: $bg-highlight-primary;
    }

    .react-toggle--focus .react-toggle-thumb,
    .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
        box-shadow: none !important;
    }
}
