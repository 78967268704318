@import '../../themeColors';

.bigRoundButton{
    background-color: $bg-primary-5;
    display: grid;
    place-content: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: none;
    &:hover{
        background-color: $bg-primary-6;
    }
}