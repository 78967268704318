@import '../../themeColors';
@import '../../components/globalFontMixins';
.tabButton{
    @include globalRubikFont;
    @include normalText($text-primary-2);
    @include backgroundTransition;
    border: none;
    border-bottom: 2px solid transparent;
    padding-bottom: 8px;
    &:hover{
        @include normalText;
        padding-bottom: 8px;
    }

    &:disabled {
        color: $text-primary-2;
        cursor: not-allowed;
    }

}

.tabButtonSelected{
    @include normalText;
    padding-bottom: 8px;
    border-bottom-color: $stroke-highlight;
    &:disabled {
        color: $text-primary-1;
    }
}   