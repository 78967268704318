.ChatInput {
    color-scheme: dark;
    display: flex;
    margin: 40px 32px;

    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #515151;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    overflow-y: auto;
    align-items: flex-end;

    &:focus,
    &:focus-visible,
    &:focus-within {
        outline: 1px solid #fff;
    }

    >textarea {
        flex: 1;
        color: #E0E0E0;
        padding: 14px 22px;
        max-width: calc(100% - 75px);
        white-space: pre-wrap;
        outline: none;
        border: none;
        resize: none;
        overflow-x: hidden;
        max-height: 5.8em;
        background: transparent;

        &:focus,
        &:focus-visible,
        &:focus-within {
            outline: none;
            border: none;
        }
    }

    >button {
        width: 30px;
        background: transparent;
        border: none;
        margin: 14px 22px;

    }
}