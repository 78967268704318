@import "../../themeColors";
@import "../../components/globalFontMixins";

.draftEditorContainer > div.DraftEditor-root {
  @include globalInterFont;
  @include normalText($backgroundColor: $bg-primary-4);
  height: 221px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $stroke-primary-4;
  box-sizing: border-box;
  padding: 16px;
  overflow-y: auto;
  line-height: 34px;
}

.draftEditorContainer > div.DraftEditor-root > div.DraftEditor-editorContainer,
.draftEditorContainer
  > div.DraftEditor-root
  > div.DraftEditor-editorContainer
  > div.public-DraftEditor-content {
  height: 100%;
}

.styledTags {
  @include globalInterFont;
  @include normalText($text-highlight-primary, $bg-highlight-secondary);
  @include backgroundTransition;
  padding: 4px 8px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  &:hover {
    border-color: $stroke-highlight;
  }
}

.styleableTextAreaContainer {
  margin: 0px;
  .DraftEditor-root {
    background-color: $bg-primary-5;
  }
}
.emailCharcterCounter {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #a7abb0;
  margin: 8px;
}
.emailCharcterCounterFlash {
  color: $text-negative-primary;
}
