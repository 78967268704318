@import "../../themeColors";
@import "../../components/globalFontMixins";
@import "../../components/globalFontMixins";
@import "../../components/globalCSSClasses";
@import "../../components/globalVariable";

.revampProspectFullCardVarient1 {
  width: 100%;
  min-height: 100vh;
  background-color: $bg-primary-3;
  display: block !important;
  color: $text-primary-1;
  transform: translateX(calc(100vw - 1180px));
  margin-top: 0 !important;
  border: 1px solid $stroke-primary-5;
  border-radius: 8px 0 0 0;
  position: relative;

  .prospectClose {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 24px;
    right: 24px;
    height: 24px;
    width: 24px;
    background-color: $bg-primary-5;
    border-radius: 8px;
    cursor: pointer;
  }

  .revampImageSection {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .revampFav {
    padding-block: 15px;
    padding-inline: 10px;
    position: absolute;
    right: 0;
  }

  .revampAction {
    padding-block: 20px;
    padding-inline: 10px;
    position: absolute;

    >p {
      @include normalText($text-primary-2);
      margin-bottom: 0.25em;
      text-align: center;
    }

    .form-control-lg {
      min-height: calc(0.6em + 0.6rem + 2px);
      padding: 0.7rem 0.7rem;
      font-size: 1.25rem;
      margin-top: -1px;
    }

    .form-check-input {
      background-color: #0003;
      border: 2px solid var(--stroke-secondary-1);

      &:checked {
        background-color: var(--text-highlight-primary);
      }
    }

    .form-check-input:checked[type="checkbox"] {
      background-color: var(--text-highlight-primary);
    }
  }

  .headStickyContainer {
    position: sticky;
    top: 0;
    z-index: 100;
  }

  .experience-info .summary span,
  .title_exp span {
    background-color: $bg-highlight-secondary;
    color: $bg-highlight-primary;
  }

  .revampIntroSection {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .profileImg {
      user-select: none;
    }

    .prospectCard-titleInfo {
      @include globalInterFont;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: nowrap;
      align-items: center;
      min-height: 115px;
      margin-top: -5px;

      .nameTitle {
        @include heading2;
        margin-bottom: 10px !important;

        .linkedInLogo {
          margin-left: 5px;
          margin-bottom: 3px;

          &:hover {
            fill: #0072b1;

            path {
              fill: #0072b1;
            }
          }
        }
      }

      .jobtooltip {
        position: relative;
        display: inline-block;
      }

      .tooltiptext {
        display: none;
        width: 20rem;
        background-color: $bg-primary-1;
        color: $text-primary-1;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
      }

      &:hover .tooltiptext {
        display: block;
      }

      .jobTitle {
        @include subHeading1($fontWeight: 400);
        margin-bottom: 5px !important;
        text-overflow: ellipsis;
        // min-height: 20px;
        // max-height: 20px;
        overflow: hidden;
      }

      .location {
        @include normalText($text-primary-2);
      }
    }
  }

  .prospectJobTitle {
    color: $text-primary-1;
  }

  .prospectLocation {
    color: $text-secondary;
  }

  .prospectJobCompany {
    font-family: Inter;
    color: var(--bg-highlight-primary);
    background-color: var(--bg-highlight-secondary);
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 146.02%;
    padding: 2px 4px;
    border-radius: 4px;
  }

  .experiencePanel,
  .relevantExperiencePanelSummary,
  .totalExperiencePanel,
  .specialitiesPanel,
  .otherSkillsPanel,
  .educationPanel,
  .VisaDemoPanel {
    padding: 24px 25px;
    border-top: $stroke-primary-5 1px solid;
    border-bottom: $stroke-primary-5 1px solid;

    .missing {
      color: $primary-red;
    }
  }

  .columnHeading {
    @include globalInterFont;
    color: $text-primary-1;
    font-weight: 600;
    margin-bottom: 16px;

    span {
      color: $text-secondary;
    }
  }

  .headSection {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 24px 14px;

    border-radius: 8px 0px 0 0;

    background-color: $bg-primary-3;

    .left {
      display: flex;
      gap: 11px;
      align-items: center;

      .prospectAvatar {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
      }

      .prospectName {
        font-weight: bold;
        // color: #000;
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 20px;

      .prospectTabBar {
        display: flex;
        gap: 1.25rem;

        .tab {
          @include normalText($fontColor: $primary-grey);
          @include globalRubikFont;
          display: flex;
          gap: 32px;
          margin-top: 14px;
          margin-bottom: 14px;
          cursor: pointer;

          &.commentsDisabled {
            cursor: no-drop;
            color: $text-primary-3;
          }

          &.active {
            // @include normalText();
            color: $text-primary;

            text-decoration: underline $stroke-highlight 2px;
            text-underline-offset: 5px;
          }
        }
      }

    }
  }

  .EmojiToolTip {
    display: flex;
    position: absolute;
    margin-top: 0px;
    // justify-content: center;
    z-index: 9999;

    span {
      background: black;
      padding: 2px 3px;
      border-radius: 6px;
      color: $text-primary-1;
    }
  }

  .emojiMenuContainer {
    position: absolute;
    top: 40vh;
  }

  .emojiContainer {
    display: flex;
    max-width: 170px;
    gap: 5px;
    padding-bottom: 5px;
    overflow-x: auto;

    button {
      display: flex;
      background: $bg-primary-6;
      min-width: 32px;
      height: 32px;
      padding: 2px 3px;
      align-items: center;
      border-radius: 37px;
    }
  }

  .btnP {
    display: flex;
    align-items: center;
    background: $bg-highlight-secondary;
    color: $bg-highlight-primary;
    font-weight: bold;
    // width: 32px;
    border-radius: 4px;
    height: 32px;
    // padding: 2px 3px;
  }

  .collab {
    margin-top: 30px;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;

    .commentIcon {
      * {
        stroke: var(--bg-highlight-primary);
      }

      color: $text-primary-1;
    }

    .toggleEmoji {
      * {
        stroke: var(--bg-highlight-primary);
      }
    }

    .emojiOwner {

      // button {
      //   // background: $bg-highlight-secondary !important;
      //   // color: $text-highlight-primary;
      //   font-weight: bold;
      // }
      .count {
        font-weight: bold;
      }

      .emoji {
        font-size: 1.25rem;
      }
    }

    .emojies {
      position: relative;

      .tooltiptext {
        display: none;
        background-color: $bg-primary-1;
        color: $text-primary-1;
        text-align: center;
        padding: 5px 15px;
        width: 150px;
        border-radius: 6px;
        position: absolute;
        // top: -50px;
        // left: 50px;
        z-index: 500 !important;

        span {
          display: block;
        }
      }

      &:hover .tooltiptext {
        display: block;
      }
    }

    button {
      color: $text-primary-1;

      span.count {
        padding: 1px 0;
      }

      .emoji {
        font-size: 17px;
      }
    }
  }

  .revampIntro {
    display: flex;
    flex-direction: column;
    border-top: $stroke-primary-5 1px solid;
    border-bottom: $stroke-primary-5 1px solid;
    background-color: $bg-primary-3;

    .revampActivities {
      display: flex;
      justify-content: space-between;
      padding-block: 10px;
      @include globalInterFont;
      color: $text-secondary;

      .activityTitle {
        color: $text-primary;

        span {
          color: $text-secondary;
        }
      }

      .activityView {
        color: $text-secondary;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: $text-primary;
        }
      }
    }

    .revampSideCardContainer {
      width: 30rem;
      position: relative;
    }

    .revampSkillbarContainer {
      position: sticky;
      top: 1px;
    }

    .revampSkillBars {
      // border-right: $stroke-primary-5 1px solid;
      padding: 15px;
      margin-top: 5px;

      transition: all 0.3s ease;

      // display: flex;
      // flex-direction: row;
      .experienceSubScores {
        margin-left: 0.5rem;
        margin-bottom: 0.5rem;

        p:first-child {
          font-weight: 400;
        }
      }

      .revampSkillBorder {
        height: 2px;
        border-bottom: $stroke-primary-5 1px solid;
        width: calc(100% + 30px);
        margin-left: -15px;
      }

      p {
        @include normalText;
        margin-top: 10px;
        width: 100px;
        margin-bottom: 0px;
      }

      &>.row>div>.totalScore {
        font-weight: 600;
      }

      .barFull {
        margin-top: 1.7em;
        // margin-right: 1em;
      }

      .barFull {
        &.progress {
          background-color: $bg-primary-6;

          .progress-bar {
            background-color: $text-highlight-primary !important;
          }
        }
      }

      .barFull.progress {
        height: 4px !important;
        margin-left: 10px;
      }

      .activitiesExpanded {
        margin-left: -2px;
        transition: all;
      }

      .activitiesSection {
        display: flex;
        background: $bg-primary-4;
        border-radius: 8px;
        padding: 10px;
        padding-bottom: 20px;
        overflow-y: auto;
        max-height: 100px;
        flex-direction: column;

        >p {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex: 1;
          width: auto;

          .activityBy {
            font-weight: bold;
            text-transform: capitalize;
          }

          .time {
            color: $text-primary-2;
          }
        }
      }
    }

    .revampMainContainer {
      width: 100%;
      border-left: var(--stroke-primary-5) 1px solid;
    }

    .revampAbout {
      // max-width: 60%;
      padding: 25px;
      margin-top: -25px;
      margin-left: 5px;

      .summary {
        @include normalText;
        margin-top: 10px;
        white-space: pre-line;
        text-align: left;
        margin-bottom: 0px;
      }
    }
  }

  .revampMain {
    .descriptionSkill {
      transition: all 0.1s ease-in-out;
      text-transform: capitalize;
    }

    .tablets {
      p {
        background-color: var(--bg-primary-6);
        padding: 1px 4px 1px 4px;
        border-radius: 6px;
        margin-left: -0.2rem;
        transition: 0.1s all ease-in-out;
      }
    }

    .experiencePanel {
      padding: 24px 25px;

      .skillHighLight {
        // font-family: Inter;
        color: var(--bg-highlight-primary);
        background-color: var(--bg-highlight-secondary);
        margin: 0;
        padding: 0;
        // font-weight: normal;
        // font-size: 16px;
        // padding-left: 5px;
        // line-height: 146.02%;

        border-radius: 4px;
      }

      .experienceList {
        display: flex;
        flex-direction: column;

        .single-experience {
          display: flex;
          margin-top: 1.25rem;
          min-height: 43px;

          .experience-date {
            >p {
              width: 150px;
            }

            .duration {
              @include subHeading1;
              margin-left: 1.5rem;
              margin-top: 0.95rem;
              margin-bottom: 0.95rem;
            }

            .experience-span {
              font-weight: 600;
            }
          }

          .experience-info {
            margin-left: 1.3rem;
            border-bottom: 1px solid $bg-primary-5;
            width: 100%;

            &.noBorder {
              border-bottom: 0px solid $bg-primary-5 !important;
            }

            .title_company {
              span {
                @include globalInterFont;
                @include normalText(#4e95ff);
                padding: 2px 4px;
                border-radius: 4px;

                // &:hover {
                //   color: $text-primary;
                // }
              }
            }

            .summary {
              // width: 60rem;
              margin-top: 0.5rem;
              white-space: pre-line;
              text-align: left;
            }

            .title {
              min-height: 10px;
            }

            .title .years {
              color: $text-primary-2;
            }

            .duration {
              font-weight: 300;
              color: $text-primary-2;
            }

            .title_exp {
              margin-top: 0.95rem;
              font-weight: 500;
            }

            .title_location {
              margin-top: 0.5rem;
              font-weight: 300;
              color: $text-primary-2;
            }
          }

          .experience-skills,
          .experience-specialities {
            display: flex;

            gap: 0.5rem;
            padding-top: 0.8rem;
            min-height: 30px;

            .tablets {
              position: relative;

              p+span {
                display: none;
              }

              p {
                position: relative;

                span {
                  position: absolute;
                }
              }

              p:hover {
                cursor: pointer;
                // font-family: Inter;
                color: var(--bg-highlight-primary);
                background-color: var(--bg-highlight-secondary);
                // margin: 0;
                // padding: 0;
                font-weight: normal;
                // font-size: 16px;
                // padding-left: 5px;
                // line-height: 146.02%;
                // padding-inline: 5px;
                border-radius: 4px;

                &+span {
                  background: $bg-highlight-secondary;
                  border-radius: 8px;
                  padding: 2px 5px;
                  display: inline-block;
                  z-index: 1000;
                  position: absolute;
                  top: -38px;
                  left: 100px;
                  width: 160px;
                }
              }
            }

            margin-bottom: 0.5rem;

            >p {
              color: $text-primary-2;
              margin-right: 1rem;
            }

            div {
              display: flex;
              flex-wrap: wrap;
              gap: 1rem;
            }
          }

          .experience-specialities {
            display: flex;
            gap: 0.5rem;

            margin-bottom: 0.5rem;

            >p {
              color: $text-primary-2;
              margin-right: 1rem;
            }

            // div {
            //   display: flex;
            // }
          }
        }
      }
    }

    .relevantExperiencePanelSummary {
      .relevantExperienceSummary {
        .relevant-summary {
          display: flex;
          min-height: 30px;

          >p:first-child {
            width: 30%;
          }

          .title {
            width: 70%;
            font-size: 16px;

            span {
              color: #4e95ff;
              // background-color: var(--bg-highlight-secondary);
              margin: 0;
              padding: 0;
              font-weight: normal;
              font-size: 16px;
              line-height: 146.02%;
              padding: 2px 4px;
              border-radius: 4px;
            }

            &:last-child {
              padding-bottom: 1.5rem;
            }

            &.noneFound {
              margin-top: 0 !important;
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }

    .totalExperiencePanel {
      display: flex;

      .totalExperience {
        flex-basis: 50%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .columnHeading {
          margin-bottom: 0px;
        }

        .yearSpans {
          // margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }

      .functionalExperience {
        flex-basis: 50%;

        .functionalExperienceContainer {
          margin-top: 0.5rem;
        }
      }
    }

    .specialitiesPanel {
      .specialities {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 0.5rem 2rem;

        div {
          display: flex;
          flex-wrap: wrap;
          margin-top: 0.5rem;
        }

        .subHeading {
          p {
            @include subHeading1;
          }

          @include subHeading1;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }

      .missing {
        span {
          color: $text_negative_primary;
        }

        .tablets {
          span {
            background-color: var(--bg-negative-secondary);
          }
        }
      }

      .tablets {
        span {
          background-color: var(--bg-primary-6);
          padding: 1px 4px 1px 4px;
          border-radius: 6px;
          margin-left: -0.2rem;
        }
      }

      span {
        font-weight: normal;
      }
    }

    .otherSkillsPanel {
      .caretDown {
        transition: all 0.3s ease;
        transform: rotate(0deg);

        &.active {
          transform: rotate(180deg);
        }
      }

      .otherSkills {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 0.5rem 2rem;

        div {
          display: flex;
          flex-wrap: wrap;
          margin-top: 0.5rem;
        }

        .subHeading {
          p {
            @include subHeading1;
          }

          @include subHeading1;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }

        .tablets {
          span {
            background-color: var(--bg-primary-6);
            padding: 1px 4px 1px 4px;
            border-radius: 6px;
            margin-left: -0.2rem;
          }
        }

        span {
          font-weight: normal;
        }
      }
    }

    .skillsPanel {
      &>.columnHeading {
        padding: 24px 25px 0px;
      }

      .missing {
        color: $primary-red;
      }

      .tablets {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.5rem 2rem;
        text-transform: capitalize;
        margin-left: 1rem;
        align-items: flex-start;
        @include globalInterFont;

        p {
          background-color: transparent;

          :not(.duration) {
            background-color: var(--bg-primary-6);
            padding: 1px 4px 1px 4px;
            border-radius: 6px;
            margin-left: -0.2rem;
          }

          &.missing>span:not(.duration) {
            background-color: $bg-negative-secondary;
          }

          .duration {
            color: $text-secondary;
          }

          .globalDotStyle {
            background-color: transparent;
            padding: 0px;
          }
        }
      }

      .highSkill,
      .mediumSkill,
      .lowSkill {
        border-bottom: $stroke-primary-5 1px solid;
        padding: 12px 12px;

        .columnHeading {
          font-size: 14px;

          span {
            font-weight: normal;
            color: $text-secondary;
          }

          span.missing>span {
            color: $text_negative_primary;
          }
        }

        .revampCollapse {
          margin-bottom: -0.5rem;
        }
      }

      .highSkill {}

      .mediumSkill {}

      .lowSkill {}
    }

    .educationPanel {
      .education {
        display: flex;
        flex-direction: column;

        .single-education {
          display: flex;
          margin-top: 1.25rem;
          min-height: 43px;

          .education-date {
            >p {
              width: 150px;
            }

            .duration {
              @include subHeading1;

              margin-top: 0.95rem;
              margin-bottom: 0.95rem;
            }

            .education-span {
              font-weight: 600;
            }
          }

          .title_education {
            span {
              @include globalInterFont;
              @include normalText($bg-highlight-primary,
                $bg-highlight-secondary );
              padding: 2px 4px;
              border-radius: 4px;

              &:hover {
                color: $text-primary;
              }
            }
          }

          .education-info {
            margin-left: 1.3rem;
            border-bottom: 1px solid $bg-primary-5;
            width: 100%;

            &.noBorder {
              border-bottom: 0px solid $bg-primary-5 !important;
            }

            .summary {
              // width: 60rem;
              margin-top: 0.5rem;
              white-space: pre-line;
              text-align: left;

              span {
                color: red;
              }
            }

            .title {
              min-height: 30px;
            }

            .title .years {
              color: $text-primary-2;
            }

            .duration {
              font-weight: 300;
              color: $text-primary-2;
            }

            .title_edu {
              margin-top: 0.95rem;
              font-weight: 500;
            }

            .title_location {
              margin-top: 0.5rem;
              font-weight: 300;
              color: $text-primary-2;
            }
          }

          .experience-skills {
            display: flex;

            gap: 0.5rem;
            padding-top: 0.8rem;
            min-height: 30px;

            .tablets {
              p:hover {
                // font-family: Inter;
                color: var(--bg-highlight-primary);
                background-color: var(--bg-highlight-secondary);
                // margin: 0;
                // padding: 0;
                font-weight: normal;
                // font-size: 16px;
                // padding-left: 5px;
                // line-height: 146.02%;
                // padding-inline: 5px;
                border-radius: 4px;
              }
            }

            margin-bottom: 0.5rem;

            >p {
              color: $text-primary-2;
              margin-right: 1rem;
            }

            div {
              display: flex;
              flex-wrap: wrap;
              gap: 0.5rem;
            }
          }

          .experience-specialities {
            display: flex;
            gap: 0.5rem;

            margin-bottom: 0.5rem;

            >p {
              color: $text-primary-2;
              margin-right: 1rem;
            }

            // div {
            //   display: flex;
            // }
          }
        }
      }
    }

    .educationPanel .education>p>span.duration {
      color: $text-primary-2;
    }

    .VisaDemoPanel {
      display: flex;

      .visaPanel {
        flex-basis: 50%;
      }

      .demographicsPanel {
        flex-basis: 50%;
      }
    }
  }

  .placeHolderContainer {
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $bg-primary-5;
  }
}

.revampProspectFullCardVarient1 .revampMain .experiencePanel .experienceList .single-experience .experience-skills .tablets p:hover+span,
.revampProspectFullCardVarient1 .revampMain .experiencePanel .experienceList .single-experience .experience-specialities .tablets p:hover+span {
  // position: absolute;
  top: 30px;
  left: 0px;
  // bottom: 0px;
}