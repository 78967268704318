@import "../../themeColors";
@import '../globalFontMixins';

.closeButton {
  @include backgroundTransition;
  background-color: $bg-primary-7;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  display: grid;
  place-content: center;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  &:hover,
  &:focus,
  &:active {
    background-color: $stroke-primary-7;
  }
  &:disabled{
    opacity: .7;
    background-color: $bg-primary-6;
    outline: none;
    box-shadow: none;
    border: none;
    cursor: not-allowed;
  }
}
