@import "../globalVariable";
@import "../globalFontMixins";
@import "../globalCSSClasses";
@import '../../themeColors';

.textEditorWrapper {
  &>h1 {
    @include globalInterFont;
    @include normalText($text-primary-2);
    margin: 0 0 32px 0
  }

  &>p {
    @include globalInterFont;
    @include normalText($text-primary-3);
    margin-bottom: 12px
  }

  // width: 664px;
  display: flex;
  flex-direction: column;
  position: relative;

  >.pasteButtonContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;

    .paste-btn {
      color: #E0E0E0;
      background: #2C2C2C;
      padding: 4px 10px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 146.02%;
    }

    .generate-btn-wrapper {
      color: #E0E0E0;
      border: 1px solid #383838;
      border-radius: 8px;
      padding: 25px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 14px;

      &::before {
        content: " OR ";
        background-color: #1D1D1D;
        color: #6C6C6C;
        position: absolute;
        padding-inline: 1ch;
        top: -9px;
        left: calc(50% - 2ch);
      }

      >div {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 146.02%;
      }

      .generate-btn {
        color: inherit;
        background: #2C2C2C;
        padding: 4px 10px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 146.02%;
      }
    }
  }

  .textEditorContainer {
    background-color: #1D1D1D;
    color: $text-primary-1;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-color: $stroke-primary-4;
    padding: 24px 16px 33px 16px;

    >div {
      height: 50vh;
      overflow: auto;
    }

  }
}