@import "../../../components/globalCSSClasses";
@import "../../../components/globalVariable";
@import "../../../components/globalFontMixins";

.commentsContainer {
    .prospectInfo {
        display: none !important;
    }

    .single-comment {
        .message {
            @include smallText($fontColor: $text-primary);
        }
    }

    .commentsList {
        align-items: unset !important;
        padding-left: 8px;
    }

    .inputBox .inputInput {
        width: 431px !important;
        height: 80px;
        margin-left: 2px;
    }
}
