@import '../../components/globalCSSClasses';
@import '../../components/globalFontMixins';
@import '../../themeColors';
.hybridMessaging{
    display: flex;
    gap: 32px;
    & > div{
        width: 64%;
    }
    & > div:last-child{
        width: 36%;
    }
    .outboxMessagingSubjectContainer{
        background: $bg-primary-4;
        border-top: 1px solid $stroke-primary-4; 
        border-right: 1px solid $stroke-primary-4;
        border-left: 1px solid $stroke-primary-4;
        padding: 16px;
        border-radius: 8px 8px 0 0;
        display: flex;
        flex-direction: column;
        gap: 12px;
        >div{
            display: flex;
            align-items: center;
            gap: 16px;
            >h3{
                @include globalRubikFont;
                @include normalText($text-primary-3);
                width: 71.5px;
            }
            >p{
                @include globalInterFont;
                @include normalText($text-primary-2);
            }
            >input{
                @include globalInterFont;
                @include normalText;
                max-width: 100%;
                width: 100%;
                border: none;
                outline: none;
            }
        }
    }
    .outboxMessagingSubjectExtension{
        .DraftEditor-root{
            border-radius: 0 0 8px 8px;
        }
    }
}
.hybridMessagingHeadlineContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    h3{
        @include globalInterFont;
        @include normalText($text-primary-3);
        text-transform: uppercase;
    }
}
.hybridMessagingCtaContainer{
    display: flex;
    gap: 8px;
    button{
        padding: 4px 6px;
        border: none;
        background: $bg-primary-5;
        border-radius: 8px;
        &:hover{
            background: $bg-primary-6;
        }
    }
}
.previewModalContainer{
    > div{
        background: $bg-primary-3;
        padding: 24px 16px;
        width: 50vw;
        transform: translateX(-25%);
        >p{
            @include globalInterFont;
            @include normalText;
        }
    }
    >div >div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 24px;
        p{
            @include globalInterFont;
            @include subHeading1;
        }
        button{
            border: none;
            background: $bg-primary-5;
            border-radius: 8px;
            padding: 8px;
            &:hover{
                background: $bg-primary-6;
            }
        }
    }
}