@import "../globalFontMixins";
@import "../globalVariable";
@import "../globalCSSClasses";
@import "../../themeColors";

@keyframes slideInComments {
  from {
    opacity: 0;
    transform: translate3d(200rem, 100rem, 500rem);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

aside.emoji-picker-react {
  left: 53px;
  box-shadow: none;
  // position: absolute;
  margin-top: -30%;
  z-index: 750;
}

.prospectComments {
  // position: sticky;
  // top: 75px;

  :active,
  :focus {
    box-shadow: none !important;
    outline: none !important;
  }

  @include globalInterFont;
  color: $text-primary;
  transition: all 0.5s ease-in 0.5s;

  &.show {
    opacity: 1 !important;
    min-height: 332px !important;
  }

  &.hide {
    transition: revert;
    opacity: 0 !important;
    min-height: 0px !important;
  }

  .prospectInfo {
    @include normalText($fontColor: $text-primary);
    padding: 24px 20px 24px 20px;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    .prospectTitle {
      padding-left: 12px;
      width: 330px;
      p:last-child {
        @include smallText($fontColor: $text-primary);
      }
    }

    border-bottom: 1px solid $primary-border-grey;

    button {
      background: none;
      border: none;
      align-self: flex-start;
      margin-right: -15px;
      margin-top: -20px;
    }
  }

  .commentsList {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    // height: calc(100vh - 225px);
    min-width: 450px;
    overflow-y: auto;
    padding-bottom: 34px;
    border-bottom: 1px solid $primary-border-grey;
    overflow-x: hidden;
    padding-right: 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    .loader {
      color: $bg-primary-7;
    }

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    .single-comment {
      @include normalText($fontColor: $text-primary);
      padding-top: 26px;
      animation: slideInComments 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

      .dropdown-menu {
        background-color: $bg-primary-5 !important;
        button {
          padding: 5px !important;
          color: $text-primary-1 !important;
          &:hover {
            background-color: $bg-highlight-secondary !important;
            color: $text-highlight-primary !important;
          }
        }
      }

      .dropdown-toggle {
        margin-right: 0.7rem;
        background-color: $bg-primary-5 !important;
        color: $text-primary-1;
        border: 0px;
        border-radius: 8px;
        padding: 0.49rem 0.85rem;
        &:hover,
        &:active,
        &:focus {
          @include backgroundTransition;
          background-color: $border-grey;
          border: none;
          box-shadow: none !important;
          outline: none;
        }
      }
      .header {
        display: flex;
        justify-content: space-between;
        .commentSender {
          display: flex;
          gap: 8px;

          .senderImage {
            @include subText($padding: 6px 4px);
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-bottom: 8px;
          }
          .senderName {
            @include smallText();
            margin-top: 2px;
          }
        }

        .time {
          @include smallText($fontColor: $text-disabled);
          display: flex;
          .more {
            button {
              background: none;
              padding: 0;
              border: none;
              margin-top: -5px;
              margin-right: -6px;
            }
          }
        }
      }

      .messageContainer {
        display: flex;
        align-items: flex-start;
        .message {
          width: 500px;
          min-height: 76px;
          border-radius: 10px;
          background-color: $primary-bg-7;
          padding: 12px;

          strong {
            display: inline-block;
          }

          .reactions {
            margin-top: 12px;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            .emoji {
              display: flex;
              align-items: center;
              width: 40px;
              height: 32px;
              background-color: $bg-primary-6;
              border-radius: 39px;
              padding: 3px;
              cursor: pointer;
              .count {
                padding-left: 1px;
                padding-top: 2px;
                color: $text-primary-1;
              }

              &.emojiOwner {
                // background: $bg-highlight-secondary !important;

                .count {
                  font-weight: bold;
                  // color: $text-highlight-primary;
                }
              }

              .tooltiptext {
                display: none;
                background-color: $bg-primary-1;
                color: $text-primary-1;
                text-align: center;
                padding: 5px 15px;
                width: 150px;
                border-radius: 6px;
                position: absolute;
                top: 70px;
                left: 50px;
                z-index: 500 !important;

                span {
                  display: block;
                }
              }
              &:hover .tooltiptext {
                display: block;
              }
            }
          }

          .replyBox {
            background: $primary-bg-5;
            padding: 12px;
            border-radius: 10px;
            color: $text-secondary;
            margin-bottom: 24px;
          }
        }

        &:hover .actions {
          margin-left: 0px;
          opacity: 1;
        }

        .actions {
          padding-left: 12px;
          display: flex;
          margin-left: -45px;
          opacity: 0;
          flex-direction: column;
          transition: all 0.5s ease-in-out;

          button {
            width: 32px;
            height: 32px;
            padding: 0 5px;
            margin-bottom: 12px;
            background-color: $primary-bg-7;
            border-radius: 8px;
            border: none;

            &:hover {
              background: $primary-bg-6 !important;
              * {
                stroke: $text-primary;
              }
            }
          }

          button:last-child {
            padding-left: 7px;
          }
        }
      }
    }
  }

  .inputBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 50px;
    .inputInput {
      background: $primary-bg-6;
      border-radius: 10px;
      width: 90% !important;
      height: 20px;
      margin-left: 20px;
      margin-top: 20px;
      border: none;
      padding-bottom: 45px;
      color: $text-primary;

      .inputInput__input {
        border: none;
        color: $text-primary;
        padding: 10px;
      }

      .inputInput__suggestions {
        background-color: $primary-bg-5 !important;
        border-radius: 10px;
        ul li {
          padding: 8px;
          &:hover {
            background-color: $bg-highlight-secondary;
            color: $text-highlight-primary;
          }
        }
      }
    }

    .messageSend {
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      background: $primary-icon-4;
      border: none;
      margin-top: 20px;
      margin-left: 16px;

      &:hover:not(.disabled) {
        background: $bg-highlight-secondary !important;
        * {
          stroke: $text-primary;
        }
      }

      &.disabled {
        cursor: no-drop;
      }
    }
  }

  .replyInputBox {
    background: $primary-bg-5;
    border-radius: 10px;
    width: 332px !important;
    margin-top: 20px;
    padding: 5px 10px;
    color: $text-primary;
    border-left: 5px solid $bg-highlight-primary;

    .replyUser {
      color: $text-highlight-primary;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        margin-right: -5px;
        margin-top: -10px;
        Button {
          background: none;
          border: none;
          padding: 0;
        }
      }
    }
    .replyMessage {
      color: $text-primary-2;
    }
  }
}
