.useravt {
    width: 47px;
    height: 47px;
    margin-left: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2C2C2C;
    // position: absolute;
    // bottom: 12px;
    // left: 25%;
    cursor: pointer;
    // position: relative;
    &:hover{
        filter: brightness(2);
    }
}
