@import "../../themeColors";
@import "../../components/globalFontMixins";
@import "../../components/globalFontMixins";
@import "../../components/globalCSSClasses";
@import "../../components/globalVariable";
.video-circle {
  border-radius: 5%;
  object-fit: cover;
  height: 291px;
  width: 349px;

  video {
    border-radius: 5%;
    object-fit: cover;
  }
}
.prospectAvatar{
  border-radius: 10px;
}
.overlay {
  position: relative;
  margin-top: -162px;
  /* left: 50px; */
  height: 162px;
  width: 349px;
  background: linear-gradient(
    180.91deg,
    rgba(18, 18, 18, 0) 30.55%,
    #121212 99.22%
  );
  border-radius: 0px 0px 8px 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .MainHeading {
    @include globalInterFont;
    font-size: 24px;
    font-weight: 600;
    color: $text-primary;
    text-align: center;
    display: flex;
    .fullName {
      max-width: 285px;
      max-height: 30px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    .linkedInLogo {
      margin-left: 5px;
      margin-bottom: 3px;
      * {
        fill: $text-secondary;
      }
      &:hover {
        fill: #0072b1;
        path {
          fill: #0072b1;
        }
      }
    }
  }
  .jobTitle {
    @include subHeading1($fontWeight: 400);
    margin-bottom: 5px !important;
    text-overflow: ellipsis;
    max-height: 45px;
    max-width: 285px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
