@import "../globalVariable";

@import "../globalFontMixins";



.inputRangeSpacing{
    margin: 64px 0px;
    padding: 0px 32px;   
    width: 100%;
    
    .input-range{

        .input-range__label{

            .input-range__label-container{
                
                @include globalInterFont;
                @include smallText(white, $primary-black);
                padding: 2px 4px;
                border-radius: 4px;
            }
        }

        > .input-range__label--min > .input-range__label-container {
            @include globalInterFont;
            @include smallText($primary-grey);
        }

        > .input-range__label--max > .input-range__label-container {
            @include globalInterFont;
            @include smallText($primary-grey);
        }
        
        .input-range__track{

            .input-range__track--active{
                background-color: $primary-blue;
            }

            .input-range__slider-container{

                .input-range__slider{
                    
                    background: #FFFFFF;
                    border: 1px solid #CDD1D7;
                    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
                }

            }

        }
    }
}

