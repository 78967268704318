@import "../globalCSSClasses";
@import "../globalFontMixins";
@import "../globalVariable";

.CriteriaVerificationCard {
  display: flex;
  border-radius: 8px;
  border: 1px solid #e6e9ed;
  position: relative;
  padding: 24px 16px;

  h2 {
    @include globalRubikFont;
    @include normalText($primary-grey);
    margin-bottom: 12px;
  }
  .CriteriaVerificationcardFirstDiv {
      width: 50%;
      .criteriaVerificationPillWithImportnaceConatiner{
          > div{
              display: flex;
              align-items: center;
              justify-content: flex-start;
              max-width: 90%;
          }
      }
    .CriteriaVerificationRangeCard {
        margin-top: 32px;
      .inputAndHeadingCard {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 8px;
          .rangeHeading{
              margin: 0;
          }
      }
      .inputRangeSpacing {
        width: 50%;
        padding: 0px;
        margin: 32px 0;
      }
    }
  }
//   .CriteriaVerificationimportanceDiv {
//   }
  .CriteriaVerificationcardFourthDiv {
    position: absolute;
    right: 16px;
    top: 16px;
  }
}
