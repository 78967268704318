.DemoChatFlow {

    width: 100%;
    // position: absolute;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: linear-gradient(248.66deg, #38183A 0%, #000000 49.48%, #241E43 100%);
  }
  
  @media screen and (max-width: 1000px) {
    .textField {
      width: 60% !important;
    }
    .chatLogs{
      width: 64% !important;
      padding: 0 1rem !important;
    }
    .chatLogs{
      padding-right: 0.6rem;
    }
  }

  @media screen and (max-width: 600px) {
    .textField {
      width: 85% !important;
      padding: 4px !important;
    }
    .chatLogs{
      width: 89% !important; 
      >.chatrow{
        font-size: 14px;
          >.imgPlaceholder{
            width: 27px !important;
            height: 27px !important;
          }
      }
    }
    .qluLogo{
      width: 150px !important;
    }
    .chatLogs{
      padding-right: 0.3rem;
    }

    .Footer{
      padding: 0.5rem 0 1rem !important;
    }
  }

  @media screen and (max-width: 300px) {
    .chatLogs{
        width: 94% !important; 
        >.chatrow{
          font-size: 12px;
            >.imgPlaceholder{
              width: 20px !important;
              height: 20px !important;
            }
        }
      }
  }