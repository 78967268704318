@import "../../../../components/globalCSSClasses";
@import "../../../../components/globalVariable";
@import "../../../../components/globalFontMixins";

.accountSettings {
    @include smallText($text-primary-1);
    margin-top: 52px;
    width: 676px;

    .heading {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 43px;
    }

    .flexAlignDisplay {
        display: flex;
    }

    .flexDisplay {
        display: flex;
        align-items: center;
        margin: 0 0 16px 0;
    }

    .header {
        width: 280px;
        margin-top: 5px;
        // margin-right: 48px;
        margin-right: 8px;
    }

    .googleBtn {
        @include smallText();
        width: 104px;
        height: 42px;
        color: $text-primary-1;
        background-color: $bg-primary-4;
        padding: 11px 20px 11px 11px;
        text-transform: capitalize;
    }

    .email {
        background-color: $bg-primary-4;
        border-radius: 8px;
        padding: 11px 32px 11px 16px;
        height: 42px;
        margin-left: 12px;
    }

    .inputText {
        background-color: $bg-primary-4;
        border-radius: 8px;
        width: 204px;
        height: 42px;
        margin-left: 12px;

        .form-control {
            background-color: transparent;
            border: none;
        }

        input.form-control:focus {
            box-shadow: none;
        }
    }

    .calendarInputText input {
        @include smallText($text-primary-1);
        background-color: $bg-primary-4;
        border-radius: 8px;
        margin-right: 16px;
        // width: 320px;
        width: 190px;
        height: 42px;
        border: 0;
        padding: 7px 16px;

        .form-control {
            background-color: transparent;
            border: none;
        }

        input.form-control:focus {
            box-shadow: none;
        }

        :focus-visible {
            outline: 0;
        }
    }

    .crossIconButton {
        background: transparent;
        border: none;

        svg {
            width: 16px;
            height: 16px;
            margin-top: -5px;
        }
    }

    .link {
        @include smallText();
        color: $bg-highlight-primary;
        margin-left: 16px;
        // margin-top: 5px;
    }

    .addNew {
        margin: 0 0 32px 0 !important;
        @include smallText();
        color: $text-disabled;
    }

    .timezone {
        margin-top: 32px;
        margin-bottom: 43px;

        .dropdown button {
            width: 320px;
            height: 42px;
            font-size: 14px;
        }
    }

    .availability {
        input {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }

        .weekDay {
            width: 50px;
        }

        .availabilityRow {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
        }

        .hyphen {
            margin: 0 8px;
        }
    }

    .meetingAvailability {
        padding-bottom: 52px;
        border-bottom: 1px solid $bg-primary-3;
    }

    .timeSettingsInput input.react-datepicker-ignore-onclickoutside,
    .timeSettingsInput .react-datepicker-wrapper input[type="text"] {
        @include smallText();
        width: 95px;
        height: 28px;
        border: none;
        box-sizing: border-box;
        border-radius: 4px;
        color: $text-primary-1;
        padding: 10px;
        background-color: $bg-primary-4;
    }

    .timeSettingsInput .react-datepicker__triangle {
        display: none !important;
    }

    .form-check-input {
        background-color: transparent;
        border: 1px solid $stroke-secondary-1;
    }

    .form-check-input:checked[type="checkbox"] {
        background-color: $text-highlight-primary !important;
    }

    .closeIcon {
        color: $stroke-secondary-1;
        margin-left: 8px;
    }
}