@import '../../themeColors';
@import '../../components/globalFontMixins';
.form-check-input {
    background-color: transparent;
    border: 1px solid $stroke-secondary-1;
    width: 24px;
    height: 24px;
    box-shadow: none !important;
    cursor: pointer;
    @include backgroundTransition;
    &:focus{
        border: 1px solid $stroke-secondary-1;
    }
    &:checked[type="checkbox"] {
        background-color: $text-highlight-primary !important;
        border: 1px solid $stroke_highlight;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    
    }
}