@import '../../components/globalCSSClasses';
@import '../../components/globalFontMixins';

.messageClassifierContainer{
    margin: 16px 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.messageClassifierProgressContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    .progress{

        background-color: $bg-primary-5;
    }
    .progress-bar{
        background-color: $bg-highlight-primary;
    }
}
.messageClassifierProgressContainer > label{
    @include globalInterFont;
    @include normalText;
    width: calc(50% - 8px);
}
.messageClassifierProgressContainer > div{
    width: calc(50% - 8px);
    height: 4px;
}
.messageClassifierHeadingContainer{
    display: flex;
    align-items: center;
    gap: 16px;
    height: 24px;
}
.messageClassifierHeading{
    @include globalRubikFont;
    @include normalText($text-primary-3);
}