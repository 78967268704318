.qluActionBarContainer {
    width: calc(100vw - 6px);
    position: fixed;
    bottom: 32px;
    /* padding: 0 32px; */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.qluActionBarContainer>div {
    width: min(100%, 1440px);
    padding: 0 32px;
}

.qluActionBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 12px;
    background-color: var(--bg-primary-3);
    border: 1px solid var(--stroke-primary-3);
    z-index: 10;
    width: 100%
}

.qluActionBar>p {
    color: var(--text-primary-3);
}

.qluActionBar>.qluActionBarButtonContainer {
    width: 25%;
    display: flex;
    align-items: center;
}

.qluActionBar>.qluActionBarButtonContainer:first-child {
    justify-content: flex-start;
}

.qluActionBar>.qluActionBarButtonContainer:last-child {
    justify-content: flex-end;
}