@import '../../themeColors';
@import '../../components/globalFontMixins';
.rangeInputContainer{
    padding: 24px 32px 0 32px;
    display: flex;
    align-items: center;
    gap: 16px;
    // min-width: 480px;
    .input-range__label-container{
        @include globalInterFont;
        @include normalText;
    }
    .input-range__label--min, .input-range__label--max {
        bottom: -4px;
    }
    .input-range__label--min{
        left: -16px;
    }
    .input-range__label--max{
        right: -16px;
        display: none;
    }
    .input-range__label--value{
        opacity: 0;
        top: -36px;
        transition: opacity 500ms ease-in-out;
    }
    .input-range__track{
        background-color: $stroke-secondary-1;
    }
    .input-range__track--active {
        background-color: $stroke-highlight;
    }
    .input-range__slider {
        border-color: $stroke-secondary-2;
        background-color: $stroke-secondary-2;
    }
    .input-range__track--background{
        border-radius: 6px;
    }
    >p{
        @include globalInterFont;
        @include normalText($backgroundColor: $bg-primary-5, $padding: 12px);
        border-radius: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 140px;
    }
    &:hover{
        .input-range__label--value{
            opacity: 1;
            transition: opacity 500ms ease-in-out;
        }
    }
}
.rangeInputContainerWithResult{
    padding: 8px 0 0 32px;
    .input-range{
        width: 220px
    }
}