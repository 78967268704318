@import "../../components/globalFontMixins";
@import "../../themeColors";

.iconHeaderContainer {
  position: absolute;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $stroke-primary-3;
  background-color: $bg-primary-2;
  width: 100%;
  z-index: 300;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-left: 40px;
    height: 75px;
    cursor: pointer;

    > span {
      @include globalInterFont;
      @include normalText(white);
    }
  }
}
