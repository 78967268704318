@import '../../themeColors';
@import '../../components/globalFontMixins';
.thumbPillQluButton{
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    border: 1px solid transparent;
    outline: none;
    box-sizing: border-box;
    box-shadow: none;
    @include backgroundTransition;  
    @include globalInterFont;
    
    @include subHeading1($text-primary-1, $bg-primary-5, 11px 12px);
    &:hover{
        @include subHeading1($text-primary-1, $bg-primary-4, 11px 12px);
        path{
            fill: $text-primary-1;
        }
    }

    &Liked{
        @include subHeading1($text-highlight-primary, $bg-highlight-secondary, 11px 12px);
        border-color: $stroke-highlight;
        &:hover{
            @include subHeading1($text-highlight-primary, $bg-highlight-secondary, 11px 12px);
        }
    }
}