@import '../../components/globalCSSClasses.scss';
@import '../../components/globalFontMixins.scss';
// @import '../globalVariable';
@import '../../themeColors';
.hybridConsentScreenContainer{
    display: grid;
    place-content: center;
    height: calc(100vh - 64px);
    width: 100vw;
}

.auth-consent {
    width: 390px;
    background-color: $bg-primary-2;
    border: 1px solid $stroke-primary-2;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 12px;
    > h2 {
        @include subHeading1;
        @include globalInterFont;
        margin-bottom: 12px;
    }
     >p {
        @include globalInterFont;
        @include normalText;
     }
}

.consent-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    margin-bottom: -32px;

    >p:nth-child(1){
        @include globalInterFont;
        @include subHeading1;
        cursor: pointer;
    }
}