@import "../../../themeColors";
@import "../../../components/globalFontMixins";
@import "../../../components/globalFontMixins";
@import "../../../components/globalCSSClasses";
@import "../../../components/globalVariable";
.MoreProspectsFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  .backBtn {
    button {
      color: $text-primary;
      path {
        stroke: $text-primary;
      }
      &:hover {
        color: $text-primary-2;
        path {
          stroke: $text-primary-2;
        }
      }
    }
  }
  .breadCrumb {
    @include globalInterFont;
    color: $text-primary-2;
    span {
      cursor: pointer;
    }
    .active {
      color: $text-primary-1;
    }
  }
  .breadcrumb-item {
    color: $text-primary-2;
  }
  .nextBtn {
    @include globalInterFont;
    @include normalText($text-primary-2);
    padding: 8px 16px;
    cursor: pointer;
    button {
      background: $bg-highlight-primary;
      border-radius: 8px;
      font-weight: 600;
      border: none;
      height: 42px;
      min-width: 96px;
      outline: none;
      color: $bg-primary-1;
      &:hover {
        background: $bg-highlight-secondary;
        color: $bg-highlight-primary;
      }
    }
  }
}
