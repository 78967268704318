$primary-blue: #297af7;
$primary-black: #2e2e2e;
$primary-grey: #a7abb0;
$primary-green: #63b780;
$primary-red: #ef5555;
$primary-orange: #f2994a;
$primary_background: white;
// $primary-blue: #FF8D4E;
// $primary-black: #FFFFFF;
$secondary-blue: #eaf2ff;
$secondary-grey: #f5f6f7;
$secondary-green: #ecf6ef;
$secondary-red: #fef2f2;
$tertiary-grey: #cdd1d7;
$border-grey: #e6e9ed;
$hover-blue: #216bde;
$tag-grey: #f0f1f2;
$plain-white: #ffffff;
$background-grey: #f8f9fa;
$underline-grey: #c0d3f0;
$disabled-blue: #91bcff;
$primary-blue-opaque: rgba(41, 123, 247, 0.2);
$primary-grey-opaque: rgba(167, 171, 176, 0.2);
$primary-red-opaque: rgba(239, 85, 85, 0.2);
$background-gradient: linear-gradient(
  10deg,
  rgba(139, 181, 255, 0.5) 0%,
  rgba(255, 144, 234, 0.2) 30%,
  rgba(238, 174, 202, 0) 60%
);

$primary-bg-3: #222222;
$primary-bg-4: #2c2c2c;
$primary-bg-5: #272727;
$primary-bg-6: #383838;
$primary-bg-7: #2d2d2d;

$text-primary: #e0e0e0;
$text-secondary: #a0a0a0;
$primary-border-grey: #383838;
$text-disabled: #6c6c6c;

$primary-icon-3: #8a4829;
$primary-icon-4: #402f27;
