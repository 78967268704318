@import "../../../components/globalFontMixins";
@import "../../../components/globalVariable";
@import "../../../components/globalCSSClasses";
@import "../../../themeColors";

.jobwiseCampaigns {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tableHeaderContainer {
  background-color: #242424;
  height: 116px;
  border: 1px solid #2c2c2c;
  box-sizing: border-box;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  flex-wrap: wrap;
}

.tableHeaderContainer_left {
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.tableHeaderContainer_heading {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 26px 0px 0px 31px;
}

.tableHeaderContainer_location {
  display: flex;
  flex-direction: row;
  margin: 10px 0px 20px 31px;
  padding-bottom: 10px;

  > p {
    @include globalInterFont;
    font-size: 14px;
    line-height: 146.02%;
    color: #a0a0a0;
    margin-left: 10px;
    margin-top: 4px;
  }
}

.title {
  @include globalInterFont;
  @include subHeading1;
  font-size: 20px;
  width: 50%;
}

.projectTitleContainer {
  display: flex;
  width: 40%;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  > div {
    justify-content: center;
    align-items: center;
    background: #2c2c2c;
    border-radius: 6px;
    width: auto;

    > p {
      @include globalInterFont;
      font-size: 14px;
      line-height: 146.02%;
      color: #a0a0a0;
      padding: 5px;
    }
  }
}

.tribes {
  display: flex;
  flex-direction: row;
  width: 10%;
  height: 22px;
  background: $bg-primary-4;
  border-radius: 5px;
  justify-content: center;
  align-items: center;

  > p {
    @include globalInterFont;
    color: $text-primary-2;
    font-weight: 600;
    font-size: 14px;
    line-height: 12px;
    margin-left: 3px;
    margin-top: 2px;
  }
}

.tableHeaderContainer_right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 30%;
  flex: 1;
}

.stats {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  margin-right: 30px;
  .label {
    @include globalInterFont;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: $text-primary-3;
  }
}

.stats_values {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .value {
    @include globalInterFont;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 121.02%;
    letter-spacing: -0.035em;
    color: #e0e0e0;
    margin-top: 5px;
  }

  .subValue {
    @include globalInterFont;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: $text-primary-3;
    margin-left: 8px;
  }
}

.table {
  width: 100%;
  background-color: $bg-primary-2;
  border-collapse: collapse;
  > thead {
    > tr {
      > th {
        font-size: 14px;
        color: $text-primary-3;
        text-transform: uppercase;
        font-weight: normal;
        padding-left: 24px;
      }
    }
  }

  > tbody {
    border-radius: 8px;
    overflow: hidden;
    > tr {
      background-color: $bg-primary-2;
      border-bottom: 1px solid $stroke-primary-2;

      color: $text-primary-1;

      > td {
        padding: 24px;
      }
    }
    > :last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.status-complete {
  @include globalInterFont;
  font-weight: 400;
  font-size: 16px;
  line-height: 146.02%;
  padding: 5px 8px;
  background: $bg-positive-secondary;
  border-radius: 6px;
  color: $text-positive-primary;
}

.status-stopped {
  @include globalInterFont;
  font-weight: 400;
  font-size: 16px;
  line-height: 146.02%;
  padding: 4px 7px;
  background: #402c2c;
  border-radius: 6px;
  color: $text-negative-primary;
}

.status-active {
  @include globalInterFont;
  font-weight: 400;
  font-size: 16px;
  line-height: 146.02%;
  padding: 4px 7px;
  background: $bg-primary-7;
  border-radius: 6px;
  color: $text-primary-1;
}
