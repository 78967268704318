@import "../globalVariable";
@import "../globalFontMixins";
@import "../globalCSSClasses";

.pillButtonTickContainer {
  @include backgroundTransition;
  @include normalText;
  @include globalInterFont;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 9px 12px 10px;
  border-radius: 36px;
  background-color: $secondary-grey;
  padding: 10.5px 14.67px 10.5px 14.67px;
  &:hover {
    cursor: pointer;
    background-color: $secondary-blue;
  }
}

.pillButtonTickActive {
  background-color: $secondary-blue;

  &:hover {
    background-color: $secondary-grey;
  }
}
