@import '../../themeColors';
@import '../../components/globalFontMixins';

.findMore{
    min-height: 100vh;
    width: 100%;
    position: relative;
    padding-top: 92px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    >div:first-of-type{
        width: calc(min(100%, 1080px));
        position: relative;
        >div:first-child{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 48px;
            >h1{
                @include globalInterFont;
                @include heading2;
                font-weight: 600;
            }
            .findMoreTabsConatiner{
                display: flex;
                align-items: center;
                gap: 40px;
            }
            >span{
                position: relative;
                width: 100%;
                margin-bottom: 20px;
                >h3{
                    position: absolute;
                    top: 0;
                    width: 100%;
                    @include globalInterFont;
                    @include smallText;
                    color: $text-primary-3;
                    font-weight: 400;
                    text-align: center;
                }
            }
        }
        >span{
            position: absolute;
            top: 200px;
            width: 100%;
        }
    }
    .findMoreProfileLoaderContainer{
        margin-top: 200px;
        transform: translateX(50%);
    }
}