@import '../../../components/globalFontMixins';
@import '../../../themeColors';

.jobDetailsLocationContainer{
    // min-height: calc(100vh - 70px - 98px - 32px);
    // padding-top: 100px;
    > h1{
        @include globalInterFont;
        @include normalText($text-primary-2);
        margin: 0 0 32px 0
    }
    > p{
        @include globalRubikFont;
        @include normalText($text-primary-3);
        margin-bottom: 12px
    }
    .selectedLocationContainer{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;    
        padding: 32px 0;
    }
    .specificLocation{
        @include normalText($text-negative-primary);
        background-color: $text-negative-secondary;
        border: 1px solid $stroke-negative;
        border-radius: 6px;
        padding: 0 4px;
    }
}