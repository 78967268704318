

.companyCardContainer{    
    padding: 64px 16px 32px 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    .companyCard{
        background-color: transparent;
        border: none;
        padding: 0;
        .companyCardBody{
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 12px;
            padding: 12px 0;
        }
    }
}