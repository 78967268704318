.heartsContainer {
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
}

.heart {
  cursor: pointer;
}
