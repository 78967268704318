@import "../../../themeColors";
@import "../../../components/globalFontMixins";

.about {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 50px 20px 25px;
  align-items: flex-start;
  width: 49vw;
  border-bottom: 1px solid $bg-primary-5;
}

.about:hover {
  background-color: $bg-primary-4;
  border-radius: 8px;
}

.otherExperience {
  display: flex;
  flex-direction: row;
  min-height: 130px;
  justify-content: space-around;
  align-items: flex-start;
  border-bottom: 1px solid $bg-primary-5;

  .otherExperienceSection {
    display: flex;
    width: 13.5vw;
    flex-direction: column;
    gap: 15px;

    > div {
      display: flex;
      flex-direction: row;
      gap: 12px;
      flex-wrap: wrap;
      align-items: center;

      > div {
        display: flex;
        flex-direction: row;
        gap: 5px;
        flex-wrap: wrap;
        align-items: center;
      }
    }
  }
}

.tag-section-container {
  border-bottom: 1px solid $bg-primary-5;
}

.section-with-tags {
  display: flex;
  width: 47vw;
  min-height: 120px;
  flex-direction: column;
  gap: 15px;
  padding: 20px 50px 20px 25px;

  > div {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;

    > div {
      display: flex;
      flex-direction: row;
      gap: 5px;
      flex-wrap: wrap;
      align-items: center;
    }
  }
}

.hoverToLikeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 20px 20px 10px 25px;
}

.hoverToLikeContainerWithoutPadding {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 10px 10px 10px 10px;
}
.hoverToLikeContainer:hover {
  background-color: $bg-primary-4;
  border-radius: 8px;
}

.sectionHeading {
  @include globalInterFont;
  font-weight: 600;
  font-size: 18px;
  color: $text-primary-1;
}

.sectionDescription {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 7px;
  @include globalInterFont;
  font-weight: 400;
  font-size: 16px;
  color: $text-primary-1;
  text-align: left;
}

.sectionSubHeading {
  @include globalInterFont;
  font-weight: 400;
  font-size: 16px;
  color: $text-primary-2;
}

.tag-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.tag-element {
  padding: 2px 7px;
  background: $bg-primary-5;
  border: 1px solid $stroke-primary-6;
  border-radius: 6px;
  @include globalInterFont;
  font-weight: 400;
  font-size: 16px;
  color: $text-primary-1;
  cursor: pointer;
}

.tag-element:hover {
  color: #e63b7f;
}

.educationDrawer {
  display: flex;
  width: 48vw;
  flex-direction: column;
  gap: 15px;
  border-bottom: 1px solid $bg-primary-5;
  margin-bottom: 20px;
}

.experience {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 10px 0px 25px;
  align-items: flex-start;
  width: 48vw;
  border-bottom: 1px solid $bg-primary-5;
  gap: 20px;

  .experienceBody {
    width: 48vw;
    min-height: 80px;
    display: flex;
    flex-direction: row;
    gap: 5px;

    .companyInfo {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 15px;
      gap: 10px;
      width: 10vw;
    }

    .experienceSection {
      display: flex;
      flex-direction: column;
      gap: 3px;
      width: 37vw;
      border-bottom: 1px solid $bg-primary-5;

      .positionSection {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 36.5vw;
        margin-left: -10px;
      }

      .experienceHeader {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 4px;
        margin-left: -10px;

        .companyName {
          @include globalInterFont;
          font-weight: 400;
          font-size: 18px;
          line-height: 121.02%;
          color: #4e95ff;
          cursor: pointer;
        }

        .companyName:hover {
          text-decoration: underline;
          color: #4e95ff;
        }

        .position {
          @include globalInterFont;
          font-weight: 600;
          font-size: 16px;
          color: $text-primary-1;
        }
      }

      .experienceSectionBody {
        display: flex;
        text-align: justify;
        flex-direction: column;
        gap: 10px;
        width: 95%;
        margin-bottom: 5px;
        margin-left: 5px;
      }
    }
  }
}

.profileHeader1 {
  display: flex;
  flex-direction: row;
  width: 48vw;
  min-width: 36vw;
  height: 128px;
  background-color: $bg-primary-3;
  border-radius: 7px 7px 0px 0px;
  border-bottom: 1px solid $bg-primary-5;
}

.profileHeaderLeft1 {
  display: flex;
  width: 38vw;
  margin-left: 10px;
  height: 115px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  align-self: center;
  gap: 18px;
}

.profileHeaderInfo1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 36vw;
  gap: 3px;
  margin-bottom: 5px;
  > span {
    @include globalInterFont;
    font-weight: 600;
    font-size: 18px;
    line-height: 121.02%;
    color: $text-primary-1;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  > p {
    @include globalInterFont;
    font-weight: 400;
    font-size: 16px;
    line-height: 121.02%;
    color: $text-primary-1;
  }
}

.profileHeaderRight1 {
  display: flex;
  width: 8vw;
  height: 100px;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  align-self: center;
  gap: 20px;

  > p {
    @include globalInterFont;
    font-weight: 400;
    font-size: 16px;
    line-height: 121.02%;
    color: #4e95ff;
    cursor: pointer;
  }
}

.slide-drawer{
  width: 50vw;
  .revampProspectFullCardVarient1{
    transform: none;
  }
} 