@import '../../../Optout/global.scss';

.consentButton{
    @include bodyText2($backgroundColor: $bg-highlight-primary, $padding: 11px 32px);
    @include backgroundTransition;
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    outline: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    &:hover{
        @include bodyText2($backgroundColor: $bg-highlight-hover,  $padding: 11px 32px);
    }
    &:disabled{
        @include bodyText2($backgroundColor: $bg-highlight-disabled, $padding: 11px 32px);
        cursor: not-allowed;
    }
}