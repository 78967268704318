@import '../../themeColors';
@import '../../components/globalFontMixins';
.stepperButton{
    padding: 8px;
    border-radius: 6px;
    background-color: transparent;
    border: none;
    outline: none;
    display: grid;
    place-content: center;
    @include backgroundTransition;
    &:hover{
        background-color: $bg-primary-7;
    }
    &:disabled{
        cursor: not-allowed;
        background-color: transparent;
    }
}

.stepperButtonSelected{

    &:hover{
        background-color: $bg-highlight-secondary;
    }
}