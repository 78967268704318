@import "../../globalFontMixins";
@import "../../globalVariable";

.numberedDetails{
    width: 30%;
    display: flex;
    flex-direction: column;

    &>h1{
        @include globalRubikFont;
        @include smallText($primary-grey);
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 8px;
    }

    &>h3{
        @include globalInterFont;
        @include heading2;

        font-weight: bold;
    }

    margin-bottom: 42px;
}