@import '../globalVariable';
@import '../globalCSSClasses';
.formik_input_custom{
    @extend .primaryInput;
    display: block;
    width: 100%;
}



.input_error {
    border: 1px solid $primary-red;
}