@import '../../themeColors';
@import '../../components/globalFontMixins';

.inputOuter_Container{
    position: relative;
    width: 55px;
    .unitLabel{
        @include normalText;
        @include globalInterFont;
        position: absolute;
        top: 25%;
        right: 16px;
    }
    .spinnerButtons{
        display: none;
        position: absolute;
        top: 0;
        right: 8px;
        >div{
            cursor: pointer;
            padding: 0 8px;
            &:hover{
                >svg>path{
                    stroke: $text-primary-1;
                }
            }
        }
    }
    &:hover{
        .unitLabelActive{
            display: none;
        } 
        .spinnerButtons{
            display: flex;
            flex-direction: column;
            gap: 0;
        }
    }
    &:focus{
        .spinnerButtons{
            display: flex;
            flex-direction: column;
            gap: 0;
        }
    }
}
.input_Container{
    -moz-appearance: textfield;
    appearance: textfield;
    @include normalText;
    @include globalInterFont;
    background-color: $bg-primary-5;
    border-radius: 6px;
    padding: 12px 16px;
    border: none;
    height: auto;
    text-align: left;
    cursor: default;
    &:focus{
        @include normalText;
        padding: 12px 16px;
        background-color: $bg-primary-6;
        box-shadow: none;
        border: none;
    }
    &::placeholder{
        @include normalText;
        @include globalInterFont;
    }
    &::-webkit-inner-spin-button, 
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }
    &:hover{
        background-color: $bg-primary-7;
    }
}
