@import "../../../components/globalCSSClasses";
@import "../../../components/globalFontMixins";
@import "../../../components/globalVariable";

.CompanyDetailsdropdownMenu {
  margin: 0px;
  border-radius: 8px;
  padding: 0px;
  padding-bottom: 20px;
  padding-top: 8px;
  width: 45%;
  margin-top: 6px;
  .CompanyDetailsdropDownItemActive {
    color: #297af7 !important;
  }
  .CompanyDetailsdeleteDotted {
    color: #ef5555 !important;
  }

  .CompanyDetailsdeleteProstects {
    color: #ef5555 !important;
  }
  .CompanyDetailsdropDownItem {
    @include normalText;
    @include globalInterFont;
    padding: 0px;
    margin: 0px;
    padding-left: 16px;
    padding-top: 12px;
    padding-right: 80px;
    background-color: #ffffff;
    margin-top: 15px;
    margin: 0;
    &:hover {
      border: none;
    }
    &:focus {
      outline: none;
      border: none;
    }
    &:active:focus {
      outline: none;
      border: none;
    }
  }
}

.CompanyDetailstempDropdown {
  @include smallText;
  @include globalInterFont;
  padding: 0px;
  margin-bottom: 32px;
  background: none;
  background-color: $secondary-grey !important;
  width: 45%;
  height: 6vh;
  padding: 10px 16px 10px 16px;
  border-radius: 8px;
  color: $primary-black !important;
  border: none !important;
  transition: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:focus {
    outline: none;
    border: none;
  }
  &:active:focus {
    outline: none;
    border: none;
    box-shadow: none !important;
  }
}
