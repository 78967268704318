@import "./_themeColors.scss";

:root {
  overflow-y: auto;
  scrollbar-gutter: stable;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 8px;
  background: $bg-primary-1;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: $bg-primary-7;
}

.Toastify__toast-container {
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff;
}


.dflex-cc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}