@import '../../../themeColors';
@import '../../../components/globalFontMixins';
.outboxSummaryContainer{
    padding: 0 20%;
    width: 100%;
    >div{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 40px;
        background-color: $bg-primary-2;
        border: 1px solid $stroke-primary-2;
        border-radius: 8px;
        .outboxSummaryHeader{
            display: flex;
            align-items: center;
            gap: 12px;
            >span{
                width: 48px;
                height: 48px;
                display: grid;
                place-content: center;
                background-color: $bg-positive-secondary;
                border-radius: 50%;
                path{
                    stroke: $bg-positive-primary;
                }
            }
            >h3{
                @include globalInterFont;
                @include normalText;
            }
        }
        .outboxSummaryNameContainer{
            display: flex;
            flex-direction: column;
            gap: 16px;
            input{
                @include globalInterFont;
                @include normalText;
                background-color: $bg-primary-4;
                border: 1px solid $stroke-primary-4;
                padding: 4px 8px;
                border-radius: 8px;
                outline: none;
                &:focus{
                    background-color: $bg-primary-5;
                    border: 1px solid $stroke-primary-5;
                    outline: none;
                }
            }
            >span{
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 12px;
                button{
                    width: 32px;
                    height: 32px;
                    display: grid;
                    place-content: center;
                    border-radius: 8px;
                    border: none;
                    outline: none;
                    background-color: $bg-primary-5;
                    @include backgroundTransition;
                    &:hover, &:active{
                        background-color: $bg-primary-6;
                        path{
                            stroke: $text-primary-1;
                        }
                    }
                }
            }
            h4{
                @include globalRubikFont;
                @include normalText($text-primary-3);
            }
            h2{
                @include globalInterFont;
                @include heading2;
                white-space: nowrap; 
                overflow: hidden;
                text-overflow: ellipsis; 
                width: calc(100% - 40px);
            }
        }
        .outboxSummaryStatsContainer{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 64px;
            span{
                display: flex;
                flex-direction: column;
                gap: 12px;
                h4{
                    @include globalRubikFont;
                    @include normalText($text-primary-3);
                }
                h2{
                    @include globalInterFont;
                    @include heading2;
                }
            }
        }
        .outboxSummaryShareWithTribe{
            display: flex;
            flex-direction: column;
            gap: 12px;
            >span{
                display: flex;
                align-items: center;
                gap: 16px;
                p{
                    @include globalInterFont;
                    @include normalText;
                }
            }
            h4{
                @include globalInterFont;
                @include normalText($text-primary-2);
            }
        }
       
    }
}