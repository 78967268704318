@import '../../themeColors';

.qluStepperContainer{
    display: flex;
    flex-direction: column;
    gap: 24px;  
    padding-top: 32px;
    padding-bottom: 32px;
    min-height: 100vh;
    max-height: 100vh;
    background-color: $bg-primary-2;
    overflow-y: auto;
    z-index: 11;
    >div{
        > button {
            margin-left: 16px;
            margin-right: 16px;
        } 
    }

    > hr{
        background-color: $stroke-primary-5;
        opacity: 1;
        min-height: 1px;
    }
    border-right: 1px solid $stroke-primary-3;
}
.stepToolTipInner{
    position: absolute;
    left: 52px;
    top: -14px;
    width: max-content;
}