@import "../globalVariable";
@import "../globalFontMixins";
@import "../globalCSSClasses";
@import '../../themeColors';
.renderInlineEditortoolBarItemIsActive {
  transform: translateY(1px);
  color: $text-primary-1 !important;
  box-shadow: none;
}

.renderInlineEditorContainer {
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  width: 100%;
  // button {
  //   @extend .secondaryButton;
  //   background-color: #F8F9FA;
  //   padding: 4px 5px 4px 5px;
  //   margin-left: auto;
  //   &:hover,
  //   &:focus,
  //   &:active {
  //     cursor: pointer;
  //     outline: none;
  //     box-shadow: none !important;
  //     border: none;
  //     background-color: #F8F9FA;
  //     color: #297AF7;
  //   }
  // }
  .renderInlineEditortoolBarItem {
    @include backgroundTransition;
    width: 28px;
    height: 27px;
    display: flex;
    align-items: center;
    color: $stroke-secondary-1;
    cursor: pointer;
    &:hover {
      color: $stroke-highlight;
    }
  }
}
