@import "../../../../../../themeColors";

.flexInput {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  color: #e0e0e0;
  align-items: flex-start;
  margin: 0px;
  padding: 0px;
  background: transparent;
  scroll-behavior: smooth;
}

.fullScreenAccordion {
  display: flex;
  flex-direction: column;
  position: relative;

  .content {
    flex: 1;
    overflow-y: auto;
  }

  >span.jd-chat-error {
    position: absolute;
    text-align: center;
    bottom: 8px;
    width: 100%;
    color: $text-negative-primary;

    >span {
      margin-inline: 1ch;
      color: $text-highlight-primary;
      cursor: pointer;
    }
  }
}