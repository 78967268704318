@import "../../themeColors";

.findmore-root {
    position: relative;
    .findmore-modal {
        border-radius: 8px;
        color: $text-primary-1;
        background-color: $bg-primary-2;
        width: 300px;
        padding: 24px;
        position: absolute;
        top: 44px;
        right: 0;
        z-index: 9;

        .fm-opts{
            margin: 24px 0;
            display: flex;
            gap: 12px;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }
    }
}

