@import ".././../components/globalFontMixins";

.accordion-item {
  list-style: none;
  background-color: #333333;
}
.accordion-toggle {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #2c2c2c;
  border: 1px solid #333333;
  padding: 1em;
}

.accordion-label {
  @include normalText;
  @include subHeading1;
  color: white;
}
.accordion-toggle:p{
  @include normalText;
}
.accordion-toggle:hover {
  background-color: #333333;
}
.accordion-toggle h3 {
  margin: 0;
}
.accordion-content {
  @include normalText;
  @include backgroundTransition;
  padding: 2px;
  padding-bottom: -1px;
  background: #333333;
}

.caretUp {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}