@import '../../themeColors';

.CaretStepperContainer{
    display: flex;
    align-items: center;
    >button{
        &:hover{
            color:  $text-primary-2;
        }
    }
    .CaretStep{
        color: $text-primary-1;
        &:hover{
            color: $text-primary-1;
        }
    }
}