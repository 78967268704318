@import '../../../Optout/global.scss';

.consentInputContainer{
    display: flex;
    flex-direction: column;
    gap: 12px;
    >p{
        @include bodyText1($fontColor: $text-primary-3, $font: Rubik, $text-transform: uppercase);
    }

    >textarea {
        min-height: 100px;
    }
    >input, >textarea{
        @include backgroundTransition;
        @include bodyText1;
        min-width: 250px;
        background: $bg-primary-5;
        border-radius: 8px;
        padding: 12px 16px;
        border: none;
        outline: none;
        box-shadow: none;
        &:focus,
        &:hover {
            border: none;
            outline: none;
            box-shadow: none;
            background: $bg-primary-6;
        }
        &::placeholder {
            @include bodyText1($text-primary-2);
        }
    }
}


@media screen and (max-width: 719px) {
    .container>input{
        min-width: unset;
    }
}