@import "../../themeColors";
@import "../../components/globalFontMixins";
@import "../../components/globalFontMixins";
@import "../../components/globalCSSClasses";
@import "../../components/globalVariable";
.footerSection {
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  width: 1174px;
  padding: 20px;
  z-index: 100;
  transform: translateX(calc(100vw - 1180px));
  color: $text-primary-1;
  bottom: 0;
  background-color: $bg-primary-5;
  border: 1px solid $stroke-primary-5;
  transition: all 0.3s ease-in-out;
  @include globalInterFont;

  .right {
    display: flex;
    align-items: center;
    gap: 20px;
    .prospectTabBar {
      display: flex;
      gap: 1.25rem;
      .tab {
        @include normalText($fontColor: $primary-grey);
        @include globalRubikFont;
        display: flex;
        gap: 32px;
        margin-top: 14px;
        margin-bottom: 14px;
        cursor: pointer;
        &.active {
          // @include normalText();
          color: $text-primary;

          text-decoration: underline $stroke-highlight 2px;
          text-underline-offset: 5px;
        }
      }
    }
    .prospectFinish {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;

      .finishReview {
        background-color: $bg-highlight-primary;
        color: $bg-primary-1;
        font-weight: 600;
        border-radius: 8px;
        height: 42px;

        border: 1px solid transparent;
        transition: 0.3s all ease-in-out;
        cursor: pointer;
        &:hover {
          color: $primary-1;
          background-color: $bg-highlight-secondary;
        }
      }
      .cancel {
        background-color: transparent;
        cursor: pointer;
        border: none;
        &:hover {
          background-color: $text-disabled;
        }
      }
    }
  }
}
.recommendedProspectFullCard {
  width: 1180px;
  min-height: 100vh;
  background-color: $bg-primary-3;
  display: block !important;
  transform: translateX(calc(100vw - 1180px));
  color: $text-primary-1;
  margin-top: 0 !important;
  border: 1px solid $stroke-primary-5;
  border-radius: 8px 0 0 0;

  .prospectJobTitle {
    color: $text-primary-1;
  }
  .prospectLocation {
    color: $text-secondary;
  }
  .prospectJobCompany {
    font-family: Inter;
    color: var(--bg-highlight-primary);
    background-color: var(--bg-highlight-secondary);
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 146.02%;
    padding: 2px 4px;
    border-radius: 6px;
  }
  .experiencePanel,
  .relevantExperiencePanelSummary,
  .totalExperiencePanel,
  .specialitiesPanel,
  .educationPanel,
  .VisaDemoPanel {
    padding: 24px 25px;
    .missing {
      color: $primary-red;
    }
  }
  .columnHeading {
    @include globalInterFont;
    color: $text-primary-1;
    font-weight: 600;
    margin-bottom: 16px;
    span {
      color: $text-secondary;
    }
  }
  .headSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 14px;
    position: sticky;
    z-index: 100;
    border-radius: 8px 0px 0 0;
    top: 0;
    background-color: $bg-primary-3;
    border-bottom: 1px solid $stroke-primary-5;

    .left {
      display: flex;
      gap: 11px;
      align-items: center;
      .prospectAvatar {
        border-radius: 50%;
      }
      .prospectName {
        font-weight: bold;
        .linkedInLogo {
          margin-left: 5px;
          margin-bottom: 3px;

          &:hover {
            fill: #0072b1;
            path {
              fill: #0072b1;
            }
          }
        }
      }
    }
  }
  .emojiContainer {
    position: absolute;
    top: 40vh;
  }
  .collab {
    margin-top: 30px;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;

    .commentIcon {
      * {
        stroke: var(--bg-highlight-primary);
      }
      color: $text-primary-1;
    }
    div:not(:first-child) {
      // margin-left: 15px;
      button {
        display: flex;
        background: $bg-primary-6;
        min-width: 32px;
        height: 32px;
        padding: 2px 3px;
        align-items: center;
        border-radius: 37px;
      }
    }

    div:first-child {
      button {
        display: flex;
        align-items: center;
        background: $bg-highlight-secondary;
        color: $bg-highlight-primary;
        font-weight: bold;
        // min-width: 32px;
        height: 32px;
        padding: 2px 3px;
      }
    }

    div:last-child {
      button {
        display: flex;
        align-items: center;
        background: $bg-highlight-secondary;
        color: $bg-highlight-primary;
        font-weight: bold;
        border-radius: 6px;
        height: 32px;
        padding: 2px 3px;
      }
    }
    .toggleEmoji {
      * {
        stroke: var(--bg-highlight-primary);
      }
    }
    .emojiOwner {
      // button {
      //   // background: $bg-highlight-secondary !important;
      //   // color: $text-highlight-primary;
      //   font-weight: bold;
      // }
      .count {
        font-weight: bold;
      }
      .emoji {
        font-size: 1.25rem;
      }
    }
    .emojies {
      position: relative;

      .tooltiptext {
        display: none;
        background-color: $bg-primary-1;
        color: $text-primary-1;
        text-align: center;
        padding: 5px 15px;
        width: 150px;
        border-radius: 6px;
        position: absolute;
        top: -50px;
        left: 50px;
        z-index: 500 !important;

        span {
          display: block;
        }
      }
      &:hover .tooltiptext {
        display: block;
      }
    }
    button {
      color: $text-primary-1;
      span.count {
        padding: 1px 0;
      }
      .emoji {
        font-size: 17px;
      }
    }
  }
  .recommendedIntro {
    display: flex;
    // border-top: $stroke-primary-5 1px solid;
    // border-bottom: $stroke-primary-5 1px solid;
    .recommendedActivities {
      display: flex;
      justify-content: space-between;
      padding-block: 10px;
      @include globalInterFont;
      color: $text-secondary;
      .activityTitle {
        color: $text-primary;
        span {
          color: $text-secondary;
        }
      }
      .activityView {
        color: $text-secondary;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
          color: $text-primary;
        }
      }
    }
    .recommendedSkillbarContainer {
      position: relative;
    }
    .recommendedSkillBars {
      width: 20rem;
      // border-right: $stroke-primary-5 1px solid;
      padding: 15px;
      margin-top: 5px;
      position: sticky;
      transition: all 0.3s ease;
      top: 128.33px;
      // display: flex;
      // flex-direction: row;
      .experienceSubScores {
        margin-left: 0.5rem;
        margin-bottom: 0.5rem;
        p:first-child {
          font-weight: 400;
        }
      }
      .recommendedSkillBorder {
        height: 2px;
        border-bottom: $stroke-primary-5 1px solid;
        width: calc(100% + 40px);
        margin-left: -25px;
      }
      p {
        @include normalText;
        margin-top: 10px;
        width: 100px;
        margin-bottom: 0px;
      }

      & > .row > div > .totalScore {
        font-weight: 600;
      }

      .barFull {
        margin-top: 1.7em;
        // margin-right: 1em;
      }

      .barFull {
        &.progress {
          background-color: $bg-primary-6;
          .progress-bar {
            background-color: $text-highlight-primary !important;
          }
        }
      }

      .barFull.progress {
        height: 4px !important;
        margin-left: 10px;
      }
    }
    .recommendedMainContainer {
      border-left: var(--stroke-primary-5) 1px solid;
    }
    .recommendedAbout {
      // max-width: 60%;
      padding: 25px;
      margin-top: 5px;
      margin-left: 5px;
      .summary {
        @include normalText;
        margin-top: 10px;
        white-space: pre-line;
        text-align: left;
        margin-bottom: 0px;
      }
    }
  }
  .recommendedMain {
    .descriptionSkill {
      transition: all 0.1s ease-in-out;
      text-transform: capitalize;
    }
    .tablets {
      p {
        background-color: var(--bg-primary-6);
        padding: 1px 4px 1px 4px;
        border-radius: 6px;
        margin-left: -0.2rem;
        transition: 0.1s all ease-in-out;
      }
    }
    .horizontalLine {
      margin-top: 10px;
      margin-bottom: 10px;
      border-top: 1px solid var(--stroke-primary-5);
      width: calc(100% + 100px);
    }
    .experiencePanel {
      padding: 24px 25px;
      border-top: 1px solid transparent;

      .skillHighLight {
        // font-family: Inter;
        color: var(--bg-highlight-primary);
        background-color: var(--bg-highlight-secondary);
        margin: 0;
        padding: 0;
        // font-weight: normal;
        // font-size: 16px;
        // padding-left: 5px;
        // line-height: 146.02%;

        border-radius: 6px;
      }
      .singleExperienceContainer {
        display: flex;
        gap: 5px;
      }
      .experienceList {
        display: flex;
        flex-direction: column;

        .single-experience {
          display: flex;
          margin-top: 1.25rem;
          min-height: 43px;

          .experience-date {
            > p {
              width: 150px;
            }

            .duration {
              @include subHeading1;
              margin-left: 1.5rem;
              margin-top: 0.95rem;
              margin-bottom: 0.95rem;
            }
            .experience-span {
              font-weight: 600;
            }
          }

          .experience-info {
            margin-left: 1.3rem;
            // border-bottom: 1px solid $bg-primary-5;
            width: 100%;

            &.noBorder {
              border-bottom: 0px solid $bg-primary-5 !important;
            }

            .title_company {
              span {
                @include globalInterFont;
                @include normalText();
                background-color: $bg-primary-6;
                padding: 2px 4px;
                border-radius: 6px;
              }
            }
            .summary {
              // width: 60rem;
              margin-top: 0.5rem;
              white-space: pre-line;
              text-align: left;
            }

            .title {
              min-height: 30px;
            }

            .title .years {
              color: $text-primary-2;
            }

            .duration {
              font-weight: 300;
              color: $text-primary-2;
            }

            .title_exp {
              @include globalInterFont;
              @include normalText();
              background-color: $bg-primary-6;
              padding: 2px 4px;
              border-radius: 6px;
            }

            .title_location {
              margin-top: 0.5rem;
              font-weight: 300;
              color: $text-primary-2;
            }
          }

          .experience-skills,
          .experience-specialities {
            display: flex;

            gap: 0.5rem;
            padding-top: 0.8rem;
            min-height: 30px;
            .tablets {
              //   p:hover {
              //     // font-family: Inter;
              //     color: var(--bg-highlight-primary);
              //     background-color: var(--bg-highlight-secondary);
              //     // margin: 0;
              //     // padding: 0;
              //     font-weight: normal;
              //     // font-size: 16px;
              //     // padding-left: 5px;
              //     // line-height: 146.02%;
              //     // padding-inline: 5px;
              //     border-radius: 6px;
              //   }
            }
            margin-bottom: 0.5rem;
            > p {
              color: $text-primary-2;
              margin-right: 1rem;
            }
            div {
              display: flex;
              flex-wrap: wrap;
              gap: 0.5rem;
            }
          }

          .experience-specialities {
            display: flex;
            gap: 0.5rem;

            margin-bottom: 0.5rem;

            > p {
              color: $text-primary-2;
              margin-right: 1rem;
            }
            // div {
            //   display: flex;
            // }
          }
        }
      }
    }
    .relevantExperiencePanelSummary {
      .relevantExperienceSummary {
        .relevant-summary {
          display: flex;
          min-height: 30px;
          .duration {
          }
          > p:first-child {
            width: 30%;
          }
          .title {
            width: 70%;
            span {
              //   color: var(--bg-highlight-primary);
              background-color: $bg-primary-6;
              margin: 0;
              padding: 0;
              font-weight: normal;
              font-size: 16px;
              line-height: 146.02%;
              padding: 2px 4px;
              border-radius: 6px;
            }

            &:last-child {
              padding-bottom: 1.5rem;
            }

            &.noneFound {
              margin-top: 0 !important;
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
    .totalExperiencePanel {
      display: flex;

      .totalExperience {
        flex-basis: 50%;
        .totalExp {
          background-color: $bg-primary-6;
          display: inline-block;
          padding-inline: 2px;
          border-radius: 6px;
        }
      }
      .functionalExperience {
        flex-basis: 50%;
        .functionalExperienceContainer {
          margin-top: 0.5rem;
        }
      }
    }
    .specialitiesPanel {
      .specialities {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 0.5rem 2rem;
        div {
          display: flex;
          flex-wrap: wrap;
          margin-top: 0.5rem;
        }
        .subHeading {
          p {
            @include subHeading1;
          }
          @include subHeading1;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }
      .tablets {
        span {
          background-color: var(--bg-primary-6);
          padding: 1px 4px 1px 4px;
          border-radius: 6px;
          margin-left: -0.2rem;
        }
      }
      span {
        font-weight: normal;
      }
    }
    .skillsPanel {
      & > .columnHeading {
        padding: 24px 25px 0px;
      }

      .missing {
        color: $primary-red;
      }
      .tablets {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.5rem 2rem;
        text-transform: capitalize;
        margin-left: 1rem;
        align-items: flex-start;
        @include globalInterFont;
        p {
          background-color: transparent;
          :not(.duration) {
            background-color: var(--bg-primary-6);
            padding: 1px 4px 1px 4px;
            border-radius: 6px;
            margin-left: -0.2rem;
          }
          &.missing > span:not(.duration) {
            background-color: $bg-negative-secondary;
          }
          .duration {
            color: $text-secondary;
          }
          .globalDotStyle {
            background-color: transparent;
            padding: 0px;
          }
        }
      }
      .highSkill,
      .mediumSkill,
      .lowSkill {
        padding: 12px 12px;
        .columnHeading {
          font-size: 14px;
          span {
            font-weight: normal;
            color: $text-secondary;
          }
          span.missing > span {
            color: $text_negative_primary;
          }
        }
        .recommendedCollapse {
          margin-bottom: -0.5rem;
        }
      }
      .highSkill {
      }
      .mediumSkill {
      }
      .lowSkill {
      }
    }

    .educationPanel .education > p > span.duration {
      color: $text-primary-2;
    }
    .educationPanel {
      .SingleEducation {
        background-color: $bg-primary-6;
        display: inline-block;
        border-radius: 6px;
        padding: 2px 5px;
        margin: 5px;
      }
    }

    .VisaDemoPanel {
      display: flex;
      margin-bottom: 100px;
      .visaPanel {
        flex-basis: 50%;
      }
      .demographicsPanel {
        flex-basis: 50%;
      }
    }
  }
}
