@import "../../themeColors";
.smw-root{
    p {
        width: 100%;
        text-align: center;
        margin: 18px 0;
        color: $text-primary-1;
    }

    > p:nth-child(1){
        font-size: 18px;
    }
}