@import "../../components/globalVariable";
@import "../../components/globalFontMixins";
@import "../../_themeColors.scss";

.prospectLoaderContainer {
  width: 100%;
  padding: 10vh 0;
  // margin-left: 15vw;
  display: grid;
  place-content: center;

  >h5 {
    @include normalText;
  }
}

.tooltip-inner {
  text-transform: capitalize;
}

.Toastify__toast-theme--dark {
  background-color: $text-primary-2;
}

.Toastify__toast-container {
  width: auto !important;
}

.Toastify__toast-theme--light {
  background: $text-primary-2;
}

.companyModal {
  visibility: hidden;
  position: fixed;
  top: 0;
  // bottom: 30%;
  left: 0;
  right: 0;
  width: 735px;
  height: 431px;
  margin-left: auto;
  margin-right: auto;
  z-index: 9999;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  &.showCompanyModal {
    transform: translate(0, 48px);
    visibility: visible;
    opacity: 1;

    @media screen and (max-width: 1300px) {
      transform: translate(0, 5vh);
    }
  }
}

.prospectMainContainer {
  .single-prospect {
    &.hidden {
      visibility: hidden;
    }

    &:last-of-type {
      margin-block-end: 30px;
    }
  }
}

.RecommendedFooterParent {
  position: fixed;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.fullCardView {
  width: 0;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: none;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 200;
  transition: all 0.5s ease-in-out;

  &.showFullCard {
    width: 100vw;
  }
}

.prospectBackDrop {
  opacity: 0.3;
  pointer-events: none !important;
}

.fade-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}

.fade-exit-active {
  opacity: 0;
  transform: translateX(100%);
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms, transform 500ms;
}

.rotate-enter {
  opacity: 0;
  transform: rotate(180deg);
}

.rotate-enter-active {
  opacity: 1;
  transform: rotate(0deg);
}

.rotate-exit {
  opacity: 1;
  transform: rotate(0deg);
}

.rotate-exit-active {
  opacity: 0;
  transform: rotate(-180deg);
}

.rotate-enter-active,
.rotate-exit-active {
  transition: opacity 500ms, transform 500ms;
}

.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in;
}