@import "../../../themeColors";
@import "../../../components/globalFontMixins";
@import "../../../components/globalFontMixins";
@import "../../../components/globalCSSClasses";
@import "../../../components/globalVariable";
.MoreProspectsLayoutContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  .footer {
    position: fixed;
    bottom: 50px;
    width: 784px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background: $bg-primary-6;
    height: 74px;
    border: 1px solid $stroke-primary-6;
    box-sizing: border-box;
    border-radius: 12px;
    z-index: 100;
  }
}
