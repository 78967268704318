@import '../../themeColors';
@import '../../components/globalFontMixins';

.tabularContainer{
    display: flex;
    flex-direction: column;
    background-color: $bg-primary-3;
    border: 1px solid $stroke-primary-3;
    border-radius: 8px;
    width: 100%;
    padding-bottom: 100px;
    > .tabularHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 32px 24px 0 24px;
        > .headingContainer{
            display: flex;
            align-items: center;
            gap: 12px;
            >h2{
                @include heading3;
            }
        }
        >.tabsContainer {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }
    > .tabularBody{
        padding: 24px;
        min-height: 50vh;
    }
}