@import '../../themeColors';
@import '../../components/globalFontMixins';

.seconadryInputContainer{
    display: flex;
    align-items: center;
    gap: 8px;
    >input{
        @include globalInterFont;
        @include normalText;
        border: none;
        border-bottom: 1px solid $stroke-secondary-1;
        outline: none;
        width: 200px;
    }
    >button{
        width: 24px;
        height: 24px;
        display: grid;
        place-content: center;
        border-radius: 8px;
        background: $bg-primary-5;
        border: 1px solid $stroke-primary-5;
        &:hover{
            background: $bg-primary-6;
            border: 1px solid $stroke-primary-6;
        }
    }
}