@import '../globalFontMixins';
@import '../globalVariable';

.profileSummaryCard{
    @include backgroundTransition;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    >img{
        border-radius: 50%;
    }
    a{
        margin: 0 8px;
    }
    & > span > div{
        display: flex;
        align-items: center;
    } 
    .profileName{
        @include globalInterFont;
        @include normalText;
        font-weight: 600;
        height: 22.5px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
    }
    .profileSummaryCardSeperator{
        content: '';
        width: 4px;
        height: 4px;
        background-color: $border-grey;
        margin: 0 8px;
        border-radius: 50%;
    }
    >span{
        // width: calc(100% - 58px);
        >div:last-child{
            // width: 100%;
            >.profileHeadline{
                @include globalInterFont;
                @include normalText($primary-grey);
                // width: 100%;
                height: 22.5px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
            }
        }
    }
}