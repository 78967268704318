@import '../../../themeColors';
@import '../../../components/globalFontMixins';

.outboxMessageSending{
    display: flex;
    flex-direction: column;
    gap: 8px;
    >div:first-child{
        display: flex;
        align-items: center;
        justify-content: space-between;
        >span{
            display: flex;
            align-items: center;
            gap: 16px;
            >p{
                @include globalInterFont;
                @include smallText;
            }
        }
        >h6{
            @include globalInterFont;
            @include smallText($text-primary-1, $bg-primary-7, 2px 8px);
            border-radius: 6px;
        }
    }

    >div:last-child{
        display: flex;
        align-items: center;
        gap: 12px;
        overflow: auto;
        // scroll-behavior: smooth;
        padding: 12px 8px;
        &::-webkit-scrollbar{
            display: none;
        }
        >.profileSummaryCard{
            background-color: $bg-primary-3;
            border: 1px solid $stroke-primary-3;
            border-radius: 8px;
            padding: 12px 16px;
            width: 320px;
            min-width: 320px;
            gap: 16px;
            height: 124px;
            align-items: flex-start;
        }
        >.outboxCurrentlySendingMessage{
            background-color: $bg-primary-4;
            border: 1px solid $stroke-highlight;
        }
        >.outboxSuccessfullySentMessage{
            background-color: $bg-primary-4;
            border: 1px solid $stroke-positive;
        }
        >.outboxFailedMessage{
            background-color: $bg-primary-4;
            border: 1px solid $stroke-negative;
        }
        >h3{
            @include globalInterFont;
            @include normalText;
        }
    }
}