@import "../../../themeColors";
@import "../../../components/globalFontMixins";
@import "../../../components/globalCSSClasses";

.specialityDetailsJDModal{
  max-width: 70vw;
  >.modal-content{
    position: relative;
    background-color: $bg-primary-2;
    border-radius: 8px;
    border: 1px solid $stroke-primary-2;
  }
  .labelQluButton {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.specialityDetailsController{
   background-color: $bg-primary-2;
   height: 100%;
   padding:  48px 24px 32px 24px;
   display: flex;
   flex-direction: column;
   gap: 42px;
   >.specialityDetailsDisclaimer{
     @include globalInterFont;
     @include normalText($text-primary-2);
   }
   >.specialityDetailsControllerHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        >span{
           display: flex;
           align-items: center;
           gap: 12px;
            >h2{
                @include globalInterFont;
                @include heading3;
            }
       }
   }
   >.specialityDetailsJDModalContainer{
    display: flex;
    justify-content: flex-end;
    >button{
      color: $text-primary-3;
      path{
        stroke: $text-primary-3;
      }
      &:hover{
        color: $text-primary-1;
        path{
          stroke: $text-primary-1;
        }
      }
    }
   }
   >.specialityDetailsControllerBody{
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        align-items: start;
        gap: 32px;
        padding-bottom: 120px;
        >div:first-child{
            >p{
                @include globalInterFont;
                @include normalText($text-primary-3);
            };
        }
        >hr{
            width: 100%;
            background-color: $stroke-secondary-1;
        }
        >h3{
            @include globalRubikFont;
            @include normalText($text-primary-2);
        }
        >span:first-child{
          margin-bottom: -24px;
          border-bottom: none;
          >h5{
            @include globalRubikFont;
            @include normalText($text-primary-3)
          }
          >h5:first-child{
            width: 320px;
          }
        }
        >span{
          display: flex;
          // align-items: center; 
          flex-direction: column;
          gap: 12px;
          >span:nth-of-type(1){
            display: flex;
            align-items: center; 
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 16px;
            >span{
              display: flex;
              align-items: center; 
              gap: 12px;
            }
            >.specialityDetailsExperienceContainer{
              display: flex;
              align-items: center; 
              min-width: 520px;
              min-height: 55px;
            }   
            >input{
                @extend .primaryInput        
            }
          }
          >span:nth-of-type(2){
            display: flex;
            flex-direction: column;
            gap: 8px;
            border-bottom: 1px solid var(--stroke-primary-2);
            padding-bottom: 24px;
            >div:nth-of-type(1){
              display: flex;
              align-items: center; 
              gap: 8px;
              >h6{
                @include globalInterFont;
                @include normalText($text-primary-3);
              }
            }
            >div:nth-of-type(2){
              display: flex;
              align-items: center; 
              flex-wrap: wrap;
              gap: 8px;
            }
          }
        }
    }
  
  > .specialityDetailsControllerFooter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    padding-bottom: 120px;
    > span {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      > input {
        @extend .primaryInput;
      }
    }
  }
}
