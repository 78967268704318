@import '../../themeColors';
@import '../../components/globalFontMixins';

.radioButtonContainer{
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    .radioButton{
        @include backgroundTransition;
        width: 20px;
        height: 20px;
        border: 1px solid $stroke-secondary-1;
        border-radius: 50%;
        box-sizing: border-box;
    
        &Checked{
            border: 4px solid $stroke-highlight;
            background-color: $bg-highlight-secondary;
        }
    }
    h3{
        @include globalRubikFont;
        @include subHeading1($text-primary-2);
        @include backgroundTransition;
    }
    &:hover{
        h3{
            @include subHeading1($text-primary-1);
        }
    }
}