@import '../globalCSSClasses';
@import '../globalFontMixins';
@import '../globalVariable';

.auth_header_root{
    padding: 32px;
    display: flex;
    justify-content: space-between;

    >div:first-child{
        cursor: pointer;
    }

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;

        > span {
            @include globalInterFont;
            @include normalText($primary-grey)
        }
    }
}