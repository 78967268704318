

.profileSpecialtyCardContainer{    
    padding: 64px 16px 32px 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    >div:last-child{
        padding-bottom: 64px;
    }
    .profileSpecialtyCard{
        background-color: transparent;
        border: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        >span{
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            width: 100%;
            padding: 0 0 12px 0;
            >.profileSummaryCard{
                width: unset;
            }
        }
        .profileSpecialtyCardBody{
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 12px;
            padding: 0 0 12px 0;
        }
    }
}