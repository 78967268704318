@import "../globalCSSClasses";
@import "../globalFontMixins";
@import "../globalVariable";

.NewJobSpecialityCard {
  display: flex;
  border-radius: 8px;
  border: 1px solid #e6e9ed;
  position: relative;
  padding: 24px 16px;
  .newJobcardSecondMainDiv{
    display: flex;
    width: 47%;
    align-items: center;
    justify-content: space-between;
  }
  .newJobcardFourthDiv {
    position: absolute;
    right: 16px;
    top: 16px;
  }
  .newJobcardThirdDivBadgeBad {
    p {
      @include globalInterFont;
      @include smallText(white, $primary-red);
      padding: 2px 4px; 
      border-radius: 5px
    }
  }
  .newJobcardThirdDivBadgeGood {
    p {
      @include globalInterFont;
      @include smallText(white, $primary-green);
      padding: 2px 4px; 
      border-radius: 5px
    }
  }
  .newJobcardThirdDiv {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    h2 {
      @include normalText;
      @include globalInterFont;
    }
  }
  .newJobcardSecondDiv {  
      width: 60%;
    .inputAndHeadingCard {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;
      .rangeHeading {
        margin: 0;
      }
    }
    > div {
      display: flex;
      align-items: center;
      h2 {
        @include globalRubikFont;
        @include normalText($primary-grey);
        text-transform: uppercase;
      }
    }
    .inputRangeSpacing {
      padding: 0px;
      margin: 32px 0px;
    }
  }
  .newJobcardFirstDiv {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 12px;
    width: 50%;
    h2 {
      @include globalRubikFont;
      @include normalText($primary-grey);
      text-transform: uppercase;
      width: 90%;
    }
    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 90%;
    }
  }
}
