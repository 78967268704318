@import '../../globalFontMixins';
@import '../../globalVariable';



.idealProfile_TopPanel{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 16px;
    width: 100%;
}

.idealProfile_LeftPanel{
    display: flex;
    align-items: center;
    width: 60%;
}

.idealProfile_DP{
    width: 64px;
    height: 64px;
    border-radius: 50%;
}

.idealProfile_CurrentDetails{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px;
    
    gap: 8px;
    
    
    span{
        @include globalInterFont;
        @include normalText;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        // gap: 8px;
        margin-left: -8px;
    }
    h1{
        @include globalInterFont;
        @include normalText;
        font-weight: 600;
    }
    h5{
        @include globalInterFont;
        @include normalText;
    }
}


.idealProfile_GeneralInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    width: 40%;
}