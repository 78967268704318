@import "../../components/globalVariable";
@import "../../components/globalCSSClasses";
@import "../../themeColors";
@import "../../components/globalFontMixins";

.headerNavbar {
  box-shadow: (0px 2px 3px rgba(0, 0, 0, 0.07));
  padding: 0 !important;
  justify-content: space-between;
  background-color: $bg-primary-3;
  color: $text-primary-1;
  .logoSvg {
    margin-left: 24px;
    margin-right: 24px;
  }
}

.headerLeftInfo {
  @include globalInterFont;
  @include heading2-5;
  display: flex;
  align-items: center;
  padding-top: 24px;
  p {
    margin: 0px;
    text-transform: capitalize;
    color: $text-primary-1;
  }
  .tribeCharacter {
    display: flex;
    align-items: center;
    background-color: $bg-primary-5;
    padding: 10px;
    border-radius: 5px;
    margin-left: 12px;
    margin-right: 8px;
    p {
      @include globalInterFont;
      @include subText;
      margin-right: 4px;
      font-size: 16px;
      font-family: inter;
      //padding-left: 1px;
      color: $text-primary-2;
      background-color: $bg-primary-5;
    }
  }
}

.leftSideHeader {
  display: flex;
  > .leftSideHeaderLogo {
    padding: 24px 18px;
    cursor: pointer;
     > img {
       width: 24px;
     }
  }
  .subDropDownMenuLink {
    @include normalText($fontColor: $primary-grey);
    @include globalRubikFont;
    display: flex;
    gap: 32px;
    margin-top: 14px;
    margin-bottom: 14px;

    div {
      cursor: pointer;
    }
    .active {
      @include normalText();
    }
    .dropdown-menu {
      background-color: $bg-primary-5;
      min-width: 8rem;
    }
    .dropdown-item {
      color: $text-primary;
      background-color: $bg-primary-5;
      padding-left: 5px;
    }
    button {
      background: none;
      padding: 0;
      border: none;

      &:hover {
        color: #ffffff;
      }
      &:hover,
      &:focus {
        background: none;

        border: none;
      }
    }
  }
}

.dropdownMenu {
  margin: 0px;
  border-radius: 8px;
  padding: 0px;
  padding-bottom: 20px;
  background-color: var(--bg-primary-3);
  position: relative;
  padding-top: 8px;
  min-width: 13rem;

  .dropDownItemActive {
    color: #297af7 !important;
  }
  .deleteDotted {
    color: #ef5555 !important;
  }

  .deleteProstects {
    color: #ef5555 !important;
  }

  .dropDownItemDotted {
    @include normalText;
    @include globalInterFont;
    padding: 0px;
    margin: 0px;
    padding-left: 16px;
    padding-top: 12px;
    padding-right: 16px;
    // background-color: #ffffff;
    margin-top: 15px;
    margin: 0;

    &:hover {
      border: none;
    }
    &:focus {
      outline: none;
      border: none;
    }
    &:active:focus {
      outline: none;
      border: none;
    }
  }
  .dropDownItem {
    @include normalText;
    @include globalInterFont;
    padding: 0px;
    margin: 0px;
    padding-left: 16px;
    padding-top: 12px;
    padding-right: 80px;
    background-color: #ffffff;
    margin-top: 15px;
    margin: 0;
    &:hover {
      border: none;
    }
    &:focus {
      outline: none;
      border: none;
    }
    &:active:focus {
      outline: none;
      border: none;
    }
  }
}

.tempDropdown {
  @include smallText;
  @include globalInterFont;
  padding: 0px;
  background: none;
  background-color: #ffffff !important;
  color: $primary-black !important;
  border: none !important;
  transition: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(.dotted) {
    width: 85px;
  }

  &.dotted.btn {
    position: relative;
    left: 62px;
    background: rgba(0, 0, 0, 0) !important;
    width: 20px;
  }

  &:focus {
    outline: none;
    border: none;
  }
  &:active:focus {
    outline: none;
    border: none;
    box-shadow: none !important;
  }
}

.headerRightButtons {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-right: -9px;
  // margin-left: 21rem;
  > button.notificationDropdownButton {
    background: $bg-primary-6;
  }
  div.verticalLine {
    top: 0px;
  }
  & > span {
    height: 24px;
    border-right: 1px solid $border-grey;
    margin-right: 13px;
  }
  > button {
    border-radius: 8px;
    background-color: #f5f6f7;
    display: grid !important;
    place-content: center;
    //margin-right: 12px;
    width: 36px !important;
    height: 36px !important;
    border: none;

    &:hover {
      border: none;
      background-color: #eaf2ff;
    }
    &:focus {
      outline: none;
      border: none;
    }
    &:active:focus {
      outline: none;
      border: none;
      box-shadow: none !important;
    }
  }
}

.notificationIcon {
  display: grid !important;
  place-content: center;
  color: #ffffff;
  font-size: 12px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #ef5555;
  margin-left: 35px;
  margin-top: -5px;
}

.mainDropdown {
  margin: 0 !important;

  .tickButtonLabel {
    background: $bg-primary-5;
    height: 48px;
    width: 48px;
    margin-left: 5px;

    .qluSvgStroke {
      stroke: $bg-positive-primary;
    }
  }

  .crossButtonLabel {
    background: $bg-primary-5;
    height: 48px;
    width: 48px;
    margin-left: 5px;

    .qluSvgStroke {
      stroke: $bg-negative-primary;
    }
  }
}
