@import "../../../components/globalCSSClasses";
@import "../../../components/globalVariable";
@import "../../../components/globalFontMixins";

.profile {
    @include smallText($text-primary-1);

    img {
        margin-right: 12px;
    }

    .linkedInLogo {
        margin-left: 5px;
        margin-bottom: 3px;
        cursor: pointer;
    }

    .paddingBottom {
        padding-bottom: 28px;
    }

    .paddingTop {
        padding-top: 32px;
    }

    .heading {
        @include normalText($text-primary-1);
    }

    .subHeading {
        color: $text-primary-2;
    }

    .content {
        color: $text-primary-1;
        padding-top: 18px;
    }

    .flexDisplay {
        display: flex;
    }

    .marginAuto {
        margin: auto;
    }

    .marginBottom {
        margin-bottom: 8px;
    }

    .tagWidth {
        width: 85px;
    }

    .marginLeft {
        margin-left: 10px;
    }

    .progress {
        width: 100px;
        height: 4px;
        background-color: $bg-primary-6;
    }

    .progress-bar {
        background-color: $bg-highlight-primary;
    }

    .profileBtn {
        width: 185px;
        height: 32px;
        font-size: 14px;
        font-weight: 400;
        padding: 7px 12px;

        path {
            stroke: $text-highlight-primary;
        }
    }

    .profileDotStyle {
        margin: 0 8px;
        color: $bg-primary-5 !important;
    }

    .flexJustifyDisplay {
        display: flex;
        justify-content: space-between;
    }
    .detailsContainer {
        border-bottom: 1px solid $stroke-primary-3;
        padding-bottom: 24px;
        padding-top: 12px;
        padding-right: 30px;
    }

    .detailPanels {
        display: flex;
        flex-direction: column;
        width: 100%;

        .noneFound {
            margin: 0 !important;
        }

        .subHeading {
            .missing {
                color: $primary-red !important;
            }
            .tablets {
                flex-direction: column;
                padding-top: 0.82rem;
                transform: scaleY(1);
                transform-origin: top;
                transition: all 0.26s ease;

                p {
                    margin-bottom: 10px;

                    > span:first-child {
                        background-color: $primary-grey-opaque;
                        padding: 1px 4px 1px 4px;
                        border-radius: 6px;
                    }

                    span.duration {
                        color: $primary-grey !important;
                    }
                }

                .missing {
                    color: $primary-grey !important;
                    > span:first-child {
                        background-color: $primary-red-opaque;
                        color: $primary-red !important;
                    }
                }

                &.show {
                    transform: scaleY(1);
                    max-height: 100vh;
                }

                &.hide {
                    transform: scaleY(0);
                    max-height: 0vh;
                    margin-top: -13px;
                }
            }
        }

        .relevant-summary {
            display: flex;
            min-height: 30px;

            > p:first-child {
                width: 170px;
            }
            .title {
                width: 70%;

                &.noneFound {
                    margin-top: 0 !important;
                    margin-bottom: 0 !important;
                }
            }
        }

        .single-experience {
            display: flex;
            min-height: 43px;

            .tablets {
                flex-wrap: wrap;
                margin-bottom: 1rem;
                p {
                    background-color: $primary-grey-opaque;
                    padding: 1px 4px 1px 4px;
                    border-radius: 6px;
                    margin-left: 0.5rem;
                }
                .missing {
                    background-color: $primary-red-opaque;
                    color: $primary-red;
                }
            }

            .experience-date {
                > p {
                    width: 150px;
                }

                .duration {
                    @include subHeading1;
                    margin-bottom: 0.95rem;
                }
            }

            .experience-info {
                border-bottom: 1px solid $stroke-primary-3;
                width: 100%;
                &.noBorder {
                    border-bottom: 0px solid $stroke-primary-3 !important;
                }

                .summary {
                    white-space: pre-line;
                    text-align: left;
                }

                .title {
                    min-height: 30px;
                }

                .title .years {
                    color: $primary-grey;
                }

                .duration {
                    font-weight: 300;
                    color: $primary-grey;
                }

                .title_exp {
                    margin-top: 0.95rem;
                    font-weight: 500;
                }

                .title_location {
                    margin-top: 0.5rem;
                    font-weight: 300;
                    color: $primary-grey;
                }
            }

            .experience-skills {
                display: flex;
                padding-top: 0.8rem;
                min-height: 30px;
                > p {
                    color: $primary-grey;
                }
                div {
                    display: flex;
                }
            }

            .experience-specialities {
                display: flex;
                margin-top: -0.7rem;
                min-height: 30px;
                > p {
                    color: $primary-grey;
                }
                div {
                    display: flex;
                }
            }
        }

        .relevant-summary, .single-experience {
            .titleTablets {
                color: $text-highlight-primary;
                background-color: $bg-highlight-secondary;
                padding: 1px 4px 1px 4px;
                border-radius: 6px;
            }
        }

        .specialitiesPanel {
            min-height: 80px;

            > .tablets {
                flex-direction: column;
                padding-top: 0.82rem;
                
                p {
                    > span:first-child {
                        background-color: $primary-grey-opaque;
                        padding: 1px 4px 1px 4px;
                        border-radius: 6px;
                        margin-left: -0.2rem;
                    }

                    span.duration {
                        color: $primary-grey !important;
                    }
                }

                .missing {
                    color: $primary-grey !important;
                    > span:first-child {
                        background-color: $primary-red-opaque;
                        color: $primary-red !important;
                    }
                }
            }
        }

        .btn:focus {
            box-shadow: none;
        }
    }
}
