@import '../../../themeColors';
@import '../../../components/globalFontMixins';

.recipientsTabsContainer{
    width: 30%;
    display: flex;
    flex-direction: column;
    background: $bg-primary-2;
    border: 1px solid $stroke-primary-2;
    border-radius: 8px;
    >div{
        border-left: 4px solid transparent;
        box-sizing: border-box;
        @include backgroundTransition;
    }
    .selectedTab{
        border-color: $stroke-highlight;
    }
    .allRecipientsTab{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 24px;
        border-radius: 8px 8px 0 0;
        >span{
            display: flex;
            align-items: center;
            gap: 12px;
            >span{
                width: 46px;
                display: grid;
                place-content: center;
            }
            >p{
                @include globalInterFont;
                @include subHeading1($text-highlight-primary);
            }
        }
        >p{
            @include globalInterFont;
            @include normalText($text-secondary-1, $bg-highlight-primary, 2px 8px);
            border-radius: 6px;
        }
    }
    .recipientTab{
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 20px 24px;
        &:last-child{

            border-radius: 0 0 8px 8px;
        }
        >div:last-child{
            padding-left: 58px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;
        }
    }
    >div:hover{
        cursor: pointer;
        background: $bg-primary-3;
    }
}