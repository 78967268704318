@import '../../../../themeColors';
@import '../../../../components/globalFontMixins';

.profileScoringContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding: 24px 20px;
    >span{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        >p{
            @include normalText;
            width: 31%;
        }
        >.progress{
            height: 4px;
            border-radius: 4px;
            width: calc(69% - 12px);
            background-color: $bg-primary-5;
            >.progress-bar{
                background-color: $bg-highlight-primary;
            }
        }
    }
}                        