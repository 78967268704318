
@import "../../../themeColors";
.jobActionHeader{
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    > p {
        font-size: 20px;
        color: $text-primary-1;
        font-weight: 500;
    }

    > div {
        display: flex;
        gap: 24px;

        > input {
            background-color: $bg-primary-2;
            border-radius: 8px;
            border: none;
            outline: none;
            color: $text-primary-1;
            padding: 8px 12px;
        }
    }
}

.sectionPagination {
    list-style-type: none;
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 0;

    > li:first-child, > li:last-child {
        > a {
            text-decoration: none;
            font-size: 18px;
            color: $primary-1;
        }
    }


    > li {
        color: $text-primary-2;

        >  a {
            background-color: $bg-primary-2;
            :hover {
                background-color: $bg-primary-3;
            }
            padding: 6px 12px;
            border-radius: 8px;
        }
        //  > a:hover{
        //      background-color: $bg-primary-3;
        //  }
    }

    > li.selected {
        color: $primary-1;
    }

    > li.disabled {
        opacity: 0.5;
    }
}