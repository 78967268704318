@import '../../themeColors';
@import '../../components/globalFontMixins';

.socialHeartButton {
    @include backgroundTransition;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    background-color: $bg-primary-5;
    border: 1px solid $stroke-primary-6;
    outline: none;
    border-radius: 45px;

    &:disabled {
        opacity: 0.7;
    }

    &:not(:disabled):hover {
        background-color: $bg-primary-6;
    }

    >svg>path {
        stroke: $stroke-heart;
    }

    &-activate {
        border: 1px solid $stroke-heart;
        background-color: $bg-heart-secondary;

        &:not(:disabled):hover {
            background-color: $bg-heart-secondary;
        }

        >svg>path {
            fill: $bg-heart-primary;
        }
    }
}