@import "../../../components/globalCSSClasses";
@import "../../../components/globalVariable";
@import "../../../components/globalFontMixins";

.eventDetailsContainer {
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .eventDetailsHeader {
        display: flex;
        justify-content: space-between;
        padding: 16px 4px;
        border-bottom: 1px solid $bg-primary-3;
    }

    .btnsContainer {
        display: flex;
    }

    .heading1 {
        @include normalText($text-primary-1);
    }

    .heading2 {
        @include smallText($text-primary-2);
    }

    .meetingButton {
        width: 65px;
        height: 36px;
        font-size: 14px;
        font-weight: 400;
    }

    .iconButton {
        width: 24px;
        height: 33px;
        background-color: transparent;
        border: none;
    }

    .tabsContainer {
        padding-top: 12px;
    }

    .react-tabs__tab {
        @include smallText($text-primary-1);
        border: unset !important;
        margin-right: 23px;
    }

    .react-tabs__tab-list {
        border-bottom: 0px !important;
    }

    .react-tabs__tab:focus {
        outline: none;
        box-shadow: none !important;
    }

    .react-tabs__tab:focus:after {
        height: 0px !important;
    }

    .react-tabs__tab--selected {
        border-bottom: 2px solid $bg-highlight-primary !important;
    }

    .dropdown-item:hover, .dropdown-item:focus {
        background-color: $bg-primary-7;
    }

    .dropdown-item {
        color: $text-primary-1 !important;
    }

    .dropdown-menu {
        background-color: $bg-primary-5;
    }
}
