@import "../../../themeColors";

.ScoringFiltersSliderModuleHeading{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: $text-primary-1;
    margin: 0px auto 16px 16px;
}

.scoringFilterSection{    
    margin: 64px 0px auto auto;
    max-width: 600px;
}
.scoringFilterSection > div > div{
    padding: 0px;
}

.sliderFilterContainer {
   
}
