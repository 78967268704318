@import '../../../themeColors';
@import '../../../components/globalFontMixins';
.outboxHeader{
    background: $bg-primary-2;
    border-bottom: 1px solid $stroke-primary-2;
    display: flex;
    align-content: center;
    justify-content: center;
    z-index: 10;
    >div{
        display: flex;
        align-items: center;
        padding: 20px 32px;
        gap: 24px;
        >span{
            display: flex;
            align-items: center;
            gap: 12px;
            >h2{
                @include globalInterFont;
                @include heading3;
            }
        }
    }
}