@import '../../../themeColors';
@import '../../../components/globalFontMixins';
.generatedCard{
    margin: 20vh 10vw;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 8px;
    background-color: $bg-primary-3;
    border: 1px solid $stroke-primary-4;
    >div:first-child{
        display: flex;
        align-items: center;
        gap: 12px;
        >h3{
            @include globalInterFont;
            @include heading2;
        }
    }
    >p{
        @include globalInterFont;
        @include normalText($text-primary-2);
    }
}