@import '../globalFontMixins';
@import '../globalVariable';



.primaryDropdownContainer{
    min-width: 90%;
    margin: 12px 0;
    .primaryDropdownToggle_Container{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        border-radius: 8px;
        border: none;
        background: $secondary-grey;
        h1{
            @include globalInterFont;
            @include normalText();
        }
        &:active{
            background: $border-grey !important; 
            box-shadow: none !important;
        }
        &:hover{
            background: $border-grey;
            outline: none;
            border: none;
        }
        &:focus{
            background: $border-grey;
            // box-shadow: none;
        }
    
    }
}

.primaryDropdownItem_Container{
    @include globalInterFont;
    @include normalText($primary-black, $padding:8px 16px);
    border-radius: 0;
    border: none !important;
    &:hover, 
    &:active{
        background-color: $secondary-grey;
        color: $primary-black;
    }

    &:focus{
        outline: none;
        background-color: $secondary-grey;
    }
}

.primaryDropdownMenu_Container{
    width: 100%;
}