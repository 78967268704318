.setupLayoutContainer {
  display: flex;
  align-items: flex-start;
  > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: calc(100vh);
    > div:last-child {
      width: 100%;
      // height: 0;
      position: fixed;
      bottom: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5;
    }
  }
}
