@import "../../../themeColors";
@import "../../../components/globalFontMixins";
@import "../../../components/globalFontMixins";
@import "../../../components/globalCSSClasses";
@import "../../../components/globalVariable";
.set-batch-size {
  @include globalInterFont;
  color: $text-primary-1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 92px;
  text-align: center;

  .title {
    color: $text-primary;
    font-weight: 600;
    font-size: 24px;
    padding: 20px;
    width: 702px;
    margin-bottom: 171px;
  }
  // .sub-title {
  //   margin-bottom: 27px;
  // }
  // .subFooter {
  //   margin-top: 27px;
  // }
  .content {
    display: flex;
    gap: 16px;
    padding-block: 27px;
    .step {
      width: 88px;
      height: 56px;
      border-radius: 70px;
      background-color: $bg-primary-4;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.active {
        background-color: $primary-1;
        color: $bg-primary-1;
      }
    }
  }
}
