@import "../../../themeColors";
@import '../../../components/globalFontMixins';
@import '../../../components/globalCSSClasses';

.notificationDropdownContainer {
    margin-left: 16px;
    .verticalLine {
        height: 40px;
        top: 5px;
        width: 1px;
        background-color: $stroke-primary-4;
        position: absolute;
    }
    .notificationDropdownButton {
        @extend .secondaryGreyButton;
        width: 47px;
        height: 47px;
        background: $bg-primary-2;
        margin-left: 16px;
        
        &:active, &:focus, &:hover {
            background-color: $bg-primary-3;
        }
    }
    .notificationDropdown {
        left: -337px;
        display: flex;
        width: 400px;
        background: $bg-primary-3;
        border-radius: 8px;
        z-index: 200;
        display: flex;
        flex-direction: column;
        max-height: 600px;
        overflow-y: auto;
        border: 1px solid $stroke-primary-3;
        &.hide {
            height: 0;
            visibility: hidden;
            div, p {
                height: 0;
                visibility: hidden;
            }
        }
        &.show {
            height: auto;
        }

        .showMore {
            display: flex;
            padding: 10px;
            justify-content: center;
            color: $text-primary-2;
            &:hover {
                background: $bg-primary-4;
                color: $text-primary-1;
                cursor: pointer;
            }
            &.disabled {
                cursor: not-allowed;
            }
        }

        .notification-item {
            background: $bg-primary-3;
            border-radius: 0;
            border-bottom: 1px solid $stroke-primary-5;
            
            &:hover {
                background: $bg-primary-4;
                cursor: pointer;
            }
        }
    }
}