@import '../../themeColors';
@import '../../components/globalFontMixins';
.reactSwitchContainer{
    cursor: pointer;
    display: flex;
    align-items: center;
    >.react-switch{
        >.react-switch-bg{
            background-color: $bg-primary-7 !important;
            @include backgroundTransition;
        }
        >.react-switch-handle{
            box-shadow: none !important;
            background-color: $stroke-secondary-2 !important;
            @include backgroundTransition;
        }   
    }
    &Active{
        cursor: pointer;
        display: flex;
        align-items: center;
        >.react-switch{
            >.react-switch-bg{
                background-color: $bg-highlight-secondary !important;
                @include backgroundTransition;
            }
            >.react-switch-handle{
                box-shadow: none !important;
                background-color: $bg-highlight-primary !important;
                @include backgroundTransition;
            }   
        }
    }

}