@import "../../themeColors";
@import "../../components/globalFontMixins";
.optionsDropdown {
  > button {
    @include globalInterFont;
    @include normalText;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 50%;
    padding: 0;
  }
  .dropdown-menu {
    @include globalInterFont;
    @include normalText;
    background-color: $bg-primary-3;
    border-color: $stroke-primary-3;
    margin-top: 8px;
    padding: 12px;
    width: 480px;
    border-radius: 8px;
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
  }
}
