@import '../../themeColors';
@import '../../components/globalFontMixins';

.labelQluButton{
    @include globalInterFont;
    @include subHeading1($text-primary-3, transparent, 8px 16px);
    @include backgroundTransition;
    display: flex;
    align-items: center;
    gap: 8px;
    outline: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    path{
        stroke: $text-primary-3
    }
    &:hover{
        @include subHeading1($text-primary-2, transparent, 8px 16px);
        path{
            stroke: $text-primary-2
        }
    }
    &:disabled{
        @include subHeading1($text-primary-3, transparent, 8px 16px);
        cursor: not-allowed;
        path{
            stroke: $text-primary-3
        }
    }
}