
@import "../globalFontMixins";
@import "../globalCSSClasses";
@import '../../themeColors';

.TribeCardContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: $bg-primary-3;
  border: 1px solid $stroke-primary-3;
  border-radius: 8px;
  box-sizing: border-box;
  width: calc(50% - 12px);
  height: 100%;
  padding: 24px 12px;
  > .TribeCardHeader{
    display: flex;
    justify-content: space-between;
    gap: 12px;
    > span{
      display: flex;
      flex-direction: column;
      gap: 4px;
      overflow: hidden;
      > h3{
        @include globalInterFont;
        @include subHeading1;
      }
      > .tribeStatusInvited {
        @include globalInterFont;
        @include normalText($text-highlight-primary, $bg-highlight-secondary);  
        padding: 4px 8px;
        border-radius: 6px;
        width: fit-content;
      }
      > p{
        @include globalInterFont;
        @include normalText($text-primary-2);
      }
    }
    > .tribeAdded{
      background-color: $bg-positive-secondary;
      color: $text-positive-primary;   
      &:hover,
      &:active{
        border-color: $stroke-positive;
      }
    }
  }
  > .tribePermissionContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
    > h4 {
      @include globalRubikFont;
      @include normalText($text-primary-2);
    }
    .setTribeAttributes{
      display: flex;
      align-items: center;
      justify-content: space-between;
      h5{
        @include globalInterFont;
        @include normalText;
      }
    }
  }
}
