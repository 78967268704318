@import '../../components/globalCSSClasses';
@import '../../components/globalFontMixins';
@import '../../components/globalVariable';
@import '../../themeColors';


.outboxButtonToggle{
    @extend .secondaryButton;
    width: 40px;
    height: 40px;
}
.outboxMessagingButtonMenu{
    background-color: $bg-primary-3;
    border: 1px solid $stroke-primary-3;
    box-sizing: border-box;
    box-shadow: 0 8px 16px 8px rgba( #000000, 0.12);
    border-radius: 8px;
    padding: 32px 16px 16px 16px;
    min-width: 350px;
    z-index: 120;
    .linkedinLoggedInContainer{
        display: flex;
        flex-direction: column;
        gap: 12px;
        >h4{
            @include globalInterFont;
            @include subHeading1;
        }
        >a{ 
            @include globalInterFont;
            @include subHeading1($text-secondary-1, $bg-highlight-primary, 11px 32px);
            @include backgroundTransition;
            display: flex;
            align-items: center;
            gap: 12px;
            border-radius: 8px;
            outline: none;
            border: none;
            box-shadow: none;
            cursor: pointer;
            &:hover{
                @include subHeading1($text-secondary-1, $bg-highlight-hover, 11px 32px);
            }
            &:disabled{
                @include subHeading1($text-secondary-1, $bg-highlight-disabled, 11px 32px);
                cursor: not-allowed;
            }
            text-decoration: none;
        }
    }
    .extensionInstallion{
        display: flex;
        flex-direction: column;
        // align-items: flex-start;
        // justify-content: flex-start;
        gap: 12px;
        .gbt_root{
            margin: 0;
            width: 100% !important;
            border-radius: 8px
        }
        >p{
            @include globalInterFont;
            @include normalText;
        }
        >h4{
            @include globalInterFont;
            @include subHeading1;
        }
    }
    >div{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .draftSelectionContainer{
            display: flex;
            flex-direction: column;
            gap: 12px;
           input{
                @extend .primaryInput;
           }
        }
        .shareWithTribeContainer{
            display: flex;
            flex-direction: column;
            gap: 12px;
            >span{
                display: flex;
                align-items: center;
                justify-content: space-between;
                p{
                    @include globalInterFont;
                    @include normalText;
                }
            }
            h4{
                @include globalInterFont;
                @include normalText($text-primary-2);
            }
        }
        >div:last-child{
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}
