@import "../../themeColors";
@import "../../components/globalFontMixins";
@import "../../components/globalFontMixins";
@import "../../components/globalCSSClasses";
@import "../../components/globalVariable";

.numbers {
  display: flex;
  align-items: center;
  padding-top: 10px;
  user-select: none;

  .number {
    @include globalInterFont();
    color: $text-primary-3;
    padding: 0px 8px;
    border-radius: 8px;
    transition: 0.3s all ease;

    cursor: pointer;
  }

  .active {
    color: $text-secondary-1;
    background-color: $text-highlight-primary;
  }

  .leftPaginate,
  .rightPaginate {
    cursor: pointer;
    display: none;
    opacity: 0;
    transition: 0.3s opacity ease-in-out, display 1s ease-in-out;

    &:hover {
      * {
        stroke: $text-highlight-primary;
      }
    }

    &.show {
      opacity: 1;
      display: block;
    }
  }

  .leftPaginate {
    margin-right: 5px;
    transform: rotate(90deg);
  }

  .rightPaginate {
    margin-left: 5px;
    transform: rotate(-90deg);
  }
}