@import "../../components/globalCSSClasses";
@import "../../components/globalVariable";
@import "../../components/globalFontMixins";

.calendarComponent {
    height: 88vh;
    overflow: hidden;
    margin-block-start: 16px;
}

.calendarView {
    padding: 24px 16px 12px 16px;
    border-right: 1px solid $stroke-primary-3;
    background-color: $bg-primary-2;

    .collapseButton {
        background-color: transparent;
        border: none !important;
        margin-top: -5px;

        :focus {
            border: none !important;
            box-shadow: none !important;
        }
    }

    .fc-toolbar-title {
        @include subHeading1($fontWeight: 400);
    }

    .fc-prev-button,
    .fc-next-button {
        color: $stroke-secondary-1 !important;
        background-color: $bg-primary-2 !important;
        height: 20px !important;
        margin: auto 2px !important;
        border: none !important;
        padding: 0 !important;
    }

    .fc-icon-chevron-left,
    .fc-icon-chevron-right {
        width: 12px !important;
        height: 6px !important;
        line-height: 0px !important;
        font-size: 20px !important;
    }

    .fc-button-primary:focus {
        border: none !important;
        box-shadow: none !important;
    }

    td,
    th,
    table {
        border: none !important;
        background-color: $bg-primary-2;
    }

    th a.fc-col-header-cell-cushion {
        @include subText($text-primary-3, $important: true);
        font-weight: 400;
        line-height: 14.52px;
        cursor: context-menu;
        text-decoration: none;
    }

    .fc-day-past a,
    .fc-day-future a,
    .fc .fc-daygrid-day-top {
        @include subText($text-primary-2, $important: true);
        font-weight: 400;
        cursor: context-menu;
        text-decoration: none;
        text-align: center;
        line-height: 24px;
        cursor: pointer;
        width: 22px;
        display: block;
        padding-right: 3px;
    }

    .fc-day-today .fc-daygrid-day-number {
        @include subText($text-primary-2, $important: true);
        color: $text-highlight-primary !important;
        text-decoration: none;
        padding-left: 3px;
    }

    .divider {
        border-bottom: 1px solid $stroke-primary-3;
        width: calc(100% + 32px);
        margin-left: -16px;
        margin-top: 10px;
    }

    .fc .fc-daygrid-day-bg .fc-highlight {
        border-radius: 50%;
        color: $text-secondary-1;
        background-color: $bg-highlight-primary 0.3;
    }

    .fc-day-today .fc-daygrid-day-frame {
        border-radius: 50%;
        background: $bg-highlight-secondary;
        height: 24px;
        width: 24px;
    }

    .fc-scrollgrid-sync-inner {
        height: 24px;
        width: 24px;
    }

    .fc-daygrid-day.fc-day-today {
        background: none !important;
    }

    .fc-toolbar-chunk {
        margin-right: 5%;
    }

    .highlight-date .fc-daygrid-day-frame {
        background-color: $bg-highlight-primary !important;
        border-radius: 50%;

        a {
            color: $text-secondary-1 !important;
        }
    }

    .allEvents {
        margin-top: 8px;
    }

    .form-check-input {
        background-color: transparent;
        border: 1px solid $stroke-secondary-1;
        margin-right: 8px;
        margin-bottom: 14px;
    }

    .form-check-input:checked[type="checkbox"] {
        background-color: $text-highlight-primary !important;
    }

    input[type="checkbox" i]:focus-visible {
        outline: 0;
    }

    .form-check-input:focus {
        box-shadow: none;
    }

    .allCalendarsLabel {
        margin-right: 12px !important;
    }

    .allCalendarsLabel,
    .calendar {
        @include smallText($text-primary-1);
    }

    .calendarBar {
        width: 4px;
        height: 16px;
        margin-top: 4px;
    }

    .google {
        background-color: #7440f5;
    }

    .outlook {
        background-color: #63b780;
    }

    .availabilityRow {
        display: flex;
        justify-content: space-between;
    }

    th[role="presentation"] {
        background: none !important;
    }
}

.eventView {
    padding: 24px 4px;
    position: relative;
    border-right: 1px solid var(--stroke-primary-3);

    .overviewDotStyle {
        margin: 0 8px;
        color: $bg-primary-5 !important;
    }

    td[role="presentation"],
    th[role="presentation"] {
        border: 0 !important;
    }

    td .fc-daygrid-day {
        border-color: var(--stroke-primary-3);
    }

    th[role="columnheader"],
    .fc-timegrid-axis {
        border: 1px solid var(--stroke-primary-3);
    }

    th a.fc-col-header-cell-cushion,
    .fc-day-past a.fc-daygrid-day-number,
    .fc-day-future a.fc-daygrid-day-number {
        @include subText($text-primary-3);
        font-weight: 400;
        cursor: context-menu;
        text-decoration: none;
        text-transform: uppercase;
    }

    .fc .fc-daygrid-day-top {
        flex-direction: row;
    }

    .fc-day-past .fc-scrollgrid-sync-inner,
    .fc-day-future .fc-scrollgrid-sync-inner,
    .fc-day-today .fc-scrollgrid-sync-inner {
        @include normalText($text-primary-3, $padding: 8px);
    }

    .fc-day-today .fc-daygrid-day-frame {
        cursor: context-menu;
        padding-top: 0;
        border-top: 4px solid $bg-highlight-primary;
    }

    .fc-day-today .fc-daygrid-day-number {
        @include normalText($bg-highlight-primary, $bg-highlight-secondary);
        text-decoration: none;
        font-weight: 600;
    }

    .fc .fc-daygrid-day.fc-day-today,
    .fc .fc-timegrid-col.fc-day-today {
        background-color: $bg-highlight-secondary;
    }

    .fc-timeGridDay-view .fc-day-today.fc-day-today {
        background-color: transparent;
    }

    th a.fc-col-header-cell-cushion {
        margin: 11px 0;
    }

    .fc .fc-toolbar.fc-header-toolbar {
        margin-bottom: 0;
    }

    .fc-toolbar-title {
        @include heading3;
        font-size: 20px;
    }

    .fc-header-toolbar {
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $bg-primary-2;
    }

    .fc-media-screen .fc-timegrid-cols>table {
        height: 100%;
    }

    .fc-scrollgrid {
        border: none !important;
    }

    td.fc-timegrid-slot-lane {
        border-top: 1px solid $stroke-primary-4 !important;
        border: 0;
        height: 47px;
    }

    .eventView .fc-timegrid-body tr:last-child td.fc-timegrid-slot-lane {
        border-bottom: 1px solid $stroke-primary-4 !important;
    }

    .fc .fc-timegrid-slot-label {
        border: 0 !important;
    }

    // .fc .fc-timegrid-axis-cushion,
    .fc-timegrid-slot-label-cushion {
        @include subText($text-primary-3, $important: true);
        font-weight: 400;
        padding: 0px 12px 0px 0px !important;
    }

    .fc-timegrid-now-indicator-container {
        overflow: unset !important;
    }

    .fc-timegrid-now-indicator-arrow {
        display: none !important;
    }

    .fc-timegrid-now-indicator-line {
        border-color: #ffc321 !important;
    }

    .fc-day-today .fc-scrollgrid-sync-inner {
        border-top: 4px solid $stroke-highlight;
    }

    .prevButton,
    .nextButton {
        color: $stroke-secondary-1 !important;
        background-color: $bg-primary-1 !important;
        height: 25px !important;
        border: none !important;
        position: absolute;
        top: 28px;
    }

    .prevButton {
        left: 270px;
        padding-left: 7px;

        :focus {
            border: none !important;
            box-shadow: none !important;
        }
    }

    .nextButton {
        left: 288px;
    }

    .additionalButtons {
        display: inline-flex;
        position: absolute;
        right: 19px;
        height: 36px;
    }

    .settingsButton {
        margin-left: 12px;
        width: 36px;
        height: 36px;
        background-color: $bg-primary-5;
    }

    .todayButton {
        width: 68px;
        height: 36px;
        font-size: 12px;
        font-weight: 400;
    }

    .newMeetingButton {
        width: 110px;
        height: 36px;
        font-size: 12px;
        font-weight: 400;
        margin-left: 12px;
    }

    .verticalDivider {
        margin-left: 12px;
        margin-top: 6px;
        height: 36px;
        border-left: 1px solid $bg-primary-6;
    }

    .viewButton {
        button {
            min-height: 36px;
            width: 85px;
            min-width: 85px;
            font-size: 12px;
            font-weight: 400;
        }

        .dropdown-item {
            font-size: 12px;
            font-weight: 400;
        }

        .dropdown {
            margin-left: 12px;
            height: 36px;
            width: 85px;
        }

        .dropdown-menu {
            min-width: 85px;
        }
    }

    .fc-theme-standard td,
    .fc-theme-standard th {
        border: 1px solid $bg-primary-2;
    }

    thead .fc-scroller {
        overflow: hidden !important;
    }

    // .fc-daygrid-event, .fc-event-time {
    //     width: 91px;
    // }

    .fc-timegrid-event {
        background-color: transparent;
        box-shadow: none !important;
        // height: 48px;
        border: 0;
    }

    .month,
    .week {
        @include subText($text-primary-1, $padding: 4px);
        font-weight: normal;
        background: #242424;
        box-sizing: border-box;
        border: 1px solid $stroke-primary-3;
        border-radius: 8px;
        // width: 91px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }

    .month div,
    .week div:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }

    .week {
        // width: 80px;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
    }

    .day {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        background: #242424;
        border: 1px solid $stroke-primary-3;
        border-radius: 8px;
        cursor: pointer;
        padding: 15px 17px;

        svg {
            margin-right: 8px;
        }
    }

    .day:hover,
    .week:hover,
    .month:hover,
    .day.selected,
    .week.selected,
    .month.selected {
        border-color: $stroke-highlight;
        border-left: 4px solid $stroke-highlight;
    }

    .fc-event-time {
        @include subText($text-primary-1);
        margin-right: 0 !important;
        font-weight: normal !important;
        border: 0;
    }

    .fc-event-title {
        @include subText($text-primary-1, $padding: 4px, $important: true);
        font-weight: normal !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    span.textDivider {
        padding: 0 22px;
    }

    .settingsLogo {
        width: 22px;
        height: 22px;
        margin-left: -10px;
    }

    .calendarBar {
        width: 4px;
        height: 16px;
        margin-left: 16px;
    }

    .google {
        background-color: #7440f5;
    }

    .outlook {
        background-color: #63b780;
    }

    .flexDisplay {
        display: flex;
        justify-content: space-between;
    }

    .profileDisplayImage {
        margin-right: 4px;
    }

    .Toastify__toast-body div {
        @include smallText($text-secondary-1);
    }
}

.contentContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 74px;
}

.eventDetails {
    height: 90vh;
    overflow-y: auto;

    .eventHeading {
        @include subHeading1;
        font-size: 20px;
        padding: 26px 16px;
        border-bottom: 1px solid $bg-primary-2;
    }

    .meetingCount {
        @include smallText();
    }

    .clickEvent {
        @include smallText($text-primary-3);
    }
}

.loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 32px;
        height: 32px;

        path {
            stroke: $text-highlight-primary;
        }
    }
}

.Toastify__close-button {
    margin-top: 8px !important;
}