@import "../../../components/globalCSSClasses";
@import "../../../components/globalFontMixins";
@import "../../../components/globalVariable";
@import "../../../themeColors";

@keyframes slideInLeftSmall {
  from {
    opacity: 0;
    transform: translate3d(2rem, 0rem, 500rem);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.tribesHeader {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 1.5vw);
  .left {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .mainHeading {
    color: $text-primary-1;
    font-size: 24px;
    font-weight: 600;
  }
  .inviteBtn {
    color: $primary-1;
    background-color: $primary-4;
    border-radius: 8px;
    border: none;
    padding: 11px 12px;
  }
  .tag {
    background-color: $bg-primary-6;
    color: $text-secondary;
    padding: 1px 4px;
    font-size: 14px;
    border-radius: 6px;
  }
  .suggestionsTribes {
    display: flex;

    div > input {
      @extend .primaryInput;
      margin-right: 10px;
      animation: slideInLeftSmall 0.5s cubic-bezier(0.165, 0.84, 0.44, 1)
        forwards;
    }

    .renderedSuggestionContainer {
      font-family: Inter;
      color: var(--text-primary-1);
      background-color: transparent;
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 146.02%;
      padding: 8px 12px;
      word-wrap: break-word;
      cursor: pointer;
      &:hover {
        background-color: $bg-primary-7;
      }
    }

    .autosuggest-suggestions-container {
      width: 322px;
      background-color: var(--bg-primary-6);
      // box-shadow: 0 2px 7px rgb(0 0 0 / 8%);
      margin-top: 8px;
      border-radius: 8px;
      max-height: 252px;
      overflow-y: auto;
      position: absolute;
      z-index: 1000;
      top: 48px;
      margin-top: 5px;
      // background: $bg-primary-5;
      // border-radius: 8px;
      // position: absolute;
      // z-index: 1000;
      // color: $text-primary-1;
      // padding-right: 10px;
      ul {
        padding-left: 1rem;
        margin: 0;
        > li {
          list-style: none;
        }
      }
    }
    .newAutoSuggestionContainerB {
      width: 322px;
      background-color: $bg-primary-6;
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.08);
      margin-top: 8px;
      border-radius: 8px;
      max-height: 252px;
      overflow-y: auto;
      position: absolute;
      // word-break: break-all;
      z-index: 1000;
      // top: 48px;
      ul {
        display: flex;
        flex-direction: column;
        // gap: 8px;
        padding: 0;
        margin: 0;
        width: 100%;
        list-style: none;

        li:first-child:hover {
          div {
            border-radius: 8px 8px 0 0;
          }
        }
        li:last-child:hover {
          div {
            border-radius: 0 0 8px 8px;
          }
        }
        li:only-child:hover {
          div {
            border-radius: 8px;
          }
        }
        li {
          .tribeMembersNewJob {
            padding: 0px;
          }
          p {
            margin: 0px;
          }
        }
      }
    }
    .newAutoSuggestion {
      > div {
        @include globalInterFont;
        @include normalText;
        padding: 8px 12px;
        cursor: pointer;
      }
      > div:hover {
        background-color: $bg-primary-7;
      }
    }
  }
}
