@import './global.scss';
.optoutContainer{
    background-color: $bg-primary-1;
    min-height: 100vh;
    position: relative;
    scroll-behavior: smooth;
    scroll-snap-type: block;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // gap: min(360px, 25vw);
}

.optoutSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    justify-content: center;
    padding: 24px;
    width: calc(min(784px, 80%));
    background-color: $bg-primary-2;
    border: 1px solid $stroke-primary-2;
    border-radius: 8px;
    margin: 164px 0;
    >h3, >p{
        @include bodyText1;
    }
    >h3{
        font-size: 18px;
    }
    >textarea{
        @include bodyText1;
        @include backgroundTransition;
        background-color: $bg-primary-4;
        border: 1px solid $stroke-primary-4;
        outline: none;
        border-radius: 8px;
        padding: 12px 16px;
        &:focus{
            background-color: $bg-primary-5;
            border: 1px solid $stroke-primary-5;
        }
        &:disabled{
            background-color: $bg-primary-1;
            border: 1px solid $stroke-primary-1;
        }
    }
}

.optoutOptions{
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    >button{
        @include bodyText1;
        outline: none;
        background-color: $bg-primary-3;
        border: 1px solid $stroke-primary-3;
        border-radius: 8px;
        width: 33%;
        min-height: 128px;
        text-align: center;
        padding: 12px 16px;
        cursor: pointer;
        transition: background-color 300ms, border 300ms;
        &:hover{
            background-color: $bg-primary-4;
            border: 1px solid $stroke-primary-4;
        }
    }
}

.requestInput{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 100%;
    >div{
        width: 100%;
        >h4{
            @include bodyText1;
            font-weight: 600;  
            margin-bottom: 8px;          
        }
        >textarea{
            @include bodyText1;
            @include backgroundTransition;
            background-color: $bg-primary-4;
            border: 1px solid $stroke-primary-4;
            outline: none;
            border-radius: 8px;
            padding: 12px 16px;
            &:focus{
                background-color: $bg-primary-5;
                border: 1px solid $stroke-primary-5;
            }
            &:disabled{
                background-color: $bg-primary-1;
                border: 1px solid $stroke-primary-1;
            }
        }
    }
    >div:last-child{
        display: flex;
        align-items: center;
        justify-content: space-between;
        >button:last-child{
            @include bodyText1($text-secondary-1, $bg-highlight-primary);
            @include backgroundTransition;
            outline: none;
            border: none;
            padding: 8px 16px;
            border-radius: 8px;
            cursor: pointer;
            &:hover{
                background-color: $bg-highlight-hover;
            }
            &:disabled{
                background-color: $bg-highlight-disabled;
            }
        }
        button:first-child{
            @include bodyText1($text-primary-1, transparent);
            @include backgroundTransition;
            outline: none;
            border: none;
            padding: 8px 16px;
            border-radius: 8px;
            cursor: pointer;
            &:hover{
                color: $text-primary-2;
            }
            &:disabled{
                color: $text-primary-3;
            }
}

    }
}