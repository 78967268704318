@import '../../themeColors';
@import '../../components/globalFontMixins';

.numberInput{
    @include globalInterFont;
    @include normalText;
    padding: 12px 16px;
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 8px;
    background-color: $bg-primary-5;
    width: 90px;
    &:hover,
    &:focus,
    &:active{
        @include normalText;
        padding: 12px 16px;
        border: none;
        outline: none;
        box-shadow: none;
        background-color: $bg-primary-6;
    }
}