@import "../../../themeColors";

.landinghome {
  display: flex;
}

.landinghome-content {
  width: 70%;
  border-right: 1px solid $stroke-primary-2;
  padding: 24px;
}

.landinghome-notif {
  width: 30%;
  padding: 24px;
}

.charts-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;

  > * {
    margin-right: 24px;
  }
}

.section-container {
  width: 100%;
  margin-top: 30px;
}

.jobItemsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  gap: 16px;
}

.campaignTable {
  width: 100%;
  margin-top: 16px;
  > thead {
    > tr {
      > th {
        font-size: 14px;
        color: $text-primary-3;
        text-transform: uppercase;
        font-weight: normal;
        padding-left: 24px;
      }
    }
  }

  > tbody {
    border-radius: 8px;
    overflow: hidden;
    > tr {
      background-color: $bg-primary-2;
      border-bottom: 1px solid $stroke-primary-2;

      :last-child {
        border-bottom: none;
      }

      color: $text-primary-1;

      > td {
        padding: 24px;
      }
    }
  }
}

.blankSlateRoot {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 200px;
  align-items: center;
  border: 1px solid $bg-primary-2;
  border-radius: 8px;
  margin-top: 16px;

  > div {
    text-align: center;
  }
  > div > p {
    color: $text-primary-3;
    margin-bottom: 12px;
  }
}

.fteroot {
  display: grid;
  justify-items: center;
  margin-top: 48px;
  > p:nth-child(1) {
    color: $text-primary-1;
    font-size: 20px;
  }
  > p:nth-child(2) {
    color: $text-primary-1;
    font-size: 14px;
    margin-bottom: 36px;
  }
}

.hor-div {
  width: 100%;
  border-bottom: 1px solid #2c2c2c;
}

.ai-ico-container {
  width: 500px;
  border-radius: 8px;
  overflow: hidden;
}
.ai-ico {
  > * {
    stroke: $primary-1;
  }
}

.actionitem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 500px;
  padding: 24px;
  background-color: $bg-primary-2;
  cursor: pointer;

  > div {
    display: flex;
    width: 400px;
    justify-content: space-between;
    align-items: center;

    > div {
      > p:nth-child(1) {
        font-size: 14px;
        color: $text-primary-1;
      }

      > p:nth-child(2) {
        font-size: 14px;
        color: $text-primary-2;
      }
    }
  }
}
