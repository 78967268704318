@import "../../../themeColors";

.homeCompHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    > p {
        color: $text-primary-1;
        font-size: 24px;
        font-weight: 600;
    }
}