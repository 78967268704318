@import '../../../../themeColors';
@import '../../../../components/globalFontMixins';

.profileCardExperienceDetails, .profileCardExperienceDetails >.Collapsible > div > div{
    padding: 42px 32px 24px 0;
    display: flex;
    flex-direction: column;
    gap: 36px;
    >.Collapsible{ 
        display:flex;
        flex-flow: column;
        > span{
            @include normalText($text-highlight-accent);
            cursor: pointer;
            order: 2;
        }
        >div{
            order: 1;
            >div{
                padding: 0;
                margin-bottom: 36px;
            }
        }

    }
}

.profileCardExperienceDetails > p{
    @include normalText($text-primary-3)
}

.profileCardExperienceDetailsItem{
    display: flex;
    align-items: center;
    gap: 16px;
    >span{
        display: flex;
        flex-direction: column;
        gap: 8px;
        >div{
            display: inline;
            align-items: center;
            gap: 4px;
            >p{
                display: inline;
                @include normalText;
                margin-right: 8px;
            }
            >h6{
                display: inline;
                @include normalText($text-highlight-accent);
                cursor: pointer;
            }
        }
    }
}

.profileCardExperienceDetailsCalibratable{
    @include backgroundTransition;
    cursor: pointer;
    position: relative;
    &:hover{
        background-color: $bg-primary-4;
    }
}

.delimitatedTitle{
    display: inline;
    align-items: center;
    gap: 4px;
    >p{
        display: inline;
        @include normalText;
        margin-right: 8px;
    }
    >h6{
        display: inline;
        @include normalText($text-highlight-accent);
        cursor: pointer;
    }
    >div{
        display: inline-flex;
        margin-left: 8px;
    }
}