@import "../../themeColors";
@import "../../components/globalFontMixins";
@import "../../components/globalCSSClasses";
@import "../../components/globalVariable";

.addingProspectsRoot {
  display: flex;
  align-items: center;
  //   justify-content: center;
  min-height: 100vh;
  width: 100vw;
  color: $text-primary;
  .companyModal {
    visibility: hidden;
    position: fixed;
    top: 0;
    // bottom: 30%;
    left: 0;
    right: 0;
    width: 735px;
    height: 431px;
    margin-left: auto;
    margin-right: auto;
    z-index: 9999;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    &.showCompanyModal {
      transform: translate(0, 48px);
      visibility: visible;
      opacity: 1;
    }
  }
  .fullCardView {
    width: 0;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    overscroll-behavior: none;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 200;
    transition: all 0.5s ease-in-out;
    &.showFullCard {
      width: 100vw;
    }
  }
  .addingProspectsContainer {
    display: flex;
    justify-content: center;
    gap: 50px;
    width: 100%;
  }
  .recommendedLoading {
    width: 668px;
    height: 606px;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .timerDiv {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
  .horizontalLine {
    width: 385px;
    height: 1px;
    margin-left: -24px;
    background-color: $bg-primary-6;
  }
  .unlockRecommendations {
    padding-top: 0%;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 45px;
    width: 385px;
    height: 244px;
    background-color: $primary-bg-4;
    color: $text-primary-2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: sticky;
    top: 183px;
    border-radius: 12px;
    overflow:hidden;
    @include globalInterFont;
  }
  .title {
    color: $text-primary;
    font-weight: 600;
    display: flex;
    font-size: 17px;
  }
  .iconListContainer {
    display: flex;
  }
  .recommendedProgressContainer {
    .recommendedProgress {
      height: 4px;
      border-radius: 0px;
      margin-top: -12px;
      margin-left: -24px;
      margin-right: -24px;
      &.progress {
        background-color: $primary-bg-6;
        .progress-bar {
          background-color: $text-highlight-primary !important;
        }
      }
    }
  }
  .iconList {
    border-radius: 6px;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-bg-6;
    transition: 0.3s all ease-in-out;
    &.tick {
      &:hover {
        background-color: $bg-positive-secondary;
      }
      * {
        stroke: $bg-positive-primary;
      }
    }
    &.question {
      &:hover {
        background-color: $bg-highlight-secondary;
      }
      * {
        stroke: #ffc321;
      }
    }
    &.cross {
      &:hover {
        background-color: $bg-negative-secondary;
      }
      * {
        stroke: $bg-negative-primary;
      }
    }
  }
}
