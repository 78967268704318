@import '../../../../themeColors';
@import '../../../../components/globalFontMixins';
.deepDetailProfileCard{
    background: $bg-primary-3;
    border: 1px solid $stroke-primary-3;
    border-radius: 8px;
    display: flex;
    width: 100%;
    position: relative;
    .socialButtonContainer{
        display: flex;
        align-items: center;
        gap: 12px;
        border-top: 1px solid $stroke-primary-3;
        border-bottom: 1px solid $stroke-primary-3;
        padding: 24px 20px;
        >button{
            width: calc(33.333% - 8px);
        }
    }
    >span:first-of-type{
        border-right: 1px solid $stroke-primary-3;
        width: 30%;
    }
    >span:last-of-type{
        position: relative;
        width: 70%;
        >span{
            position: absolute;
            top: 12px;
            right: 20px;
            >h6{
                @include normalText($text-highlight-primary);
                cursor: pointer;
            }
        }
        >div{
            display: flex;
            justify-content: space-between;
            gap: 12px;
            >h3{
                @include globalRubikFont;
                @include normalText($text-primary-2);
                font-weight: 400;
                width: 203px;
                padding: 42px 32px 24px 32px;
            }
            >span{
                width: calc(100% - 190px);
                border-bottom: 1px solid $stroke-primary-3;
            }
        }
        >div:last-of-type{
            >span{
                border-bottom: none;
            }
        }
    }
}