@import '../../../components/globalFontMixins';
@import '../../../themeColors';
.QuickNerSetupContainer{
    width: calc(min(100%, 1080px));
    padding: 150px 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    >.QuickNerSetupSection{
        display: flex;
        justify-content: space-between;
        gap: 24px;
        >span{
            width: 100%;
            >h3{
                @include globalRubikFont;
                @include normalText($text-primary-2);
                margin-bottom: 16px;
            }
            >input{
                width: 320px;
                @include globalInterFont;
                @include normalText;
                padding: 12px 16px;
                background: $bg-primary-5;
                border-radius: 8px;
                border: 1px solid transparent;
                outline: none;
                &:focus, &:active, &:hover{
                    outline: none;
                    background: $bg-primary-6;
                }
                &:focus{
                    border-color: $stroke-secondary-1;
                }
                &::placeholder{
                    color: $text-primary-3;
                }
            }
        }
    }
}