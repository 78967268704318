@import "../../themeColors";
.variableTagHeading {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #2e2e2e;
  margin: 8px 0px;
}
.tagTabsContainer {
  margin-top: 8px;
  display: flex;
  align-items: center;
}
.tagTabs {
  box-sizing: border-box;
  outline: none;
  border: none !important;
  border-radius: 6px 6px 0px 0px;
}

.tagTabsSelected {
  color: $text-primary-1;
}
.tagButtonsContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  width: 100%;
  margin: 0px;
  background: $bg-primary-3;
  border: 1px solid $stroke-primary-3;
  box-sizing: border-box;
  border-radius: 8px 8px 8px 8px;
}
.tags {
  display: flex;
  align-items: center;
  margin: 6px 6px;
  padding: 2px 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: $text-highlight-primary;
  border: 1px solid transparent;
  background: $bg-highlight-secondary;
  border-radius: 8px;
  &:hover {
    border-color: $stroke-highlight;
  }
}
