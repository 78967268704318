@import "../../components/globalVariable";
@import "../../components/globalFontMixins";
@import "../../themeColors";

.categoryContainer {
  display: flex;
  // margin-left: 1.7%;
  flex-grow: 1;
  padding-left: 10px;
  padding-right: 150px;
  margin-top: 0.7%;
  margin-bottom: 0.1%;
  column-gap: 2rem;
  scroll-behavior: smooth;
  .myDropDown {
    display: block;
    position: relative;
    background-color: $primary-bg-3;
    color: $text-primary;
    .myDropDown-item {
      position: absolute;
      will-change: transform;
      border-radius: 8px;
      top: 21px;
      left: -24px;
      padding: 0.5rem 1rem;
      border-bottom: 1px solid $bg-primary-3;
      background-color: $bg-primary-3;
      z-index: 10000;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: $bg-primary-2;
        color: $text-primary;
      }
    }
  }
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;

  &::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
    background: transparent !important;
  }

  .categoryMenu {
    // width: clamp(100px, 250px, 350px);
    display: inline-block;
    height: 60px;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;

    &.selected {
      .title {
        color: $text-primary-1 !important;
      }

      .horizontalLine {
        width: 100%;
        border-bottom: 2px solid $bg-highlight-primary;

        /* make it glow! */
        // -webkit-box-shadow: 0px 0px 12px 2px $bg-highlight-primary;
        // -moz-box-shadow: 0px 0px 12px 2px $bg-highlight-primary;
        // box-shadow: 0px 0px 12px 2px $bg-highlight-primary;
      }
    }
    .footerParent {
      display: flex;
    }
    .footer {
      display: none;
      background-color: $bg-primary-1;
      color: $text-primary-1;
      text-align: center;
      // padding: 10px 15px 0px;
      // max-width: 100px;

      width: 0;
      flex-grow: 1;
      flex-wrap: wrap;
      border-radius: 6px;
      position: relative;
      // margin-left: 8.5rem;
      z-index: 500 !important;

      p {
        @include globalInterFont;
        @include normalText($primary-grey);
        font-weight: 400;
        background-color: $bg-primary-1;
        padding-bottom: 16px;
      }
    }
    &:hover .footer {
      display: block;
    }
    .header {
      display: flex;
      padding-bottom: 5px;
      // margin-left: -8%;
      .title {
        @include globalRubikFont;
        color: $text-primary-2;
        letter-spacing: 0.001em;
        font-size: 16px;

        span {
          @include globalInterFont;
          @include normalText;
          background-color: $bg-primary-6;
          color: $text-primary-2;
          padding: 2px 5px;
          border-radius: 4px;
        }
      }

      button {
        margin-top: -2%;
        margin-left: -0.12%;
      }
    }
  }
}
