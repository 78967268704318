$primary-1: var(--primary-1);
$primary-2: var(--primary-2);
$primary-3: var(--primary-3);
$primary-4: var(--primary-4);

$recommended: var(--recommended);
$recommended-secondary: var(--recommended-secondary);

$text-primary-1: var(--text-primary-1);
$text-primary-2: var(--text-primary-2);
$text-primary-3: var(--text-primary-3);
$text-secondary-1: var(--text-secondary-1);
$text-highlight-primary: var(--text-highlight-primary);
$text-highlight-accent: var(--text-highlight-accent);
$text-highlight-heart: var(--text-highlight-heart);
$text-highlight-maybe: var(--text-highlight-maybe);
$text-highlight-secondary: var(--text-highlight-secondary);
$text-negative-primary: var(--text-negative-primary);
$text-negative-secondary: var(--text-negative-secondary);
$text-positive-primary: var(--text-positive-primary);
$text-positive-secondary: var(--text-positive-secondary);
$bg-primary-1: var(--bg-primary-1);
$bg-primary-2: var(--bg-primary-2);
$bg-primary-3: var(--bg-primary-3);
$bg-primary-4: var(--bg-primary-4);
$bg-primary-5: var(--bg-primary-5);
$bg-primary-6: var(--bg-primary-6);
$bg-primary-7: var(--bg-primary-7);
$bg-highlight-primary: var(--bg-highlight-primary);
$bg-highlight-hover: var(--bg-highlight-hover);
$bg-highlight-disabled: var(--bg-highlight-disabled);
$bg-heart-primary: var(--bg-heart-primary);
$bg-heart-secondary: var(--bg-heart-secondary);
$bg-maybe-primary: var(--bg-maybe-primary);
$bg-maybe-secondary: var(--bg-maybe-secondary);
$bg-highlight-secondary: var(--bg-highlight-secondary);
$bg-negative-primary: var(--bg-negative-primary);
$bg-negative-secondary: var(--bg-negative-secondary);
$bg-positive-primary: var(--bg-positive-primary);
$bg-positive-secondary: var(--bg-positive-secondary);
$stroke-primary-1: var(--stroke-primary-1);
$stroke-primary-2: var(--stroke-primary-2);
$stroke-primary-3: var(--stroke-primary-3);
$stroke-primary-4: var(--stroke-primary-4);
$stroke-primary-5: var(--stroke-primary-5);
$stroke-primary-6: var(--stroke-primary-6);
$stroke-primary-7: var(--stroke-primary-7);
$stroke-secondary-1: var(--stroke-secondary-1);
$stroke-secondary-2: var(--stroke-secondary-2);
$stroke-highlight: var(--stroke-highlight);
$stroke-heart: var(--stroke-heart);
$stroke-maybe: var(--stroke-maybe);
$stroke-positive: var(--stroke-positive);
$stroke-negative: var(--stroke-negative);
$bg-gradient: var(--bg-gradient);

:export {
  primary-1: $primary-1;
  primary-2: $primary-2;
  primary-3: $primary-3;
  primary-4: $primary-4;
  text_primary_1: $text-primary-1;
  text_primary_2: $text-primary-2;
  text_primary_3: $text-primary-3;
  text_secondary-1: $text-secondary-1;
  text_highlight_primary: $text-highlight-primary;
  text-highlight-accent: $text-highlight-accent;
  text-highlight-heart: $text-highlight-heart;
  text-highlight-maybe: $text-highlight-maybe;
  text_highlight_secondary: $text-highlight-secondary;
  text_negative_primary: $text-negative-primary;
  text_negative_secondary: $text-negative-secondary;
  text_positive_primary: $text-positive-primary;
  text_positive_secondary: $text-positive-secondary;
  bg_primary_1: $bg-primary-1;
  bg_primary_2: $bg-primary-2;
  bg_primary_3: $bg-primary-3;
  bg_primary_4: $bg-primary-4;
  bg_primary_5: $bg-primary-5;
  bg_primary_6: $bg-primary-6;
  bg_primary_7: $bg-primary-7;
  bg_highlight_primary: $bg-highlight-primary;
  bg_highlight_hover: $bg-highlight-hover;
  bg_highlight_disabled: $bg-highlight-disabled;
  bg-heart-primary: $bg-heart-primary;
  bg-heart-secondary: $bg-heart-secondary;
  bg-maybe-primary: bg-maybe-primary;
  bg-maybe-secondary: bg-maybe-secondary;
  bg_highlight_secondary: $bg-highlight-secondary;
  bg_negative_primary: $bg-negative-primary;
  bg_negative_secondary: $bg-negative-secondary;
  bg_positive_primary: $bg-positive-primary;
  bg_positive_secondary: $bg-positive-secondary;
  stroke_primary_1: $stroke-primary-1;
  stroke_primary_2: $stroke-primary-2;
  stroke_primary_3: $stroke-primary-3;
  stroke_primary_4: $stroke-primary-4;
  stroke_primary_5: $stroke-primary-5;
  stroke_primary_6: $stroke-primary-6;
  stroke_primary_7: $stroke-primary-7;
  stroke_secondary_1: $stroke-secondary-1;
  stroke_secondary_2: $stroke-secondary-2;
  stroke_highlight: $stroke-highlight;
  stroke-heart: $stroke-heart;
  stroke-maybe: $stroke-maybe;
  stroke_positive: $stroke-positive;
  stroke_negative: $stroke-negative;
  bg-gradient: $bg-gradient;
}