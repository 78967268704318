.ChatBubble {
    display: flex;
    gap: .75em;
    padding: 1.5em 3.5em;
    align-items: flex-start;
    width: 100%;
    align-items: center;

    .chat-avatar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: flex-start;

        &.user>div {
            margin-left: 0px !important;
        }
    }

    &:nth-child(2n+1) {
        background-color: rgba(73, 73, 73, 0.2);
    }

    &:nth-child(2n) {
        background-color: transparent;
    }


    // &:not(:last-child) {
    //     .use-jd-btn {
    //         display: none;
    //     }
    // }

    >span {
        white-space: pre-wrap;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        gap: 40px;
    }

    .styles-module_blinkingCursor__yugAC.styles-module_blinking__9VXRT {
        animation-duration: .5s;
        animation-timing-function: linear;
    }
}