@import '../../../../themeColors';
@import '../../../../components/globalFontMixins';

.profileColabActions {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

    >.emoji {
        position: absolute;
        // left: 31%;
        top: 250%;

        .emoji-picker-react {
            z-index: 9;
            box-shadow: none;
        }
    }

    button {
        outline: none;
        border: none;
        @include normalText;
        padding: 4px 8px;
    }

    >button {
        background-color: $bg-highlight-secondary;
        border-radius: 8px;
        padding: 8px;
    }

    >.emojiContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        overflow-y: auto;
        max-width: 80%;
        flex-wrap: wrap;
        padding-bottom: 4px;

        button {
            outline: none;
            border: none;
            @include normalText();
            background-color: $bg-primary-5;
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            border-radius: 24px;

            >.count {
                @include normalText();
                font-size: 14px;
            }

            &:hover {
                background-color: $bg-primary-6;
            }
        }
    }


}