@import '../../../../themeColors';
@import '../../../../components/globalFontMixins';
.outboxReviewMessageContainer{
    display: flex;
    flex-direction: column;
    gap: 16px;
    .outboxReviewMessageHeader{
        display: flex;
        justify-content: space-between;
        gap: 16px;
        >div{
            width: 30%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            >h5{
                @include globalInterFont;
                @include normalText($text-highlight-primary, $bg-highlight-secondary, 0px 8px);
                border-radius: 6px;
                margin-left: 58px;
            }
        }
        >span{
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            flex-wrap: wrap;
            gap: 12px;
            width: calc(70% - 16px);
            >p{
                @include globalInterFont;
                @include normalText($text-negative-primary, $bg-negative-secondary, 0px 8px);
                border-radius: 6px;
            }
            >h6{
                @include globalInterFont;
                @include normalText($text-positive-primary, $bg-positive-secondary, 0px 8px);
                border-radius: 6px;
            }
        }
    }
    .affordance{
        border-radius: 8px;
        display: flex;
        gap: 12px;
        align-items: center;
        background-color: var(--recommended-secondary);
        padding: 8px 16px;
        margin: 16px 0;
        >p{
            @include globalInterFont;
            @include normalText;
        }
        >p>a{
            cursor: pointer;
            @include globalInterFont;
            @include normalText($text-highlight-primary);
            text-decoration: underline;
        }
    }
    .outboxReviewMessageBody{
        background: $bg-primary-3;
        border: 1px solid $stroke-primary-3;
        border-radius: 8px;
        position: relative;
        >.outboxReviewMessageSubject{
            display: flex;
            gap: 12px;
            justify-content: space-between;
            padding: 16px;
            border-bottom: 1px solid $stroke-primary-3;
            h4{
                @include globalRubikFont;
                @include normalText($text-primary-3);
                width: 71.5px;
            }
            >span:first-child{
                width: calc(45% - 6px);
            }
            >span:last-child{
                width: calc(55% - 6px);
            }
            >span{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                >div{
                    display: flex;
                    align-items: baseline;
                    gap: 16px;
                    width: 100%;
                    >span{
                        display: flex;
                        gap: 12px;
                        flex-wrap: wrap;
                        width: calc(100% - 71.5px - 16px);
                        .DropdownToggle{
                            padding: 8px 12px;
                            width: 155px;
                            min-height: unset
                        }
                        .DropdownMenu {
                            width: 155px;
                            min-width: unset;
                        }
                    }
                    >p{
                        @include globalInterFont;
                        @include normalText($text-primary-2);
                    }
                    >input{
                        @include globalInterFont;
                        @include normalText;
                        border: none;
                        // border-bottom: 1px solid $stroke-secondary-1;
                        outline: none;
                    }
                }
            }
        }
        >textarea{
            @include globalInterFont;
            @include normalText;
            outline: none;
            border: none;
            resize: none;
            width: 100%;
            rows: 36;
            padding: 24px 16px;
        }
        >p{
            @include globalInterFont;
            @include smallText($text-primary-2, $bg-primary-4, 0 4px);
            position: absolute;
            bottom: 16px;
            right: 24px;
            border-radius: 6px

        }
        >.overLimitError{
            @include smallText($text-negative-primary, $bg-negative-secondary, 0 4px);

        }
    }
}