@import "../../../components/globalCSSClasses";
@import "../../../components/globalFontMixins";
@import "../../../themeColors";

.editJobWrapper {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 3rem;

    > div {
        background-color: $bg-primary-2;
        border-radius: 12px;
        width: 272px;
        height: 147px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        > span {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: $bg-primary-5;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;
        }

        &:hover {
            border: 1px solid $stroke-highlight;
            cursor: pointer;
        }

        p {
            @include globalInterFont;
            @include normalText();
        }

        &.notAllowed {
            opacity: 0.5;
            cursor: no-drop;
            &:hover {
                border: none;
            }
        }
    }
}

div.JobInReview {
    input, .input-range, .leftWindow button, .rightWindow button, .carouselTagContainer {
        pointer-events: none;
        &:hover {
            cursor: no-drop;
        }
    }
}