@import "../globalVariable";
@import "../globalFontMixins";
@import "../globalCSSClasses";

.social_login_button_root {
    @extend .secondaryButton;
    width: 320px;
    position: relative;
    text-align: center;
    margin: 40px 0;

    &:hover {
        color: white;
    }

    >img {
        position: absolute;
        position: absolute;
        width: 24px;
        left: 12px;
        top: 6px;
        height: 24px;
    }

    >p {
        // transition: all 500ms ease;
        // transition-property:  color;
        // @include normalText($primary-blue);
        // @include backgroundTransition();
        margin: 0;
        font-weight: 600;

        &:hover {
            color: white;
        }

    }
}

.gbt_root {
    margin: 40px 0;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
    transition: background-color .218s, border-color .218s, box-shadow .218s;
    user-select: none;
    appearance: none;
    background-color: #fff;
    background-image: none;
    color: #262626;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    position: relative;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    border: none;
    color: #fff;
    background-color: #4285f4;


    &.google {
        background-color: #4285F4;
    }

    &.outlook {
        background-color: #0072C6;
    }

    &:active {
        background-color: #3367d6;
    }

    &:hover {
        box-shadow: 0 0 3px 3px rgba(66, 133, 244, .3);
    }
}

.gbt_wrapper {
    height: 100%;
    width: 100%;
}

.gbt_icon {
    padding: 15px;
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    float: left;
}

.gbt_svg_cntr {
    width: 18px;
    height: 18px;
    color: #fff;

    >svg {
        vertical-align: unset;
    }

    >img {
        width: 100%;
        height: 100%;
        vertical-align: unset;
    }
}

.gbt_text {
    font-size: 16px;
    line-height: 48px;
    font-family: Roboto, arial, sans-serif;
    font-weight: 500;
    letter-spacing: .21px;
    margin-left: 6px;
    margin-right: 6px;
    vertical-align: top;
}