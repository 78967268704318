@import "./globalVariable";
@import "./globalFontMixins";

.reset-this-root {
  all: initial;
  * {
    all: unset;
  }
}

.primaryGreyButton {
  @include backgroundTransition;
  @include globalInterFont;
  @include normalText;
  display: grid;
  place-content: center;
  font-weight: 600;
  background-color: $primary-grey;
  padding: 8px 16px;
  border-radius: 8px;
  &:hover {
    background-color: $primary-black;
    color: $primary-grey;
  }
  &:active,
  &:focus {
    box-shadow: none !important;
    outline: none !important;
    background-color: $primary-black !important;
    color: $primary-grey !important;
  }
}

.mb32 {
  margin-bottom: 32px;
}

.mb40 {
  margin-bottom: 40px;
}

.primaryButton {
  @include backgroundTransition;
  @include globalInterFont;
  @include normalText;
  font-weight: 600;
  display: grid;
  place-content: center;
  background-color: $primary-blue;
  color: white;
  padding: 11px 32px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  &:hover {
    background-color: $hover-blue;
    // color: $primary-blue;
  }
  &:active,
  &:focus {
    box-shadow: none !important;
    outline: none;
    background-color: $hover-blue !important;
    color: white;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    &:hover {
      background-color: $primary-blue;
    }
  }
}

.secondaryGreyButton {
  @include backgroundTransition;
  @include globalInterFont;
  @include normalText;
  display: grid;
  place-content: center;
  font-weight: 600;
  background-color: $secondary-grey;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  &:hover,
  &:active,
  &:focus {
    @include backgroundTransition;
    background-color: $border-grey;
    border: none;
    box-shadow: none !important;
    outline: none;
  }
}

.secondaryButton {
  @include backgroundTransition;
  @include globalInterFont;
  @include normalText($primary-blue);

  display: grid;
  place-content: center;
  background-color: $secondary-blue;
  border: 0px;
  padding: 8px 16px;
  border-radius: 8px;

  cursor: pointer;

  &:active {
    box-shadow: none !important;
    outline: none;
    border: none;
    background-color: $primary-blue-opaque;
  }
  &:focus {
    box-shadow: none !important;
    background-color: $primary-blue-opaque;
    outline: none;
  }

  &:hover {
    background-color: $primary-blue-opaque;
    border: none;
  }
}

.primaryToolTip {
}
.primaryInnerToolTip {
  @include globalInterFont;
  @include smallText(white);
  border-radius: 8px;
  background: $primary-black;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    @include globalInterFont;
    @include smallText(white);
    text-align: left;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      @include globalInterFont;
      @include normalText(white);
    }
    h3 {
      @include globalInterFont;
      @include normalText($primary-black);
      background-color: #ffc321;
      border-radius: 6px;
      padding: 2px 4px;
    }
    p {
      @include globalInterFont;
      @include smallText(white);
      text-align: left;
    }
  }
}
.primaryArrowToolTip {
  border-radius: 2px;
  &::before {
    border-bottom-color: $primary-black !important;
  }
}

.primaryInput {
  @include backgroundTransition;
  @include globalInterFont;
  @include normalText;
  min-width: 275px;
  background: $bg-primary-5;
  border-radius: 8px;
  padding: 12px 16px;
  border: none;
  outline: none;
  box-shadow: none;
  &:focus,
  &:hover {
    border: none;
    outline: none;
    box-shadow: none;
    background: $bg-primary-6;
  }
  &::placeholder {
    @include globalInterFont;
    @include normalText($text-primary-2);
  }
}

.pillInput {
  @include globalInterFont;
  @include normalText;
  background-color: transparent;
  border-radius: 36px;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0 8px;
  &::placeholder {
    @include globalInterFont;
    @include normalText($disabled-blue);
  }
  &:hover,
  &:focus,
  &:active {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    cursor: text;
    text-decoration: underline;
    text-decoration-color: $underline-grey;
  }
}
.pillSuggestions {
  div {
    @include globalInterFont;
    @include normalText;
    padding: 12px 16px;
    cursor: pointer;
  }
  div:hover {
    background-color: $bg-primary-7;
  }
}
.pillSuggestionsContainer {
  background-color: white;
  // border: 1px solid $border-grey;
  box-sizing: border-box;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.08);
  margin-top: 12px;
  margin-left: -8px;
  border-radius: 8px;
  position: absolute;
  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0;
    margin: 0;
    width: 100%;
    list-style: none;

    li:first-child:hover {
      div {
        border-radius: 8px 8px 0 0;
      }
    }
    li:last-child:hover {
      div {
        border-radius: 0 0 8px 8px;
      }
    }
    li:only-child:hover {
      div {
        border-radius: 8px;
      }
    }
  }
}

.globalDotStyle {
  color: $text-primary-3 !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.globalDotStyleSecondary {
  color: $text-primary-2 !important;
}

.dotSeperator {
  background-color: $stroke-secondary-2;
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.addMorePrimaryButton {
  @include globalInterFont;
  @include normalText($text-highlight-primary, $padding: 8px 16px);
  @include backgroundTransition;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  &:hover {
    @include normalText($text-highlight-secondary, $padding: 8px 16px);
  }
}

.addMoreButton {
  @include globalInterFont;
  @include normalText($text-primary-2, $padding: 8px 16px);
  @include backgroundTransition;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  &:hover {
    @include normalText($text-primary-1, $padding: 8px 16px);
  }
}
