@import "../globalVariable";
@import "../globalFontMixins";
@import "../globalCSSClasses";
@import '../../themeColors';

.textEditortToolBarContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  min-height: 44px;
  background-color: $bg-primary-4;
  padding-left: 20px;
  padding-right: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: $stroke-primary-4 solid 1px;
}
