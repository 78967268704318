@import '../../../components/globalFontMixins';
@import '../../../components/globalVariable';
@import '../../../components/globalCSSClasses';

.jobDetailsTitleContainer{
    min-height: calc(100vh - 70px - 98px - 32px);
    // padding-top: 100px;
    & > h1{
        @include globalInterFont;
        @include heading1;
        margin: 64px 0
    }
    & > p{
        @include globalInterFont;
        @include normalText($primary-grey);
        margin-bottom: 12px
    }
    > input{
        @extend .primaryInput;
    }
}