.Home{
    // overflow: unset;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100% !important;
    height: 100vh;

    >.Header{
        display: flex;
        background: rgba(217, 217, 217, 0.1);

        align-items: center;
        justify-content: center;
        padding: 1.5rem !important;
       
        width: 100%;
        >img{
            width: 96px;
        }
    }

    >.chatLogs{

        display: flex;
        flex-direction: column;
        width: 49%;
        max-width: 900px;
        margin: auto;
        padding: 0 2rem;
        height: 75%;
        overflow-y: auto;
        overflow-x: none;
        >.chatrow{         
            display: flex;
            align-items: flex-start;
            gap: 1rem;
            padding: 1.5rem;
            text-overflow: unset;
            >p{
                max-width: 820px;
                overflow-wrap: break-word;
            }
        }
        >.chatrowAnim{
            gap:0.1rem;
        }
        >.gptResponse{
            background: rgba(73, 73, 73, 0.2);
            border-radius: 0px;
        }
    }
    // >.chatLogs::-webkit-scrollbar {
    //     display: none;
    //     width:0;
    //     height:0;
    //   }

    >.Footer{
        display: flex;
        background: rgba(217, 217, 217, 0.1);

        align-items: center;
        justify-content: center;
        padding: 1.5rem 0 2rem !important;
        width: 100%;
        >.textField{
            width: 45%;
            max-width: 900px;
        }
    }
}
