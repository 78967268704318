@import '../globalVariable';
@import "../../themeColors";

.auth_layout {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: relative;
    isolation: isolate;
    color-scheme: dark;
}


.auth_layout::before {
    content: '';
    position: absolute;
    background: $bg-gradient;
    opacity: 0.3;
    filter: blur(150px);
    transform: rotate(-0.3deg);
    height: 40%;
    width: 75%;
    left: -3px;
    top: 25%;
    z-index: -10;
}

.auth_layout_content {
    width: 100%;

    >div {
        padding: 32px;
    }

    >div:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 60vh;

    }

    // padding: 32px;
}

.auth_layout_curtain {
    width: 30%;
    background: $background-gradient
}