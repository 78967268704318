@import '../../components/globalCSSClasses';
@import '../../components/globalVariable';
@import '../../themeColors';


.formik_err_msg {
    @include globalInterFont;
    @include smallText($primary-red);
    margin-top: 8px;
    width: 320px;
    word-wrap: break-word;
}

.input_label {
    @include globalRubikFont;
    @include normalText($primary-grey);
    margin-bottom: 12px;
    display: flex;
    font-size: 14px;
    font-weight: normal;

    >a {
        text-decoration: none;
        @include normalText($primary-grey);
        margin-left: auto;
        cursor: pointer;
        text-transform: capitalize;

        &:hover {
            color: #FF8D4E;
        }
    }
}

.signin_root {
    max-width: 500px;

    >h1 {
        @include globalInterFont;
        @include heading1;
        margin-bottom: 40px;
        font-size: 16px;
    }
}

.password_show_hide {
    position: relative;
    width: 100%;
    height: 100%;

    >img {
        cursor: pointer;
        position: absolute;
        right: -310px;
        bottom: 12px;
    }
}


.authElemContainer {
    background-color: #222;
    padding: 24px;
    border-radius: 8px;
    word-wrap: break-word;
    width: 370px;
    color: #E0E0E0;
    font-size: 14px;
    font-weight: normal;
}

.auth-submit-btn {
    display: inline-block;
    width: 100%;
    text-align: center;
    justify-content: center;
}

.verifyEmailRoot {
    color: $text-primary ;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 320px;

    .bt_wrapper {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        gap: 10px;
        height: 42px;
        width: 100%;
        background: $text-primary;
        border-radius: 8px;
        margin-block: 30px;
        text-decoration: none;

        >.bt_text {
            text-transform: capitalize;
            color: $text-secondary-1;

        }

        &:hover {
            box-shadow: #FF8D4E 1px 1px 4px 3px;
        }
    }

    >p {

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 146.02%;
        color: #E0E0E0;

        &:first-of-type {
            margin-bottom: 20px;
        }

        >span {
            color: grey;

            &.resend {
                cursor: pointer;
                color: #FF8D4E;
            }
        }
    }
}

.verify_2fa_root,
.enable_2fa_root {
    color: $text-primary;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;


    h1 {
        font-size: 16px;
        font-weight: 600;
    }

}

.enable_2fa_root {

    form {
        margin-top: 40px;
        color-scheme: dark;
    }

    .check_group {
        display: flex;
        gap: 12px;
        align-items: center;

        .checkbox {
            accent-color: #FF8D4E;
            border-radius: 6px;
            height: 24px;
            width: 24px;
        }
    }
}

.verify_2fa_root {
    span {
        margin-inline: 2px;
    }

    .resend {
        color: #FF8D4E;
        cursor: pointer;
    }
}

.ver_cb_root {
    width: 460px;

    >h1 {
        @include globalInterFont;
        @include heading1;
        margin-bottom: 40px;
        font-size: 16px;
    }

    >p {

        @include normalText($primary-grey);
        margin-bottom: 32px;
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 18px;
        width: 100%;
        text-align: center;
    }
}