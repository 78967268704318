@import '../globalVariable';
@import '../globalFontMixins';
@import '../globalCSSClasses';

.editablePillButtonContainer{
    background-color: $secondary-blue;
    border-radius: 36px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: fit-content;
    max-width: 100%;
    >input{
        @extend .pillInput;
        width: fit-content;
        max-width: calc(100% - 38px - 16px);
    }
    > button {
        @extend .secondaryButton;
        padding: 12px;
        border-radius: 50%;
    }
    > button:hover, 
    > button:focus, 
    > button:active {
        background-color: $secondary-blue !important;
    }
}

.editablePillButtonDefault{
    background-color: $secondary-grey;
    > button {
        background-color: $secondary-grey;
    }
    > button:hover, 
    > button:focus, 
    > button:active {
        background-color: $secondary-grey !important;
    }
}