@import '../../themeColors';
@import '../../components/globalFontMixins';

.emailTagConatiner{
    @include globalInterFont;
    @include backgroundTransition;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    &Found{
        @include normalText($text-primary-2, $bg-primary-5, 2px 8px);
    }
    &Missing{
        @include normalText($text-negative-primary, $text-negative-secondary, 2px 8px);
    }
    .tagTickFade-enter {
        opacity: 0;
        transform: translateX(-24px);
    }
    .tagTickFade-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
    }
    .tagTickFade-exit {
        opacity: 1;
        transform: translateX(0);
    }
    .tagTickFade-exit-active {
        opacity: 0;
        transform: translateX(-24px);
        transition: opacity 300ms ease-out, transform 300ms ease-out;
    }
} 

.tagInputFade-enter {
    opacity: 0;
    transform: translateX(240px);
}
.tagInputFade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
.tagInputFade-exit {
    opacity: 1;
    transform: translateX(0);
}
.tagInputFade-exit-active {
    opacity: 0;
    transform: translateX(250px);
    transition: opacity 300ms ease-out, transform 300ms ease-out;
}