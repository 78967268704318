@import "../../themeColors";
@import "../../components/globalFontMixins";
@import "../../components/globalFontMixins";
@import "../../components/globalCSSClasses";
@import "../../components/globalVariable";

.auth_layout {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.auth_layout_content {
    width: 100%;

    >div {
        padding: 32px;
    }

    >div:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 60vh;

    }

    .invite-confirmed {
        color: $text-primary;
        display: flex;
        row-gap: 1rem;
        flex-direction: column;
        align-items: center;
    }

    // padding: 32px;
}

.auth_layout_curtain {
    width: 30%;
    background: $background-gradient
}

.recruiter-form-invite {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.7rem;
    width: 400px;

    input {
        width: 400px;
    }

    label {
        color: $text-primary;
    }

    .error-labels {
        color: $text-negative-primary;
    }
}