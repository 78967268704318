@import '../../../components/globalFontMixins';


.jobDetailsDescriptionContainer{
    padding: 64px 0 0 0;
    & > h1{
        @include globalInterFont;
        @include heading1;
        margin-bottom: 32px;
    }
}