@import "../../themeColors";
@import "../../components/globalFontMixins";
@import "../../components/globalFontMixins";
@import "../../components/globalCSSClasses";
@import "../../components/globalVariable";

.CsvExport {
    position: relative;
    color-scheme: dark light;

    .options {
        position: absolute;
        background-color: $bg-primary-4;
        padding: 20px;
        text-align: left;
        width: 400px;
        top: 36px;
        left: -7px;
        color: $text-primary-1;
        box-sizing: border-box;
        border: 1px solid #{$bg-primary-5};
        border-radius: 8px;

        h2,
        label,
        div {
            @include globalInterFont;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: $text-primary-1;
        }

        .options-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 21px;
            margin-block: 31px 45px;
            border-bottom: 2px solid #{$stroke-primary-5};
            padding-bottom: 40px;
            margin-bottom: 20px;

            .input-group {
                display: flex;
                gap: 12px;
            }
        }

        .options-footer {
            @include globalInterFont;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 146.02%;
            color: $text-primary-3 ;

            button {
                @include globalInterFont;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 146.02%;
                color: $bg-primary-1;

                &.loading-button {
                    background-color: $bg-highlight-hover;
                }
            }
        }
    }
}