@import '../Optout/global.scss';
.consentContainer{
    background-color: $bg-primary-1;
    min-height: 100vh;
    position: relative;
    scroll-behavior: smooth;
    scroll-snap-type: block;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    // gap: min(360px, 25vw);
}

.consentSection{
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding-top: 150px;
    padding-bottom: 200px;
    justify-content: center;
    width: calc(min(784px, 80%));
    >h3{
        @include bodyText2($text-primary-1);
        margin-bottom: 32px;
    }
    >p{
        @include bodyText1;
        margin-bottom: 24px;
    }
}


.consentContent {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    >p{
        @include bodyText1;
        margin-bottom: 24px;
        font-size: 24px;
    }
}