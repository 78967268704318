@import '../../themeColors';
@import '../../components/globalFontMixins';
@import '../../components/globalCSSClasses';
.addMoreContainer{
    display: flex;
    align-items: center;
    gap: 16px;
    > span > input{
        @extend .primaryInput
    }
}
