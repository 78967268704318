@import '../../../themeColors';
.outboxMessagesController{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: calc(70% - 16px);
    height: 100%;
    >div:first-child{
        display: flex;
        align-items: center;
        gap: 16px;
        position: sticky;
        top: 0;
        background: $bg-primary-1;
        z-index: 1000;
        width: 100%;
        padding: 8px 0;
    }
    >div:last-child{
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
    }
}
