@import "../globalCSSClasses";
@import "../globalFontMixins";
@import "../globalVariable";

.calendar_header_root {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $stroke-primary-3;
  background-color: $bg-primary-2;

  img {
    width: 36px;
    height: 36px;
    padding: 8px;
  }

  > div:first-child {
    cursor: pointer;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    > span {
      @include globalInterFont;
      @include normalText(white);
    }
  }
}

.calendarHeaderRightButtons {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: 8px;
  & > span {
    height: 24px;
  }
  svg {
    // margin: 0 !important;
    color: $text-highlight-primary;
    width: 18px;
    height: 15px;
  }
  .headerIcon {
    width: 36px;
    height: 36px;
    padding: 8px;

    path {
      stroke: $text-highlight-primary;
    }

    .verticalLine {
      display: none;
    }

    .notificationIcon {
      margin-left: 0 !important;
    }

    .notificationDropdownContainer .notificationDropdownButton {
      background-color: transparent;
      margin: 0;

      svg {
        margin-left: -30px !important;
        margin-bottom: 12px !important;
      }
    }
  }
}
