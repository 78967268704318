@import '../../themeColors';
@import '../../components/globalFontMixins';
.cardContainer{
    @include globalInterFont;
    @include normalText;
    background-color: $bg-primary-3;
    border: 1px solid $stroke-primary-3;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 32px 24px;
    h3{
        @include globalRubikFont;
        @include normalText($text-primary-2);
    }
    h2{
        @include globalInterFont;
        @include heading3;
    }
    >div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > span{
        display: flex;
        align-items: center;
        gap: 12px;
        }
        margin-bottom: 24px;
    }
    >span{
        // background-color: $bg-primary-4;
        // border: 1px solid $stroke-primary-4;
        // border-radius: 8px;
        // padding: 32px 24px;
    }
}