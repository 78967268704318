@import "../../../components/globalCSSClasses";
@import "../../../components/globalFontMixins";
@import "../../../components/globalVariable";


.NoCompanyFound{
    border: 1px solid #E6E9ED;
    width: fit-content;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    margin-top: 8px;
    padding: 28px 0px 32px 16px;
    h3{
        @include normalText;
        @include globalInterFont;
        margin-right: 178px;
        margin-bottom: 8px;
    }
    p{
        @include normalText($primary-grey);
        @include globalInterFont;
        margin-bottom: 24px;
        width: 18vw;
    }
    button{
        @extend .primaryButton;
        background-color: #EAF2FF;
        color: #297AF7;
        border: none;
        &:hover{
            background-color: #EAF2FF;
            border: none;
        }
    }
}