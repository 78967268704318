@import '../../globalFontMixins';
@import '../../globalVariable';


.idealProfileSkills{

    margin: 32px 16px;
    
    & > h1{
        @include globalInterFont;
        @include subHeading1;
    }
    
}

.idealProfileSkills_Container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    row-gap: 12px;
    column-gap: 8px;
    padding-bottom: 64px;
    margin-top: 17px;
}