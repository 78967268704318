@import "../../../components/globalCSSClasses";
@import "../../../components/globalVariable";
@import "../../../components/globalFontMixins";

.messaging {
    .header {
        display: flex;
        justify-content: space-between;
        height: 76px;
        padding: 28px 17px;
        border-bottom: 1px solid $stroke-primary-2;
        .heading {
            @include smallText($text-primary-2);
        }
        .dropdownFilters {
            display: flex;
            gap: 12px;
            .dropdown {
                button {
                    @include smallText($text-primary-2);
                    padding: 6px 8px;
                    min-height: 28px;
                    min-width: 132px;
                    width: 132px;
                }
                .dropdown-item {
                    @include smallText($text-primary-2);
                    padding: 6px 8px;
                }
                .dropdown {
                    margin-left: 12px;
                    height: 28px;
                    width: 132px;
                }
                .dropdown-menu {
                    min-width: 132px;
                }
            }
        }
        .actionbarFilterButton {
            border: none !important;
            :focus {
                border: none !important;
                box-shadow: none !important;
            }
        }
        .searchJob {
            background-color: $bg-primary-2 !important;
        }
        .searchRecipient {
            background-color: $bg-primary-1 !important;
        }
    }

    .jobsView {
        background-color: $bg-primary-2;
        padding: 0;
        .selectedJob {
            height: 72px;
            background-color: $bg-primary-4;
            border-left: 4px solid $bg-highlight-primary;
            border-right: 4px solid $bg-highlight-primary;
            cursor: pointer;
            .jobTitle {
                @include smallText($text-highlight-primary);
                font-weight: 500;
                padding: 12px;
                text-transform: capitalize;
            }

            .jobContent {
                @include subText($text-primary-2);
                text-transform: capitalize;
                padding-left: 12px;
                display: flex;
                gap: 6px;
            }
        }
        .jobs {
            @include smallText($text-primary-2);
            padding: 16px 17px;
            text-transform: capitalize;
            cursor: pointer;
        }
    }

    .recepientView {
        border-right: 1px solid $stroke-primary-2;
        padding: 0;

        .selectedRecipient,
        .recipient {
            padding: 13px 16px;
            display: flex;
            text-transform: capitalize;
            cursor: pointer;

            .profileDisplayImage {
                margin-right: 12px;
            }
            .recipientName {
                @include smallText($text-primary-1);
                font-weight: 500;
            }
            .recipientDescription {
                @include smallText($text-primary-2);
            }
        }
        .selectedRecipient {
            background-color: $bg-primary-2;
            .recipientName {
                color: $text-highlight-primary;
            }
        }
    }

    .messagesView {
        padding: 0;
        .messagesContent {
            display: flex;
            flex-direction: column;
            height: 75vh;
            overflow-y: auto;
            overflow-x: hidden;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none;

            .flexOnly {
                color: $text-primary-3;
                display: flex;
                text-transform: capitalize;
            }

            .channel {
                display: inline-flex;
                svg {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                    margin-top: 4px;

                    path {
                        stroke: $stroke-secondary-1;
                    }
                }
            }

            .overviewDotStyle {
                margin: 0 8px;
                color: $text-primary-3 !important;
            }

            // .profileDisplayImage {
            //     margin-top: -3px;
            // }

            .loader {
                color: $bg-primary-7;
            }

            &::-webkit-scrollbar {
                width: 0 !important;
            }

            .self {
                margin-left: 80px !important;

                .senderImage {
                    background-color: rgba(156, 168, 225, 0.2) !important;
                    color: #9ca8e1 !important;
                }

                .messageContainer .message {
                    background-color: #363944 !important;
                }

                .senderName {
                    color: #9ca8e1 !important;
                }

                .profileDisplayInitials {
                    color: #9ca8e1 !important;
                }
            }

            .single-comment {
                @include smallText($fontColor: $text-primary);
                padding-top: 26px;
                animation: slideIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

                .messagesHeader {
                    display: flex;
                    justify-content: space-between;
                    margin-left: 165px;
                    margin-right: 32px;

                    .commentSender {
                        display: flex;
                        gap: 8px;

                        .senderImage {
                            @include subText($padding: 6px 4px);
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                            margin-bottom: 8px;
                        }
                        .senderName {
                            @include smallText();
                            margin-top: 2px;
                            color: $text-highlight-primary;
                        }
                        .profileDisplayInitials {
                            width: 25px;
                            height: 25px;
                            color: $text-highlight-primary;
                            background-color: $primary-bg-7;
                            border-radius: 50%;
                            font-size: 12px;
                            font-weight: normal;
                            margin-top: -6px;
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                        }
                    }

                    .time {
                        @include smallText($fontColor: $text-disabled);
                        display: flex;
                        .more {
                            button {
                                background: none;
                                padding: 0;
                                border: none;
                                margin-top: -5px;
                                margin-right: -6px;
                            }
                        }
                    }
                }

                .messageContainer {
                    display: flex;
                    align-items: flex-start;
                    margin-left: 165px;
                    margin-right: 32px;

                    .message {
                        width: 100%;
                        border-radius: 10px;
                        background-color: $primary-bg-7;
                        padding: 12px;

                        strong {
                            display: inline-block;
                        }
                    }
                }
            }
        }
        .inputBox {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 76px;
            border-top: 1px solid $stroke-primary-2;
            .inputInput {
                background: $primary-bg-6;
                border-radius: 10px;
                width: 90% !important;
                height: 36px;
                margin: 13px 12px 10px 32px;
                border: none;
                color: $text-primary;

                .inputInput__input {
                    border: none;
                    color: $text-primary;
                    padding: 7px 16px;
                }

                .inputInput__suggestions {
                    background-color: $primary-bg-5 !important;
                    border-radius: 10px;
                    ul li {
                        padding: 8px;
                        &:hover {
                            background-color: $bg-highlight-secondary;
                            color: $text-highlight-primary;
                        }
                    }
                }
            }

            .messageSend {
                padding: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 36px;
                height: 36px;
                background: $primary-icon-4;
                border: none;
                border-radius: 10px;
                margin: 20px 12px 20px 0;

                &:hover {
                    background: $primary-bg-6 !important;
                    * {
                        stroke: $text-primary;
                    }
                }
            }

            .sendMessageChannel {
                width: 115px;
                height: 36px;
                margin: 20px 12px 20px 0;
                background: $primary-bg-6;
                border-radius: 10px;
                border: none;
                display: flex;
                gap: 5px;
                align-items: center;
                .dropdown {
                    .DropdownContainer .DropdownToggle {
                        background-color: transparent !important;
                    }
                    button {
                        @include smallText($text-primary-2);
                        min-height: 36px;
                        min-width: 70px;
                        width: 70px;
                    }
                    .dropdown-item {
                        @include smallText($text-primary-2);
                        padding: 6px 8px;
                    }
                    .dropdown {
                        margin-left: 12px;
                        height: 36px;
                        width: 70px;
                    }
                    .dropdown-menu {
                        min-width: 115px;
                        left: -33px !important;
                    }
                }
            }
        }
        textarea:focus-visible {
            outline: none;
        }
    }
}
