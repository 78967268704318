@import '../globalFontMixins';
@import '../globalVariable';
@import "../globalCSSClasses";
@import "../../themeColors";


.jobCard{
    width: 437px;
    border: 1px solid $stroke-primary-4;
    border-radius: 8px;
    background-color: $bg-primary-3;

    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .secondaryGreyButton{
        &:hover{
            cursor: pointer;
            background-color: $secondary-grey;
        }

        @extend .secondaryGreyButton;
        font-size: 14px !important;
        font-weight: 300 !important;
    }

    .secondaryButton{
        @extend .secondaryButton;
        font-size: 14px !important;
        font-weight: 300 !important;
    }
}

.jobCardGeneralDetails{

    &>h2{
        @include normalText;
        @include globalInterFont;

        width: max-content;
        background-color: $tag-grey;
        padding: 1px 4px;
        border: none;
        border-radius: 6px;
        margin-bottom: 24px;
        

    }
}
.jobCardNameAndTribeCount{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    margin-bottom: 8px;

    &>span{
        display: flex;
        align-items: center;
    
        gap: 8px;
    
        h1{
            @include heading2;
            @include globalInterFont;
        }
    
        div{
            width: fit-content;
            height: fit-content;
    
            display: flex;
            align-items: center;
            // text-align: center;
            padding: 3px;
            background-color: $tertiary-grey;
            border-radius: 5px;
    
            .tribeCount{
                @include subText($fontColor: white);
                @include globalInterFont;

                line-height: 12px;
                font-size: 10px;
            }
        }
    }
}

.jobCardSvgAndDescriptionContainer{
    display: flex;
    flex-direction: column;

    gap: 8px;

    margin-bottom: 40px;
}

.numberedDetailsContainer{
    display: flex;
    justify-content: space-between;
}

.jobCardButtonContainer{
    display: flex;
    gap: 17px;
    justify-content: space-between;

    &>*{
        width: 50%;
    }
}
