@import "../globalVariable";
@import "../globalFontMixins";

.draftEditorContainer > div.DraftEditor-root {
  @include globalInterFont;
  @include normalText;
  height: 221px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $stroke-primary-4;
  box-sizing: border-box;
  padding: 16px;
  overflow-y: auto;
  line-height: 34px;
}

.draftEditorContainer > div.DraftEditor-root > div.DraftEditor-editorContainer,
.draftEditorContainer
  > div.DraftEditor-root
  > div.DraftEditor-editorContainer
  > div.public-DraftEditor-content {
  height: 100%;
}

.styledTags {
  @include globalInterFont;
  @include normalText($backgroundColor: $secondary-grey, $padding: 6px 8px);
  @include backgroundTransition;
  color: $text-highlight-primary;
  background-color: $bg-highlight-secondary;
  border-radius: 8px;
  cursor: pointer;
}
.styledTags:hover {
  @include normalText(white, $primary-grey, 6px 8px);
  @include backgroundTransition;
  background-color: $bg-highlight-primary;
}

.styleableTextAreaContainer {
  margin: 0px;
}
.emailCharcterCounter {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #a7abb0;
  margin: 8px;
}
.emailCharcterCounterFlash {
  color: #ef5555;
}
