@import '../../../themeColors';
@import '../../../components/globalFontMixins';
@import '../../../components/globalCSSClasses';

.idealProfileSuggestionContainer{

}

.addIdealProfiles{
    padding-top: 32px;
    min-height: 50vh;
    & > h1{
        @include globalInterFont;
        @include heading1;

    }
    & > h5{
        @include globalInterFont;
        @include normalText($text-primary-3);
        text-transform: uppercase;
        // margin-top: 52px;
    }
    & > p{
        @include globalInterFont;
        @include normalText($text-primary-2);
        // margin-top: 27px;
    }
}
.addIdealProfilesInputAndButton{
    display: flex;
    margin-top: 12px;
    gap: 12px;
    align-items: center;

}
.addIdealProfilesInput{
    @extend .primaryInput;
    width: 320px;
}

.addIdealProfilesSummaryCard{
    display: flex;
    flex-wrap: wrap;
    margin: 32px 0;
    gap: 24px;
}